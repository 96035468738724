<div class="encyclo-faradel-land-view u-marginTop1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité de Faradel">
      <img class="encyclopedia-img" [src]="citeFaradelImage" alt="La Cité de Faradel" />
      <div class="card is-info">Contenu à venir...</div>
      <!--div class="card" [innerHTML]="citeFaradelResume"></div>
      <div class="card u-marginTop1" [innerHTML]="citeFaradelDetails"></div-->
    </mat-tab>
    <mat-tab label="La Tour du Savoir">
      <img class="encyclopedia-img" [src]="tourSavoirImage" alt="La Cité de Faradel" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="Les Plaines de Terfil">
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
  </mat-tab-group>
</div>
