<div class="chronology-view bg-celestia">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="card">Section en cours de développement...</div>
  </head-layout>

  <div separator></div>

  <div class="layout-bloc-view">
    <div class="card">Section en cours de développement...</div>
  </div>
</div>
