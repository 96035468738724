import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { SpecialityModal } from '@src/components/modals/speciality/speciality.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorPower, PowerRanksEnum } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSpeciality } from '@src/models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '@src/models/data/ListSpecialities';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-crystals',
  templateUrl: './character-crystals.component.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterCrystalsComponent implements OnInit, OnChanges {
  @Input() public indexCrystal: number;
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public character: ArlenorCharacter;
  public allSpecialities: ArlenorSpeciality[];
  public idsPowers: number[];

  public nbRankX: number;
  public maxRankX: number;
  public nbRankIII: number;
  public maxRankIII: number;
  public nbRankII: number;
  public maxRankII: number;
  public nbRankI: number;
  public maxRankI: number;

  private _destroyRef = inject(DestroyRef);

  public get selectedSpeciality(): ArlenorSpeciality | null {
    if (!this.form.controls.codeSpeciality.value) return null;
    return ArlenorSpecialities.getSpeciality(this.form.controls.codeSpeciality.value);
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get filteredPowers(): ArlenorPower[] {
    const filteredPowers = this.allPowers.filter(power => power.speciality.code === this.selectedSpeciality?.code);
    filteredPowers.sort((a, b) => a.name.localeCompare(b.name));
    return filteredPowers;
  }

  public get selectedSpeName() {
    const codeSpeciality = this.form.controls.codeSpeciality.value;
    if (codeSpeciality) return this.allSpecialities.find(spe => spe.code === codeSpeciality).name;
    else return '-';
  }

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      codeSpeciality: new FormControl(null, Validators.required),
      isNbPowersValid: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    const codeSpeciality = this.indexCrystal === 0 ? this.character.codeSpeciality01 : this.character.codeSpeciality02;
    this.idsPowers = this.indexCrystal === 0 ? this.character.idsPowers01 : this.character.idsPowers02;
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();

    this.maxRankX = this.character.level.maxRankS[this.indexCrystal];
    this.maxRankIII = this.character.level.maxRankA[this.indexCrystal];
    this.maxRankII = this.character.level.maxRankB[this.indexCrystal];
    this.maxRankI = this.character.level.maxRankC[this.indexCrystal];

    // Init
    this.form.controls.codeSpeciality.setValue(codeSpeciality);

    this.form.controls.codeSpeciality.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.idsPowers = [];
      this._checkNbPowers();
    });

    this._checkOldPowers();

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public ngOnChanges() {
    this._checkOldPowers();
  }

  public addPower(power: ArlenorPower) {
    this.idsPowers.push(power.id);
    this._storeService.modifyCharacter();
    this._checkNbPowers();
  }

  public removePower(power: ArlenorPower) {
    this.idsPowers = this.idsPowers.filter((idPower: number) => idPower !== power.id);
    this._storeService.modifyCharacter();
    this._checkNbPowers();
  }

  public checkSpe(spe: ArlenorSpeciality) {
    if (!this.allPowers || !this.idsPowers) return false;
    // Pour tester si une classe est éligible
    if (this.indexCrystal === 1) {
      const character: ArlenorCharacter = this._storeService.character;
      if (spe.code === character.codeSpeciality01) return false;
    }

    const list = this.allPowers.filter(power => power.speciality?.code === spe.code);
    if (list.length === 0) return false;

    const nbRankX = list.filter(power => power.codeRank === PowerRanksEnum.Unique.Code).length;
    if (nbRankX < this.maxRankX) return false;

    const nbRankIII = list.filter(power => power.codeRank === PowerRanksEnum.TresRare.Code).length;
    if (nbRankIII < this.maxRankIII) return false;

    const nbRankII = list.filter(power => power.codeRank === PowerRanksEnum.Rare.Code).length;
    if (nbRankII < this.maxRankII) return false;

    const nbRankI = list.filter(power => power.codeRank === PowerRanksEnum.Commun.Code).length;
    if (nbRankI < this.maxRankI) return false;

    return true;
  }

  public openSpePopup() {
    const dialogRef = this.dialog.open(SpecialityModal, {
      data: this.selectedSpeciality ? this.selectedSpeciality : null,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public openSpeURL() {
    const url = this._router.serializeUrl(this._router.createUrlTree(['jeu-de-roles/cristaux'], { queryParams: { spe: this.selectedSpeciality.code } }));
    window.open(url, '_blank');
  }

  private _checkOldPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const validIdsPowers = this.allPowers.map(power => power.id);
    this.idsPowers = this.idsPowers.filter((idPower: number) => validIdsPowers.includes(idPower));
    this._checkNbPowers();
  }

  private _checkNbPowers() {
    if (!this.allPowers || !this.idsPowers) return;
    const powersSelected = this.allPowers.filter(power => this.idsPowers.includes(power.id));
    this.nbRankX = powersSelected.filter(power => power.codeRank === PowerRanksEnum.Unique.Code).length;
    this.nbRankIII = powersSelected.filter(power => power.codeRank === PowerRanksEnum.TresRare.Code).length;
    this.nbRankII = powersSelected.filter(power => power.codeRank === PowerRanksEnum.Rare.Code).length;
    this.nbRankI = powersSelected.filter(power => power.codeRank === PowerRanksEnum.Commun.Code).length;

    this.form.controls.isNbPowersValid.setValue(
      this.nbRankX === this.maxRankX && this.nbRankIII === this.maxRankIII && this.nbRankII === this.maxRankII && this.nbRankI === this.maxRankI ? true : null
    );
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    const character: ArlenorCharacter = this._storeService.character;
    if (this.indexCrystal === 0) {
      newCharacter.codeSpeciality01 = this.form.controls.codeSpeciality.value;
      newCharacter.idsPowers01 = this.idsPowers;
      newCharacter.codeSpeciality02 = character.codeSpeciality02;
      newCharacter.idsPowers02 = character.idsPowers02;
    } else {
      newCharacter.codeSpeciality01 = character.codeSpeciality01;
      newCharacter.idsPowers01 = character.idsPowers01;
      newCharacter.codeSpeciality02 = this.form.controls.codeSpeciality.value;
      newCharacter.idsPowers02 = this.idsPowers;
    }
    this._storeService.changeCharacterCrystals(newCharacter);
  }
}
