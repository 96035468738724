import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CaractDescriptionEnum } from '../../../models/ModelEnum';

@Component({
  selector: 'caract-modal',
  templateUrl: './caract.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class CaractModal implements OnInit {
  public caractDescriptionEnum = CaractDescriptionEnum;

  public caract: string;

  constructor(
    public dialogRef: MatDialogRef<CaractModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.caract = this.data;
  }
}
