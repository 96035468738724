import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { PageTitles } from '@src/models/PagesTitles';

import { filter } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent implements OnInit {
  public pages = PageTitles;
  public onHome: boolean;
  public isMaxFooter: boolean;

  constructor(private _router: Router) {}

  public ngOnInit() {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const currentUrl = this._router.url;
      this.onHome = currentUrl === '/';
      this.isMaxFooter = currentUrl !== '/' && currentUrl !== '/a-propos' && currentUrl !== '/mentions-legales';
    });
  }
}
