import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { FactionModal } from '@src/components/modals/faction/faction.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorFaction } from '@src/models/arlenor/ArlenorFaction';
import { getListFactions } from '@src/models/data/ListFactions';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-details',
  templateUrl: './character-details.component.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterDetailsComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public character: ArlenorCharacter;
  public allFactions: ArlenorFaction[];

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _router: Router,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      story: new FormControl(null),
      description: new FormControl(null),
      traits: new FormControl(null),
      codeAlly: new FormControl(null),
      codeEnemy: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    this.allFactions = getListFactions();
    this.allFactions.sort((a, b) => {
      if (a.isOff === b.isOff) return a.name.localeCompare(b.name);
      else if (a.isOff) return -1;
      else return 1;
    });

    // Init
    this.form.controls.story.setValue(this.character.story);
    this.form.controls.description.setValue(this.character.description);
    this.form.controls.traits.setValue(this.character.traits);
    this.form.controls.codeAlly.setValue(this.character.codeAlly);
    this.form.controls.codeEnemy.setValue(this.character.codeEnemy);

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public openFactionPopup(isAlly: boolean) {
    let faction;
    if (isAlly) faction = this.allFactions.find(fact => fact.code === this.form.controls.codeAlly.value);
    else faction = this.allFactions.find(fact => fact.code === this.form.controls.codeEnemy.value);

    const dialogRef = this.dialog.open(FactionModal, {
      data: faction ? faction : null,
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public openFactionURL(isOff: boolean) {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['univers/encyclopedie'], { queryParams: { section: isOff ? 'EncycloFactionsOffView' : 'EncycloFactionsSecView' } })
    );
    window.open(url, '_blank');
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.story = this.form.controls.story.value;
    newCharacter.description = this.form.controls.description.value;
    newCharacter.traits = this.form.controls.traits.value;
    newCharacter.codeAlly = this.form.controls.codeAlly.value;
    newCharacter.codeEnemy = this.form.controls.codeEnemy.value;
    this._storeService.changeCharacterDetails(newCharacter);
  }
}
