import { Component, Input } from '@angular/core';

import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-resume',
  templateUrl: './character-resume.component.html',
  styleUrls: ['./character-resume.component.scss'],
})
export class CharacterResumeComponent {
  @Input() public hasLevel: boolean;

  public get character(): ArlenorCharacter {
    return this._storeService.character;
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get powers(): ArlenorPower[] {
    return this.allPowers.filter(power => this.character.idsPowers01.includes(power.id) || this.character.idsPowers02.includes(power.id));
  }

  public get skills(): ArlenorSkill[] {
    return this.allSkills.filter(skill => this.character.idsSkills.includes(skill.id));
  }

  constructor(private _storeService: StoreService) {}
}
