<div class="card u-marginTop1" [innerHtml]="systemRacesText"></div>

<div class="grid-list grid-2 u-marginTop1">
  <div *ngFor="let race of allRaces" class="grid-element">
    <img class="u-rounded" [src]="race.image" [alt]="race.name" />
    <div>
      <div class="u-flexRow u-alignCenter u-gap05 u-marginBottom05">
        <h3>{{ race.name }}</h3>
        <span [class]="getDifficultyColor(race)">{{ getDifficultyLibelle(race) }}</span>
      </div>
      <p [innerHtml]="race.description"></p>
    </div>
  </div>
</div>
