<div class="encyclo-no-land-view u-marginTop1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Forêt d'Imerys">
      <img class="encyclopedia-img" [src]="imerysImage" alt="La Forêt d'Imerys" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="L'Ile de Lumeck">
      <img class="encyclopedia-img" [src]="lumeckImage" alt="L'Ile de Lumeck" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
  </mat-tab-group>
</div>
