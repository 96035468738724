import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorSpecialities } from '@src/models/data/ListSpecialities';
import { StoreService } from '@src/services/store.service';

import { ArlenorArchetype } from '../../../models/arlenor/ArlenorArchetype';
import { ArlenorSpeciality } from '../../../models/arlenor/ArlenorSpeciality';
import { CaractDescriptionEnum } from '../../../models/ModelEnum';

@Component({
  selector: 'speciality-modal',
  templateUrl: './speciality.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class SpecialityModal implements OnInit {
  public currentIndex: number;
  public allSpecialities: ArlenorSpeciality[];
  public allArchetypes: ArlenorArchetype[];
  public caractDescriptionEnum = CaractDescriptionEnum;

  public get speciality(): ArlenorSpeciality {
    const spe = this.allSpecialities[this.currentIndex];
    const archetypes = this.allArchetypes.filter(archetype => archetype.codeSpeciality === spe.code);
    if (archetypes.length > 0) spe.archetype01 = archetypes[0];
    if (archetypes.length > 1) spe.archetype02 = archetypes[1];
    return spe;
  }

  constructor(
    public dialogRef: MatDialogRef<SpecialityModal>,
    private _storeService: StoreService,
    @Inject(MAT_DIALOG_DATA) public data: ArlenorSpeciality
  ) {}

  public ngOnInit(): void {
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();
    this.allArchetypes = this._storeService.$allArchetypes.value;
    this.allSpecialities.sort((a, b) => a.name.localeCompare(b.name));
    this.currentIndex = this.data ? this.allSpecialities.findIndex(speciality => speciality.code === this.data.code) : 0;
  }

  public previous() {
    if (this.currentIndex === 0) this.currentIndex = this.allSpecialities.length - 1;
    else this.currentIndex--;
  }

  public next() {
    if (this.currentIndex === this.allSpecialities.length - 1) this.currentIndex = 0;
    else this.currentIndex++;
  }
}
