<div class="encyclo-ether-land-view u-marginTop1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité d'Ether">
      <img class="encyclopedia-img" [src]="citeEtherImage" alt="La Cité d'Ether" />
      <div class="card is-info">Contenu à venir...</div>
      <!--div class="card" [innerHTML]="citeEtherResume"></div>
      <div class="card u-marginTop1" [innerHTML]="citeEtherDetails"></div-->
    </mat-tab>
    <mat-tab label="La Forêt de Miryden">
      <img class="encyclopedia-img" [src]="mirydenImage" alt="La Forêt de Miryden" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="Le Lac Utica">
      <img class="encyclopedia-img" [src]="uticaImage" alt="Le Lac Utica" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="Les Montagnes de Habivel">
      <img class="encyclopedia-img" [src]="habivelImage" alt="Les Montagnes de Habivel" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
  </mat-tab-group>
</div>
