import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ArlenorSkill, SkillTypesEnum } from '@src/models/arlenor/ArlenorSkill';
import { StoreService } from '@src/services/store.service';
import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-skills-view',
  templateUrl: './system-skills.view.html',
  styleUrls: [],
})
export class SystemSkillsView implements OnInit {
  public systemSkillsText01 = systemSkillsText01;
  public systemSkillsText02 = systemSkillsText02;
  public allSkills: ArlenorSkill[];

  private _destroyRef = inject(DestroyRef);

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this._storeService.$allSkills.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(value => {
      if (value) this.allSkills = value.filter(skill => skill.codeType !== SkillTypesEnum.Race.Code);
    });

    this._storeService.loadAllSkills();
  }
}

export const systemSkillsText01 = convertMarkdownToHTML(`
  Survivre en milieu hostile. Mentir. Utiliser un pouvoir élémentaire.
  Autant de savoir-faire qu'un personnage peut, ou non, développer au cours de sa vie.
  
  Cette section s'intéresse à ces **compétences** que les personnages apprennent ou non à utiliser
  et qui les différencient les uns des autres.`);

export const systemSkillsText02 = convertMarkdownToHTML(`
  Les bonus des compétences **ne sont pas cumulatives** !
  Exemple : Avoir **Ambidextrie** et **Épées à une main**, ne donne pas 2 bonus (encore moins 3) si la personne combat avec deux épées.`);
