<div class="card u-marginTop1" [innerHtml]="systemResultsText01"></div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Difficulté</th>
      <td mat-cell *matCellDef="let element" class="u-textBold">{{ element.name }}<br />{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="score0">
      <th mat-header-cell *matHeaderCellDef>Échec critique</th>
      <td mat-cell *matCellDef="let element">{{ element.scores[0] }}</td>
    </ng-container>

    <ng-container matColumnDef="score1">
      <th mat-header-cell *matHeaderCellDef>Échec à conséquence</th>
      <td mat-cell *matCellDef="let element">{{ element.scores[1] }}</td>
    </ng-container>

    <ng-container matColumnDef="score2">
      <th mat-header-cell *matHeaderCellDef>Échec simple</th>
      <td mat-cell *matCellDef="let element">{{ element.scores[2] }}</td>
    </ng-container>

    <ng-container matColumnDef="score3">
      <th mat-header-cell *matHeaderCellDef>Réussite à coût</th>
      <td mat-cell *matCellDef="let element">{{ element.scores[3] }}</td>
    </ng-container>

    <ng-container matColumnDef="score4">
      <th mat-header-cell *matHeaderCellDef>Réussite simple</th>
      <td mat-cell *matCellDef="let element">{{ element.scores[4] }}</td>
    </ng-container>

    <ng-container matColumnDef="score5">
      <th mat-header-cell *matHeaderCellDef>Réussite critique</th>
      <td mat-cell *matCellDef="let element">{{ element.scores[5] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="card u-marginTop1" [innerHtml]="systemResultsText02"></div>
