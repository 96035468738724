import { convertMarkdownToHTML } from '../../../../utils/conversions';

export const journalAsmodeePersos = convertMarkdownToHTML(`
  **Les personnages**

  *En cours d'écriture...*
`);

export const journalAsmodee01 = convertMarkdownToHTML(`
  **Scénario 1 : Le Temple de Kiranof**

  Le destin vous a amené au village de Kiranof où un mystère plane dans le temple. Quelque chose vous y attire sans comprendre.
  À l'intérieur ce sont des découvertes macabres et ce serait l'un des prêtres le responsable : il cherche à effectuer un rituel.
  Vous le combattez et sauvez deux personnes : Anabeth et Erick, ses parents.

  Ils vous racontent que leur enfant est un enfant réceptacle à démon (celui de Nahémar) et que le sien s'est réveillé.
  Ils racontent aussi qu'ils faisaient partis d'une société appelée "Eveil"
  ayant pour premier but de réaliser des découvertes scientifiques autour de la religion.
  Seulement des gens importants / riches / politiques ont commencé
  à dévier cette société secrète pour utiliser les enfants réceptacles à des fins personnelles.

  Et VOUS faites partie de ces enfants qui ont été sauvé des griffes de la Société de l'Eveil.
  Anabeth et Erick vous proposent alors de former une Coalition et de récupérer d'autres rebelles pour empêcher l'Eveil d'arriver à leurs fins...
`);

export const journalAsmodee02 = convertMarkdownToHTML(`
  **Scénario 2 : La Forêt d'Imerys**

  Anabeth et Erick pensent qu'un des enfants réceptacles vit à l'entrée de cette forêt.
  Vous avez croisé une certaine Mélane, sorcière aquatique aux charmes insoupçonnées...
  Et après plusieurs mésaventures, vous découvrez Amélia la fameuse enfant devenue voleuse/archéologue professionnelle,
  curieuse du passé, et avide de trésors.
  Son démon, Asmodée, s'est partiellement réveillé à votre arrivée et cela vous a entraîné dans un combat acharnée avec elle.

  À la fin, elle accepte de vous suivre pour se contrôler et éviter un nouveau massacre.
  Anabeth et Erick, eux, ont récupéré Jessica, une cuisinière et Joffrey, un barman.
`);

export const journalAsmodee03 = convertMarkdownToHTML(`
  **Scénario 3 : Les Mines de Jirakan**

  Après avoir commercé et tué quelques enfants "par la gravité", vous rencontrez Zelhana :
  une réceptacle vide qui ne veut pas avoir à faire à la Société de l'Eveil, ni à votre Coalition.
  Elle vous conduit sans trop de problèmes à travers le désert... puis dans les mines où vous vous perdez un peu :
  quelque chose perturbe le monde réel.

  Vous découvrez que la Société de l'Eveil a trouvé l'Etherolithe de Lilith et rencontrez l'un de leurs agents les plus forts.
  S'en suis un combat où tout explose et vous sortez de justesse grâce à Zelhana qui a récupéré Lilith (sans faire exprès)...
  elle vous accompagne désormais contre son gré.

  Anabeth et Erick, eux, ont récupéré Cléofée, une infirmière, Magnus, un mécanicien, et Lysandre, une ingénieure navigatrice.
  La Coalition a désormais tous ses membres maintenant. Et vous êtes à bord du BAMM (Bateau Aérien à Moteur Magique).
`);

export const journalAsmodee04 = convertMarkdownToHTML(`
  **Scénario 4 : La Lucifaniel**
  
  *En cours d'écriture...*
`);
