<character-template [number]="4" label="Choix des compétences" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <div *ngIf="idsSkills.length !== this.character.level.maxSkills" class="card is-warning">
      Compétences restantes à sélectionner :
      {{ character.level.maxSkills - idsSkills.length }}
    </div>

    <div *ngIf="idsSkills.length === this.character.level.maxSkills" class="card">
      Toutes les compétences ont été sélectionnées.
    </div>

    <skills-selection
      [level]="character.level"
      [idsSkills]="idsSkills"
      [skills]="allSkills"
      (outAdd)="addSkill($event)"
      (outRemove)="removeSkill($event)"
    ></skills-selection>
  </form>
</character-template>
