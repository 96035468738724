import { ModelAPI } from '../ModelAPI';
import { getEnumByCode, ModelEnum } from '../ModelEnum';

export class ArlenorStuff extends ModelAPI {
  // Variables à sauvegarder
  public name: string;
  public description: string;
  public urlImage: string | null;
  public codeType: string;

  // Variables dérivées
  public get image(): string | null {
    if (this.urlImage) return 'assets/images_filled/stuffs/' + this.urlImage;
    else if (this.codeType === StuffTypesEnum.WeaponShort.Code) return 'assets/images_filled/stuffs/sword.png';
    else if (this.type.Code === StuffTypesEnum.WeaponLong.Code) return 'assets/images_filled/stuffs/bow.png';
    else if (this.type.Code === StuffTypesEnum.Armor.Code) return 'assets/images_filled/stuffs/armor.png';
    return null;
  }

  public get type(): ModelEnum {
    return getEnumByCode(this.codeType, StuffTypesEnum);
  }

  constructor() {
    super();
    this.name = '';
    this.description = '';
    this.urlImage = null;

    this.codeType = StuffTypesEnum.Other.Code;
  }

  public init(name: string, type: ModelEnum, urlImage: string = null): void {
    this.name = name;
    this.codeType = type.Code;
    if (urlImage) this.urlImage = urlImage;
  }
}

export class StuffTypesEnum {
  public static WeaponShort: ModelEnum = { Code: 'WEAPON_SHORT', Libelle: 'Arme au corps à corps' };
  public static WeaponLong: ModelEnum = { Code: 'WEAPON_LONG', Libelle: 'Arme à distance' };
  public static Armor: ModelEnum = { Code: 'ARMOR', Libelle: 'Armure' };
  public static Accessory: ModelEnum = { Code: 'ACCESSORY', Libelle: 'Accessoire' };
  public static Potion: ModelEnum = { Code: 'POTION', Libelle: 'Potion' };
  public static Other: ModelEnum = { Code: 'OTHER', Libelle: 'Autre' };
}
