import { Component, OnInit } from '@angular/core';

import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { StoreService } from '../../../../services/store.service';
import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-coalition-view',
  templateUrl: './encyclo-coalition.view.html',
  styleUrls: ['./encyclo-secrets.view.scss'],
})
export class EncycloCoalitionView implements OnInit {
  public coalitionText = coalitionText;
  public characters: ArlenorCharacter[];

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  public get isPlayer(): boolean {
    return this._storeService.isPlayer;
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.characters = [];

    const anabeth = new ArlenorCharacter();
    anabeth.name = 'Anabeth';
    anabeth.avatar = 'coalition/anabeth';
    anabeth.description = 'Meneuse / Archéologue';
    anabeth.traits = `Humaine grande, sûre d'elle et autoritaire`;
    anabeth.story = `Anabeth est convaincue de pouvoir un jour discuter avec Arlénor, sa créatrice.
    Elle donna alors avec son mari Erick, naissance à Guélin : un réceptacle.
    Mais... en voyant les traitements, qu'il subissait avec la Société de l'Eveil, elle a tout fait pour le sauver et quitta la Société de l'Eveil.
    Aujourd'hui, elle culpabilise d'y avoir participé et veut se montrer plus forte qu'avant.`;
    this.characters.push(anabeth);

    const erick = new ArlenorCharacter();
    erick.name = 'Erick';
    erick.avatar = 'coalition/erick';
    erick.description = 'Meneur / Chercheur';
    erick.traits = `Humain grand, sûr de lui, élégant et ferme`;
    erick.story = `Erick est un fervant du savoir : la connaissance est pour lui synonyme de pouvoir.
    La découverte du premier Etherolithe lui a montré un nouveau chemin à parcourir, un nouveau but à atteindre.
    Il aime éperdument Anabeth et depuis qu'il sait que ce que leur enfant Guélin a subit,
    il hait de tout son être la Société de l'Eveil, qui a trahit sa confiance de scientifique.`;
    this.characters.push(erick);

    const lysandre = new ArlenorCharacter();
    lysandre.name = 'Lysandre';
    lysandre.avatar = 'coalition/lysandre';
    lysandre.description = 'Ingénieure / Navigatrice';
    lysandre.traits = `Elfe calme, posée, mais la tête dans ses pensées`;
    lysandre.story = `Lysandre fut adoptée par une haute famille de Faradel avec Cléofée.
    Leur statut leur a permis d'accéder à beaucoup de connaissances, et Lysandre fut tout de suite éprise par l'ingénieurie et la cartographie.
    Perfectionniste, sérieuse et curieuse, elle voulut vite quitter le nid familial maison pour découvrir les recoins du monde,
    mais fini par être recrutée par la Société de l'Eveil.`;
    this.characters.push(lysandre);

    const cleofee = new ArlenorCharacter();
    cleofee.name = 'Cléofée';
    cleofee.avatar = 'coalition/cleofee';
    cleofee.description = 'Infirmière';
    cleofee.traits = `Elfe petite, très douce, toujours dans l'empathie`;
    cleofee.story = `Cléofée fut adoptée par une haute famille de Faradel avec Lysandre.
    Leur statut leur a permis d'accéder à beaucoup de connaissances, et Cléofée fut tout de suite éprise par la médecine.
    Empathique, attentionnée et appliquée, elle cherche à mettre à profit son savoir et son altruisme pour sauver les gens dans le besoin.
    Cléofée, très attachée à sa grande soeur, la suivit dans son entrée au sein de la Société de l'Eveil pour finir par y être prisonnière.`;
    this.characters.push(cleofee);

    const jessica = new ArlenorCharacter();
    jessica.name = 'Jessica';
    jessica.avatar = 'coalition/jessica';
    jessica.description = 'Cuisinière';
    jessica.traits = 'Naine robuste, un peu matriache près à en découdre';
    jessica.story = `Jessica est née dans les Montagnes de Kazador mais s'est sentie étouffée par son peuple
    et leurs traditions. Ayant une forte passion pour la cuisine, elle ouvra un ptit restaurant mobile.
    Suite à une rencontre fortuite, Jessica considère Anabeth comme sa soeur.`;
    this.characters.push(jessica);

    const joffrey = new ArlenorCharacter();
    joffrey.name = 'Joffrey';
    joffrey.avatar = 'coalition/joffrey';
    joffrey.description = 'Barman / Réceptacle vide';
    joffrey.traits = `Humain charmant avec le sourire aux lèvres, et un parfum enivrant`;
    joffrey.story = `Joffrey est un aventurier à la retraite... suite à la mort d'un de ses compagnons.
    Il trouva une passion à aider les gens derrière un bar, aux côtés d'un Bob. Gardant toujours le sourire,
    il raconte ses histoires, écoute les autres, et essaie de leur changer les idées.`;
    this.characters.push(joffrey);

    const magnus = new ArlenorCharacter();
    magnus.name = 'Magnus';
    magnus.avatar = 'coalition/magnus';
    magnus.description = 'Mécanicien / Réceptacle vide';
    magnus.traits = `Mutant peu souriant et peu sociable, fumant souvent`;
    magnus.story = `Magnus est devenu rapidement orphelin, ses parents adoptifs n'aimant pas sa mutation.
    Il fut relégué aux rues de Faradel, et fit tout ce qu'il peut pour survivre.
    Il découvrit la beauté des matériaux et apprit à réparer / construire des objets, des machines...
    Magnus considère que les méchanismes ont moins de secrets que les personnes.`;
    this.characters.push(magnus);

    const amelia = new ArlenorCharacter();
    amelia.name = 'Amélia';
    amelia.avatar = 'coalition/amelia';
    amelia.description = 'Archéologue / Réceptacle Asmodée';
    amelia.traits = `Humaine habile de ses mains, curieuse et rusée`;
    amelia.story = `Amélia a été une enfant réceptacle placée dans un village à l'orée de la forêt.
    Elle était appréciée de tous, notamment de son père adoptif qu'elle ne pu laisser seul.
    Pourtant Amélia rêve de découvrir le monde et ses secrets (surtout les ruines liées au passé),
    ce qu'elle a finalement fait quand sa divinité s'est révélée.`;
    this.characters.push(amelia);

    const zelhana = new ArlenorCharacter();
    zelhana.name = 'Zelhana';
    zelhana.avatar = 'coalition/zelhana';
    zelhana.description = 'Guide désertique / Réceptacle Lilith';
    zelhana.traits = `Mutante discrète, méfiante et peu aimable au premier abord`;
    zelhana.story = `Zelhana est une mutante orpheline qui était au service d'une bonne famille Jirakanienne.
    Sa connaissance du désert de Sanderten lui permet d'avoir un poste en tant que guide vers les mines, et d'être plus tard affranchie.
    Mais à un moment, une de ses clientes l'a kidnappée pour réaliser des expériences sur sa mutation.
    Aujourd'hui Zelhana est très méfiante envers les inconnus (surtout les "gentilles" personnes).`;
    this.characters.push(zelhana);
  }
}

export const coalitionText = convertMarkdownToHTML(`
**L'Histoire de la Coalition**

Alliance créée entre les PJs et d'anciens membres de la Société de l'Eveil, leur but est de récupérer les réceptacles et de les protéger.
Et potientellement contrecarrer les plans de la Société de l'Eveil, considérée comme ennemie.`);
