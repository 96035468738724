import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'expand-bloc',
  templateUrl: './expand-bloc.component.html',
  styleUrls: ['./expand-bloc.component.scss'],
})
export class ExpandBlocComponent {
  @Input() public number: number;
  @Input() public label: string;
  @Input() public isOpen: boolean;
  @Input() public isWarning: boolean;
  @Output() public outToggle = new EventEmitter<null>();

  public toggleBloc() {
    this.outToggle.emit();
  }
}
