<character-template [number]="2" label="Choix des caractéristiques" [isInvalid]="form.controls.pointsLeft.value !== 0">
  <form [formGroup]="form" class="u-flexColumn">
    <table mat-table [dataSource]="dataSource" class="hide-on-mobile">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-30">Nom</th>
        <td mat-cell *matCellDef="let element" class="col-30">
          <span class="hide-on-tablet">{{ element.libelle }}&nbsp;({{ element.code }})</span>
          <span class="show-on-tablet">{{ element.libelle }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="range">
        <th mat-header-cell *matHeaderCellDef class="col-50"></th>
        <td mat-cell *matCellDef="let element" class="col-50">
          <mat-slider [min]="element.isNullable ? 0 : 1" [max]="maxCaract" step="1" [showTickMarks]="true">
            <input type="range" matSliderThumb [formControlName]="element.key" (change)="changeCaract()" />
          </mat-slider>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="col-10">Valeur</th>
        <td mat-cell *matCellDef="let element" class="col-10">
          {{ form.controls[element.key].value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-10">Actions</th>
        <td mat-cell *matCellDef="let element" class="col-10 u-textRight">
          <button
            type="button"
            mat-icon-button
            color="primary"
            class="is-small u-marginLeftAuto"
            (click)="openCaractPopup(element.code)"
          >
            ?
          </button>
        </td>
      </ng-container>

      <!--tr mat-header-row *matHeaderRowDef="displayedColumns"></tr-->
      <tr mat-row *matRowDef="let row; columns: ['name', 'range', 'value', 'actions']"></tr>
    </table>

    <table mat-table [dataSource]="dataSource" class="show-on-mobile">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="col-10">Nom</th>
        <td mat-cell *matCellDef="let element" class="col-10">
          <button type="button" mat-stroked-button color="primary" (click)="openCaractPopup(element.code)">
            {{ element.code }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="range">
        <th mat-header-cell *matHeaderCellDef class="col-70"></th>
        <td mat-cell *matCellDef="let element" class="col-70">
          <mat-slider [min]="element.isNullable ? 0 : 1" [max]="maxCaract" step="1" [showTickMarks]="true">
            <input type="range" matSliderThumb [formControlName]="element.key" (change)="changeCaract()" />
          </mat-slider>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef class="col-10">Valeur</th>
        <td mat-cell *matCellDef="let element" class="col-10 u-textLeft">
          {{ form.controls[element.key].value }}
        </td>
      </ng-container>

      <!--tr mat-header-row *matHeaderRowDef="displayedColumns"></tr-->
      <tr mat-row *matRowDef="let row; columns: ['name', 'range', 'value']"></tr>
    </table>

    <div class="u-flexRow u-alignStart u-flexColumn-on-tablet u-alignCenter-on-tablet">
      <div class="card u-width50">
        Le Bonus d'Initiative est à : <b>{{ getInitiative() }}</b>
        <br />
        Les Points de Vie sont à : <b>{{ getPointsDeVie() }}</b>
        <span *ngIf="form.controls.mag.value === 0" class="u-textRed">
          <br />
          <b>Le personnage n'aura pas de capacités magiques.</b>
        </span>
      </div>
      <div *ngIf="form.controls.pointsLeft.value !== 0" class="card u-width50 is-warning">
        Points restants à répartir : {{ form.controls.pointsLeft.value }}
      </div>
      <div *ngIf="form.controls.pointsLeft.value === 0" class="card u-width50">Tous les points ont été répartis.</div>
    </div>
  </form>
</character-template>
