<h3>Description de la race</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <button type="button" mat-icon-button (click)="previous()">
      <i class="icon-chevron-left"></i>
    </button>
    <img class="element-modal-img" [src]="race.image" [alt]="race.name" />
    <button type="button" mat-icon-button (click)="next()">
      <i class="icon-chevron-right"></i>
    </button>
  </div>
  <h2>{{ race.name }}</h2>
  <p class="u-textCenter u-textItalic">
    {{ race.infoAge }}
    <br />Apparence : {{ race.infoAppareance }}
  </p>
  <p [innerHtml]="race.description"></p>
  <div class="section-moreinfos">
    <p comments>
      <span class="u-textBold">{{ race.ratioWorld }}%</span>
      du monde sont des {{ race.name.toLowerCase() }}s
    </p>
    <p comments>
      <span>Localisations</span><br />
      <span *ngFor="let location of race.locations">- {{ location }}<br /></span>
    </p>
    <p comments>
      <span class="u-textBold">{{ race.ratioMagic }}%</span>
      des {{ race.name.toLowerCase() }}s peuvent utiliser la magie
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
