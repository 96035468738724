<div class="encyclo-secrets-view u-marginTop1">
  @if (!isAdmin && !isMaster && !isPlayer) {
    <div class="card is-info">Données réservées aux Maîtres du Jeu et/ou Joueurs.</div>
  } @else {
    <div class="card" [innerHtml]="coalitionText"></div>
    <div *ngIf="!characters.length" class="card is-info u-marginTop1">Aucun personnage.</div>
    <div class="grid-list grid-2 u-marginTop1">
      <div *ngFor="let character of characters" class="character-item grid-element">
        <img *ngIf="character.profil" class="character-image is-big" [src]="character.profil" [alt]="character.name" />
        <div>
          <h3>{{ character.name }}</h3>
          <p class="u-textBold u-marginBottom05">{{ character.description }}</p>
          <p class="u-marginBottom05" comments>{{ character.traits }}</p>
          <p class="u-marginBottom0">{{ character.story ? character.story : 'Aucune description' }}</p>
        </div>
      </div>
    </div>
  }
</div>
