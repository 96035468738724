<div class="legacy-view bg-global">
  <div class="layout-full-view">
    <div class="legacy-center">
      <h1>Mentions légales</h1>
      <div separator></div>
      <p>Statut du propriétaire : <b>Particulier</b></p>
      <p>
        <b>
          Hébergeur Netlify<br />
          2325 3rd Street, Suite 296<br />
          San Francisco, California 94107
        </b>
      </p>
      <p>
        Pour contacter cet hébergeur, rendez-vous à l'adresse suivante :
        <b>
          <a href="https://www.netlify.com/" target="_blank">https://www.netlify.com/</a>
        </b>
        <br />
        Ou par mail : <b>{{ 'support@netlify.com' }}</b>
      </p>
      <p>
        Note : Tous les dessins, images et illustrations de ce site ne sont pas libres de droit.<br />
        Certains sont ma propriété. D'autres viennent de Pixabay, ou sont générées avec MidJourney et Wonder.<br />
        Merci de ne pas les utiliser sans mon autorisation.
      </p>
    </div>
  </div>
</div>
