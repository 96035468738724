import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

import '@lottiefiles/lottie-player';

@Component({
  selector: 'anim-finish',
  templateUrl: './anim-finish.component.html',
  styleUrls: ['./anim-finish.component.scss'],
})
export class AnimFinishComponent implements AfterViewInit {
  @Output() public outFinish = new EventEmitter();
  @ViewChild('lottiePlayer', { static: true }) lottiePlayer!: ElementRef;

  public isLottieLoaded: boolean = false;

  public ngAfterViewInit(): void {
    const lottieElement = this.lottiePlayer.nativeElement;
    lottieElement.addEventListener('complete', () => {
      console.log('Animation terminée, on rejoue !');
      this.outFinish.emit();
    });
  }
}
