import { Component, OnInit } from '@angular/core';

import { ArlenorRace } from '@src/models/arlenor/ArlenorRace';
import { getListRaces } from '@src/models/data/ListRaces';
import { DifficultyEnum } from '@src/models/ModelEnum';
import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-races-view',
  templateUrl: './system-races.view.html',
  styleUrls: [],
})
export class SystemRacesView implements OnInit {
  public systemRacesText = systemRacesText;
  public allRaces: ArlenorRace[];

  public ngOnInit(): void {
    this.allRaces = getListRaces().filter(race => race.difficulty !== DifficultyEnum.Impossible.Code);
  }

  public getDifficultyColor(race: ArlenorRace) {
    if (race.difficulty === DifficultyEnum.Simple.Code) return 'status-ocean';
    if (race.difficulty === DifficultyEnum.Standard.Code) return 'status-green';
    if (race.difficulty === DifficultyEnum.Complexe.Code) return 'status-yellow';
    else return '';
  }

  public getDifficultyLibelle(race: ArlenorRace) {
    if (race.difficulty === DifficultyEnum.Simple.Code) return DifficultyEnum.Simple.Libelle;
    if (race.difficulty === DifficultyEnum.Standard.Code) return DifficultyEnum.Standard.Libelle;
    if (race.difficulty === DifficultyEnum.Complexe.Code) return DifficultyEnum.Complexe.Libelle;
    else return '';
  }
}

export const systemRacesText = convertMarkdownToHTML(`
  Chaque **race** possède ses avantages et ses inconvénients, ainsi qu'une certaine difficulté
  ou facilité à être jouée dans le Monde d'Arlénor. Mieux vaut privilégier les races les plus simples
  pour les nouveaux joueurs, et les plus complexes pour les amateurs de risques.
  Les **races** sont présentées ici dans l'ordre de difficulté croissante.
  
  Note : Les Célestiens ne sont pas une race jouable vu la différence de magie qu'ils utilisent et le lieu où ils se trouvent.`);
