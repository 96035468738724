<div class="encyclo-somni-land-view u-marginTop1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité de Somni">
      <img class="encyclopedia-img" [src]="citeSomniImage" alt="La Cité de Somni" />
      <div class="card is-info">Contenu à venir...</div>
      <!--div class="card" [innerHTML]="citeSomniResume"></div>
      <div class="card u-marginTop1" [innerHTML]="citeSomniDetails"></div-->
    </mat-tab>
    <mat-tab label="Les Terres Oubliées">
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
  </mat-tab-group>
</div>
