import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { InformationModal } from '@src/components/modals/information/information.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-identity',
  templateUrl: './character-identity.component.html',
  styleUrls: ['./character-identity.component.scss'],
})
export class CharacterIdentityComponent implements OnInit {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public character: ArlenorCharacter;

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      avatar: new FormControl(null),
      name: new FormControl(null, Validators.required),
      age: new FormControl(null),
      gender: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;

    // Init
    this.form.controls.avatar.setValue(this.character.avatar);
    this.form.controls.name.setValue(this.character.name);
    this.form.controls.age.setValue(this.character.age);
    this.form.controls.gender.setValue(this.character.gender);

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public changeAvatar(event: Event) {
    const target = <HTMLInputElement>event.target;
    const file = target && target.files ? target.files[0] : null;
    if (!file) return;
    if (file.size > 1000000) {
      target.value = null;
      this.dialog.open(InformationModal, {
        data: {
          title: `Alerte sur l'importation`,
          content: `Votre image est de qualité / de taille supérieure à 1 Mo.
        <br>Elle n'est pas donc pas importée.`,
        },
      });
    } else {
      let image64: string;
      const promiseGetImage64 = new Promise(function (resolve) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          image64 = reader.result as string;
          return resolve(true);
        };
        reader.onerror = function (error) {
          console.log(error);
        };
      });
      Promise.all([promiseGetImage64]).then(() => {
        this.form.controls.avatar.setValue(image64);
      });
    }
  }

  public getProfil(avatar: string) {
    if (avatar && avatar.indexOf('data') === 0) return avatar;
    else if (avatar) return 'assets/images_filled/characters/' + avatar + '.png';
    else return '';
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.avatar = this.form.controls.avatar.value;
    newCharacter.name = this.form.controls.name.value;
    newCharacter.age = this.form.controls.age.value;
    newCharacter.gender = this.form.controls.gender.value;
    this._storeService.changeCharacterIdentity(newCharacter);
  }
}
