<div class="card is-info u-marginTop1">Contenu à venir...</div>

<!--div class="u-flexColumn u-marginTop1">
<expand-bloc label="Les actions courtes" [isOpen]="fightChoice === 1" (outToggle)="changeFightChoice(1)">
  <div>Contenu à venir...</div>
</expand-bloc>

<expand-bloc label="Les actions longues" [isOpen]="fightChoice === 2" (outToggle)="changeFightChoice(2)">
  <div>Contenu à venir...</div>
</expand-bloc>

<expand-bloc label="Les soins en / hors combat" [isOpen]="fightChoice === 3" (outToggle)="changeFightChoice(3)">
  <div>Contenu à venir...</div>
</expand-bloc>
</div-->
