<div class="crystals-view bg-roleplay">
  <div *ngIf="currentSpeciality" class="layout-view">
    <h1 class="crystals-title">{{ title }}</h1>

    <!-- Menu en haut -->
    <div class="side-menu">
      <div class="side-list">
        <div
          *ngFor="let spe of allSpecialities"
          class="side-element"
          [class.selected]="spe.code === currentSpeciality?.code"
        >
          <img [src]="spe.image" [title]="spe.name" [alt]="spe.name" (click)="moveToSpe(spe.code)" />
        </div>
      </div>
    </div>

    <!-- Icone et titre -->
    <div class="crystals-layout u-marginTop2">
      <div class="crystals-top card">
        <div *ngIf="currentSpeciality.archetype01" class="crystals-section hide-on-mobile">
          <div>Archétype</div>
          <h3 class="u-marginBottom1">"{{ currentSpeciality.archetype01.name }}"</h3>
          <img
            class="crystal-img u-marginBottom1 rounded"
            [src]="currentSpeciality.getImageForArchetype()"
            [alt]="currentSpeciality.archetype01.name"
          />
          <p>
            {{ currentSpeciality.archetype01.description }}
          </p>
          <div *ngIf="currentSpeciality.archetype01.skill" class="power-line u-justifyCenter">
            <div class="power-img-layout">
              <img
                class="power-img"
                [src]="currentSpeciality.archetype01.skill.image"
                [alt]="currentSpeciality.archetype01.skill.name"
              />
            </div>
            <span class="power-txt">{{ currentSpeciality.archetype01.skill.name }}</span>
          </div>
        </div>
        <div class="crystals-separator hide-on-mobile"></div>
        <div class="crystals-section">
          <img class="crystal-img" [src]="currentSpeciality.image" [alt]="currentSpeciality.name" />
          <h2 class="u-marginBottom1">{{ currentSpeciality.name }}</h2>
          <p class="u-marginBottom05">{{ currentSpeciality.description }}</p>
          <span [class]="'status-' + currentSpeciality.color">
            <span>{{ currentSpeciality.role.name }}</span>
            <span class="u-marginLeft05" [class]="currentSpeciality.role.icon"></span>
          </span>
          <p class="u-marginTop1" comments>Apparence : {{ currentSpeciality.appearance }}</p>
          <div *ngIf="currentSpeciality.archetype01" class="show-on-mobile u-marginTop1">
            <button type="button" mat-stroked-button color="primary">
              Archétype "{{ currentSpeciality.archetype01.name }}"
            </button>
          </div>
          <div *ngIf="currentSpeciality.archetype02" class="show-on-mobile u-marginTop1">
            <button type="button" mat-stroked-button color="primary">
              Archétype "{{ currentSpeciality.archetype02.name }}"
            </button>
          </div>
        </div>
        <div class="crystals-separator hide-on-mobile"></div>
        <div *ngIf="currentSpeciality.archetype02" class="crystals-section hide-on-mobile">
          <div>Archétype</div>
          <h3 class="u-marginBottom1">"{{ currentSpeciality.archetype02.name }}"</h3>
          <img
            class="crystal-img u-marginBottom1 rounded"
            [src]="currentSpeciality.getImageForArchetype()"
            [alt]="currentSpeciality.archetype02.name"
          />
          <p>
            {{ currentSpeciality.archetype02.description }}
          </p>
          <div *ngIf="currentSpeciality.archetype02.skill" class="power-line u-justifyCenter">
            <div class="power-img-layout">
              <img
                class="power-img"
                [src]="currentSpeciality.archetype02.skill.image"
                [alt]="currentSpeciality.archetype02.skill.name"
              />
            </div>
            <span class="power-txt">{{ currentSpeciality.archetype02.skill.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Pouvoirs -->
    <div class="crystals-layout u-marginTop1">
      <div class="crystals-left card">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Liste des Pouvoirs">
            <div *ngFor="let rank of ranks" class="powers-content">
              <div class="powers-content-title">Rang {{ rank.Libelle }}</div>
              <div
                *ngFor="let power of getRankPowers(rank)"
                class="power-line u-pointer"
                [class.selected]="power.code === selectedPower?.code"
                (click)="selectPower(power)"
              >
                <power-image [power]="power"></power-image>
                <span class="power-txt">{{ power.name }}</span>
              </div>
              <div *ngIf="getRankPowers(rank).length === 0">Aucun pouvoir</div>
            </div>
          </mat-tab>
          <mat-tab label="Spécificités de la Classe" [disabled]="!currentSpeciality.explanations">
            <ng-container *ngIf="currentSpeciality.code === 'INVOCATEUR'">
              <div class="card u-textLeft u-marginTop1" [innerHtml]="invocateurText01"></div>
              <div class="card u-textLeft u-marginTop1" [innerHtml]="invocateurText02"></div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div class="crystals-right card">
        <h3 class="u-marginBottom1">Détails du pouvoir</h3>
        <div class="power-separator"></div>
        <ng-container *ngIf="selectedPower">
          <div>
            {{ selectedPower.type.Libelle }} de Rang
            {{ selectedPower.rank.Libelle }}
          </div>
          <power-image [isBig]="true" [power]="selectedPower"></power-image>
          <h2 class="u-marginTop1">{{ selectedPower.name }}</h2>
          <div class="power-separator"></div>
          <div>
            {{ selectedPower.description ? selectedPower.description : 'Aucune description' }}
          </div>
          <div class="power-separator"></div>
          <div>
            <i class="icon-spinner" title="Catégorie du pouvoir"></i>&nbsp;
            <span title="Catégorie du pouvoir">{{ selectedPower.category.Libelle }}</span>
          </div>
          <div class="u-marginTop05">
            <i class="icon-enlarge" title="Portée du pouvoir"></i>&nbsp;
            <span title="Portée du pouvoir">{{ selectedPower.range.Libelle }}</span>
          </div>
          <div class="u-marginTop05 u-marginBottom05">
            <i class="icon-hour-glass" title="Durée et rechargement du pouvoir"></i>&nbsp;
            <span title="Durée et rechargement du pouvoir">{{ selectedPower.duration.Libelle }}</span>
          </div>
          <div *ngIf="isAdmin" class="u-marginTop1">
            <button type="button" mat-stroked-button color="primary" (click)="openModifyPower()">
              Modifier le pouvoir
            </button>
          </div>
          <div *ngIf="isAdmin" class="u-marginTop1 u-marginBottom05">
            <button type="button" mat-stroked-button color="warn" (click)="openDeletePower()">
              Supprimer le pouvoir
            </button>
          </div>
        </ng-container>
        <div *ngIf="!selectedPower">
          <div>Pas de pouvoir sélectionné</div>
          <div *ngIf="isAdmin" class="u-marginTop1 u-marginBottom05">
            <button type="button" mat-stroked-button color="primary" (click)="openAddPower()">
              Ajouter un pouvoir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
