<div class="expand-bloc">
  <div class="expand-bloc-title" [class.is-open]="isOpen" [class.is-warning]="isWarning" (click)="toggleBloc()">
    <div class="u-flexRow u-alignCenter">
      <span *ngIf="number" class="expand-bloc-number">{{ number }}</span>
      <span>{{ label }}</span>
    </div>
    <span *ngIf="!isOpen" class="icon-chevron-up"></span>
    <span *ngIf="isOpen" class="icon-chevron-down"></span>
  </div>
  <div class="expand-bloc-content" [class.is-open]="isOpen">
    <ng-content></ng-content>
  </div>
</div>
