<header>
  <!-------------------------- Tablet / PC version ---------------------------->

  <div class="header-section" [class.is-home]="onHome">
    <div class="header-main">
      <a
        class="header-icon link"
        routerLink="/"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        <i class="icon-logo u-marginRight05"></i>
        {{ pages.home }}
        {{ isAdmin ? '(Administrateur)' : '' }}
        {{ isMaster ? '(Maitre du Jeu)' : '' }}
        {{ isPlayer ? '(Joueur)' : '' }}
      </a>
    </div>
    <nav>
      <a class="link" routerLink="/univers" routerLinkActive="active-link">
        {{ pages.universe }}
      </a>
      <a class="link" routerLink="/jeu-de-roles" routerLinkActive="active-link">
        {{ pages.roleplay }}
      </a>
      <a class="link" routerLink="/celestia" routerLinkActive="active-link">
        {{ pages.celestia }}
      </a>
      <div class="header-options-icon link" (click)="openUserPopup()">
        <i class="icon-address-book"></i>
      </div>
    </nav>
  </div>
  <div class="header-subsection" [class.is-active]="onUniverseRoute">
    <nav>
      <a
        class="link"
        routerLink="/univers/calendrier"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.calendar }}
      </a>
      <a
        class="link"
        routerLink="/univers/encyclopedie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.encyclopedia }}
      </a>
      <a class="link" routerLink="/univers" routerLinkActive="active-link" [routerLinkActiveOptions]="activeOptions">
        {{ pages.worldmap }}
      </a>
      <a
        class="link"
        routerLink="/univers/religion"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.religion }}
      </a>
      <a
        class="link"
        routerLink="/univers/magie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.magic }}
      </a>
      <a
        class="link"
        routerLink="/univers/races"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.races }}
      </a>
    </nav>
  </div>
  <div class="header-subsection" [class.is-active]="onRoleplayRoute">
    <nav>
      <!--a class="link" routerLink="/jeu-de-roles/avatar" routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions">{{ pages.avatar }}</a-->
      <!--a
        class="link"
        routerLink="/jeu-de-roles/creature"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.creature }}
      </a-->
      <a
        class="link"
        routerLink="/jeu-de-roles/personnage"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.character }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.adventure }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles/systeme"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.system }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles/cristaux"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.crystals }}
      </a>
    </nav>
  </div>
  <div class="header-subsection" [class.is-active]="onCelestiaRoute">
    <nav>
      <a
        class="link"
        routerLink="/celestia/chronologie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.chronology }}
      </a>
      <a class="link" routerLink="/celestia" routerLinkActive="active-link" [routerLinkActiveOptions]="activeOptions">
        {{ pages.etincelles }}
      </a>
      <a
        class="link"
        routerLink="/celestia/academie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
      >
        {{ pages.academy }}
      </a>
    </nav>
  </div>

  <!-------------------------- Mobile version ---------------------------->

  <div class="header-mobile-section">
    <div class="header-main">
      <a class="header-icon link" routerLink="/" (click)="hideMenu()">
        <i class="icon-logo"></i>
      </a>
      <div class="header-icon link" (click)="toggleMenu()">
        <i class="icon-menu"></i>
      </div>
    </div>
  </div>
  <div *ngIf="showSubmenu" class="header-mobile-subsection">
    <nav>
      <a class="link" routerLink="/univers" routerLinkActive="active-link" (click)="toggleMenu()">
        {{ pages.universe }}
      </a>
      <a
        class="link"
        routerLink="/univers"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.worldmap }}
      </a>
      <a
        class="link"
        routerLink="/univers/calendrier"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.calendar }}
      </a>
      <a
        class="link"
        routerLink="/univers/religion"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.religion }}
      </a>
      <a
        class="link"
        routerLink="/univers/magie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.magic }}
      </a>
      <a
        class="link"
        routerLink="/univers/races"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.races }}
      </a>
      <a
        class="link"
        routerLink="/univers/encyclopedie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.encyclopedia }}
      </a>
    </nav>
    <nav>
      <a class="link" routerLink="/jeu-de-roles" routerLinkActive="active-link" (click)="toggleMenu()">
        {{ pages.roleplay }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.adventure }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles/systeme"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.system }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles/cristaux"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.crystals }}
      </a>
      <a
        class="link"
        routerLink="/jeu-de-roles/personnage"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.character }}
      </a>
      <!--a
        class="link"
        routerLink="/jeu-de-roles/creature"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.creature }}
      </a-->
      <!--a class="link" routerLink="/jeu-de-roles/avatar" routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions" (click)="toggleMenu()">{{ pages.avatar }}</a-->
    </nav>
    <nav>
      <a class="link" routerLink="/celestia" routerLinkActive="active-link" (click)="toggleMenu()">
        {{ pages.celestia }}
      </a>
      <a
        class="link"
        routerLink="/celestia"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.etincelles }}
      </a>
      <a
        class="link"
        routerLink="/celestia/chronologie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.chronology }}
      </a>
      <a
        class="link"
        routerLink="/celestia/academie"
        routerLinkActive="active-link"
        [routerLinkActiveOptions]="activeOptions"
        (click)="toggleMenu()"
      >
        {{ pages.academy }}
      </a>
    </nav>
    <nav>
      <a class="link" (click)="openUserPopup()">
        <span *ngIf="isAdmin" class="u-marginRight05">Votre rôle actuel : Administrateur</span>
        <span *ngIf="isMaster" class="u-marginRight05">Votre rôle actuel : Maitre du Jeu</span>
        <span *ngIf="isPlayer" class="u-marginRight05">Votre rôle actuel : Joueur</span>
        <span *ngIf="!isAdmin && !isMaster && !isPlayer" class="u-marginRight05">Votre rôle actuel : Visiteur</span>
        <i class="icon-address-book"></i>
      </a>
    </nav>
    <div white-separator>•</div>
  </div>
</header>
