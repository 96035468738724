<div class="religion-view bg-universe">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="card" [innerHtml]="resume"></div>
  </head-layout>

  <div separator></div>

  <div class="layout-bloc-view">
    <div class="religion-list">
      <img
        *ngFor="let angel of allAngels"
        [src]="currentDivinity.name === angel.name ? angel.imageSelected : angel.image"
        [alt]="angel.name"
        (mouseover)="changeSelection(angel)"
        (click)="changeSelection(angel)"
      />
    </div>
    <div class="religion-description card u-marginTop1 u-marginBottom1">
      <div>
        <h3 class="u-marginBottom05">{{ currentDivinity.isInversed ? 'La Cabale Inversée' : 'La Sainte Cabale' }}</h3>
        <h2 class="u-marginBottom05">
          {{ currentDivinity.libNum }}<span class="u-textLowercase">{{ currentDivinity.isInversed ? 'i' : '' }}</span> -
          {{ currentDivinity.name }}, {{ currentDivinity.title }}
        </h2>
        <h3>{{ currentDivinity.symbols }}</h3>
      </div>
      <p comments>"{{ currentDivinity.comment }}"<br />- {{ currentDivinity.commentName }}</p>
      <div>
        <h3>Pouvoir divin : {{ currentDivinity.powerName }}</h3>
        <p>{{ currentDivinity.power }}</p>
      </div>
    </div>
    <div class="religion-list">
      <img
        *ngFor="let demon of allDemons"
        [src]="currentDivinity.name === demon.name ? demon.imageSelected : demon.image"
        [alt]="demon.name"
        (mouseover)="changeSelection(demon)"
        (click)="changeSelection(demon)"
      />
    </div>
  </div>
</div>
