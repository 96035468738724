import { CelestiaCharacter } from '../celestia/CelestiaCharacter';

export function getListCelestias(): CelestiaCharacter[] {
  const celestiaCharacters: CelestiaCharacter[] = [];

  const celestia01 = new CelestiaCharacter('Elisa', 'Felnéris', 24);
  celestia01.astro = 'Cancer';
  celestia01.mbti = 'INFJ';
  celestia01.orientation = 'Hétérosexuelle';
  celestia01.situation = 'En couple discret avec Romain';
  celestia01.relations = [`Jérémy est comme un frère pour elle`, `Delphyn est sa meilleure amie`, `Sinon elle reste plutôt seule`];
  celestia01.emotion = 'Désespoir';
  celestia01.grade = 'Mage de Lumière';
  celestia01.image = 'assets/images_filled/celestia/elisa_ld.webp';
  celestia01.qualities = ['Sensible', 'Timide', 'Réservée', 'Prudente', 'Rêveuse'];
  celestia01.defaults = ['Anxieuse', 'Lunatique', 'Complexée', 'Distraite'];
  celestia01.comment = `Rien n'arrive par hasard, il doit y avoir une raison.`;
  celestiaCharacters.push(celestia01);

  const celestia02 = new CelestiaCharacter('Jérémy', 'Lecuyer', 22);
  celestia02.astro = 'Lion';
  celestia02.mbti = 'INFJ';
  celestia02.orientation = 'Homosexuel';
  celestia02.situation = 'En couple ouvert avec Zachary';
  celestia02.relations = [
    `Elisa est comme une soeur pour lui`,
    `Romain lui fait à la fois peur, et l'attire`,
    `Sinon il est ami avec Delphyn et Diana, mais il a du mal avec Alice`,
  ];
  celestia02.emotion = 'Peur';
  celestia02.grade = 'Mage de Feu';
  celestia02.image = 'assets/images_filled/celestia/jeremy_ld.webp';
  celestia02.qualities = ['Amical', 'Empathique', 'Loyal', 'Coopératif', 'Protecteur'];
  celestia02.defaults = ['Anxieux', 'Crédule', 'Influençable', 'Peureux'];
  celestia02.comment = 'Je ne veux pas que ça recommence. Cette fois je saurais les protéger.';
  celestiaCharacters.push(celestia02);

  const celestia03 = new CelestiaCharacter('Romain', 'Divernn', 23);
  celestia03.astro = 'Sagittaire';
  celestia03.mbti = 'ISFJ';
  celestia03.orientation = 'Bisexuel';
  celestia03.situation = 'En couple discret avec Elisa';
  celestia03.relations = [`Il ne fréquente personne d'autre qu'Elisa`, `Il apprécie Alice et ne la supporte pas en même temps`];
  celestia03.emotion = 'Colère';
  celestia03.grade = 'Mage de Glace';
  celestia03.image = 'assets/images_filled/celestia/romain_ld.webp';
  celestia03.qualities = ['Audacieux', 'Déterminé', 'Protecteur', 'Indépendant'];
  celestia03.defaults = ['Aigri', 'Colérique', 'Froid', 'Impulsif', 'Bagarreur'];
  celestia03.comment = `Certaines vies sont précieuses et doivent d'être vengées.`;
  celestiaCharacters.push(celestia03);

  const celestia04 = new CelestiaCharacter('Alice', 'Pierce', 24);
  celestia04.astro = 'Scorpion';
  celestia04.mbti = 'ESTJ';
  celestia04.orientation = 'Homosexuelle';
  celestia04.situation = 'Célibataire';
  celestia04.relations = [
    `Elle méprise Jérémy et toute personne qui veut l'approcher`,
    `Elle a un ptit faible pour Sheila, même si elle ne veut pas se l'avouer`,
  ];
  celestia04.emotion = 'Dégoût';
  celestia04.grade = 'Elite de Magma';
  celestia04.image = 'assets/images_filled/celestia/alice_ld.webp';
  celestia04.qualities = ['Ambitieuse', 'Rusée', `Sûre d'elle`, 'Franche'];
  celestia04.defaults = ['Arrogante', 'Hautaine', 'Individualiste', 'Envieuse'];
  celestia04.comment = `Soit t'as le niveau, soit t'es une merde. Snif snif... ça pue ici.`;
  celestiaCharacters.push(celestia04);

  const celestia05 = new CelestiaCharacter('Delphyn', '', 22);
  celestia05.astro = 'Gémeaux';
  celestia05.mbti = 'INFP';
  celestia05.orientation = 'Bisexuelle';
  celestia05.situation = 'Célibataire';
  celestia05.relations = [
    `Diana est sa soeur jumelle`,
    `Elisa est sa meilleure amie`,
    `Elle apprécie tout le monde, y compris Alice et Romain qui lui font un peu peur`,
  ];
  celestia05.emotion = 'Tristesse';
  celestia05.grade = 'Mage de Vie';
  celestia05.image = 'assets/images_filled/celestia/delphyn_ld.webp';
  celestia05.qualities = ['Affectueuse', 'Attentionnée', 'Brillante', 'Timide'];
  celestia05.defaults = ['Étourdie', 'Naïve', 'Maladroite'];
  celestia05.comment = `Quelqu'un a besoin d'aide ?`;
  celestiaCharacters.push(celestia05);

  const celestia06 = new CelestiaCharacter('Diana', '', 22);
  celestia06.astro = 'Gémeaux';
  celestia06.mbti = 'ISTP';
  celestia06.orientation = 'Asexuelle';
  celestia06.situation = 'Célibataire';
  celestia06.relations = [`Delphyn est sa soeur jumelle`, `Elle évite tout contact avec des personnes autres que sa soeur`];
  celestia06.emotion = 'Tristesse';
  celestia06.grade = 'Mage de Vie';
  celestia06.image = 'assets/images_filled/celestia/diana_ld.webp';
  celestia06.qualities = ['Juste', 'Mature', 'Patiente', 'Réfléchie'];
  celestia06.defaults = ['Asociale', 'Silencieuse', 'Paresseuse'];
  celestia06.comment = 'Passé, Présent, Futur... Tu ne devais pas exister.';
  celestiaCharacters.push(celestia06);

  return celestiaCharacters;
}
