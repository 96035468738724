<footer>
  <!-------------------------- Large version ---------------------------->

  <ng-container *ngIf="isMaxFooter">
    <div class="footer-large-infos">
      <div class="footer-large-left">
        <div class="footer-large-container">
          <img src="assets/images_alpha/logo_md.png" alt="Logo d'Arlénor" />
        </div>
        <div class="footer-large-container">
          <p class="u-marginBottom1">
            Le Monde d'Arlénor est un univers fantastique, créé en 2015 par Nicholas (aka Spefire) pour en faire un Jeu
            de Rôles.
          </p>
          <arrow-button linkName="En savoir plus" linkPage="/a-propos"></arrow-button>
        </div>
      </div>
      <div class="footer-large-right">
        <footer-link
          [withBorder]="true"
          link="https://www.linkedin.com/in/nicholas-brun-spefire/"
          iconClass="icon-linkedin"
        ></footer-link>
        <footer-link
          [withBorder]="true"
          link="https://www.instagram.com/mondedarlenor"
          iconClass="icon-instagram"
        ></footer-link>
        <footer-link [withBorder]="true" link="https://discord.gg/WWkewPveYs" iconClass="icon-discord"></footer-link>
      </div>
    </div>

    <div class="footer-large-navigation">
      <nav>
        <a class="link" routerLink="/mentions-legales">{{ pages.legacy }}</a>
        <a class="link" routerLink="/a-propos">{{ pages.about }}</a>
      </nav>
    </div>
  </ng-container>

  <!-------------------------- Small version ---------------------------->

  <ng-container *ngIf="!isMaxFooter">
    <div class="footer-small" [class.is-home]="onHome">
      <div class="footer-small-section-mobile">
        <footer-link link="https://www.linkedin.com/in/nicholas-brun-spefire/" iconClass="icon-linkedin"></footer-link>
        <footer-link link="https://www.instagram.com/mondedarlenor" iconClass="icon-instagram"></footer-link>
        <footer-link link="https://discord.gg/WWkewPveYs" iconClass="icon-discord"></footer-link>
      </div>
      <nav>
        <a
          class="link"
          routerLink="/mentions-legales"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ pages.legacy }}
        </a>
        <a
          class="link"
          routerLink="/a-propos"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ pages.about }}
        </a>
      </nav>
      <div class="footer-small-section">
        <footer-link
          [isSmall]="true"
          link="https://www.linkedin.com/in/nicholas-brun-spefire/"
          iconClass="icon-linkedin"
        ></footer-link>
        <footer-link
          [isSmall]="true"
          link="https://www.instagram.com/mondedarlenor"
          iconClass="icon-instagram"
        ></footer-link>
        <footer-link [isSmall]="true" link="https://discord.gg/WWkewPveYs" iconClass="icon-discord"></footer-link>
      </div>
    </div>
  </ng-container>
</footer>
