import { Component, Input, OnInit } from '@angular/core';

import { journalLilith01 } from '@src/views/roleplay/system/journaux/journaux-lilith';
import { journalMichael01 } from '@src/views/roleplay/system/journaux/journaux-michael';

import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { StoreService } from '../../../../services/store.service';

import { journalAsmodee01, journalAsmodee02, journalAsmodee03, journalAsmodee04 } from './journaux-asmodee';
import { journalGabriel01, journalGabriel02, journalGabriel03, journalGabriel04 } from './journaux-gabriel';
import { journalSatan01 } from './journaux-satan';

@Component({
  selector: 'system-journaux-view',
  templateUrl: './system-journaux.view.html',
  styleUrls: ['./system-journaux.view.scss'],
})
export class SystemJournauxView implements OnInit {
  @Input() public team: string;

  public teamSatan = [`Aly'nn`, `Ethan`, `Malzarius`, `Ramage`];
  public journalSatan01 = journalSatan01;

  public teamAsmodee = [`Flora Astor`, `Marcelino`, `Sélène`, `Wyll Owl`];
  public journalAsmodee01 = journalAsmodee01;
  public journalAsmodee02 = journalAsmodee02;
  public journalAsmodee03 = journalAsmodee03;
  public journalAsmodee04 = journalAsmodee04;

  public teamGabriel = [`Decim`, `Demetrius Chronopoulos`, `Dynn Erjack`, `Kasyade`];
  public journalGabriel01 = journalGabriel01;
  public journalGabriel02 = journalGabriel02;
  public journalGabriel03 = journalGabriel03;
  public journalGabriel04 = journalGabriel04;

  public teamMichael = [`Elyndra Luneclaire`, `Nishi`, `Opale`, `Miriel`];
  public journalMichael01 = journalMichael01;

  public teamLilith = [`Mehr Grant`, `Flora Estrel`, `Vhaleria`, `Zahal`];
  public journalLilith01 = journalLilith01;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  public get isPlayer(): boolean {
    return this._storeService.isPlayer;
  }

  public get characters(): ArlenorCharacter[] {
    const allCharacters = this._storeService.$allCharacters.value || [];
    const localCharacters = this._storeService.localCharacters || [];
    return allCharacters.concat(localCharacters);
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this._storeService.loadAllCharacters();
    this._storeService.loadLocalCharacters();
  }

  public getDetails(character: ArlenorCharacter) {
    let libelle = character.race?.name;
    if (character.speciality01) libelle += ' - ' + character.speciality01.name;
    if (character.speciality02) libelle += '/' + character.speciality02.name;
    return libelle;
  }

  public getCharacters(team: string[]) {
    return this.characters.filter(character => team.includes(character.name));
  }
}
