<h3>Description de l'équipement</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <img class="element-modal-img" [src]="stuff.image" [alt]="stuff.name" />
  </div>
  <h2>{{ stuff.name }}</h2>
  <p>{{ stuff.description ? stuff.description : 'Aucune description disponible' }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
