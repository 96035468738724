<div class="footer-section">
  <button type="button" mat-stroked-button (click)="goTop()">
    <i class="icon-arrow-up"></i>
    <span>Retour en haut</span>
    <i class="icon-arrow-up"></i>
  </button>

  <button type="button" mat-stroked-button color="primary" class="u-width100" (click)="change(previousSection.code)">
    <i class="icon-arrow-left"></i>
    <span>Vers "{{ previousSection.title }}"</span>
  </button>

  <button type="button" mat-stroked-button color="primary" class="u-width100" (click)="change(nextSection.code)">
    <span>Vers "{{ nextSection.title }}"</span>
    <i class="icon-arrow-right"></i>
  </button>
</div>
