import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-difficulties-view',
  templateUrl: './system-difficulties.view.html',
  styleUrls: [],
})
export class SystemDifficultiesView {
  public systemDifficultiesText0A = systemDifficultiesText0A;
  public systemDifficultiesText0B = systemDifficultiesText0B;
  public systemDifficultiesText0C = systemDifficultiesText0C;
  public systemDifficultiesText01 = systemDifficultiesText01;
  public systemDifficultiesText02 = systemDifficultiesText02;
  public exampleChoice = 0;

  public changeExampleChoice(choice: number) {
    if (choice === this.exampleChoice) this.exampleChoice = 0;
    else this.exampleChoice = choice;
  }
}

export const systemDifficultiesText0A = convertMarkdownToHTML(`
  Le **système de jeu** est une partie importante du jeu de rôles, puisque c'est grâce à lui que vous savez si votre personnage,
  au cours du jeu, réussit ou non les actions qu'il entreprend.

  Le **Monde d'Arlénor** se joue avec des sets de **dès à 6 faces** seulement : idéalement 5 dés par personne.`);

export const systemDifficultiesText0B = convertMarkdownToHTML(`
  Le **test classique** qui détermine si un personnage réussit ou non une action se réalise de la façon suivante :
  le Maitre du Jeu **détermine quelle caractéristique de base du personnage est concernée**
  (Force, Habileté, Intellect, Ténacité, Charisme, Magie)
  ensuite le joueur prépare autant de dés à 6 faces qu'il a de points dans la caractéristique,
  en ajoutant / enlèvant autant de dés bonus / malus qu'il pourrait avoir à côté
  (dûs aux compétences, aux équipements ou aux effets appliqués sur le personnage).
  
  Le MJ annonce aussi la difficulté rencontrée (Simple, Standard, Complexe, Légendaire).
  Cela déterminera le nombre de dés gagnants que le joueur doit réaliser pour espérer la réussite de son action.

  Note : Un **dé gagnant**, c'est quand un dé affiche un 4, un 5 ou un 6.
  Note : Un **dé perdant**, c'est quand un dé affiche un 1, un 2 ou un 3.`);

export const systemDifficultiesText0C = convertMarkdownToHTML(`
  **Difficulté simple : 1 dé gagnant**.
  N'importe qui peut normalement y arriver en un temps réduit.
  *Exemple : Lancer un fruit pourri sur une cible immobile à 5m.*

  **Difficulté standard : 2 dés gagnants**.
  La majorité des personnages peut le faire rapidement, et un spécialiste n'éprouve aucune difficulté.
  *Exemple : Obtenir des informations sur une légende urbaine bien connue.*

  **Difficulté complexe : 3 dés gagnants**.
  Quasi impossible sans formation à moins de circonstances favorables ou d'un don particulièrement marqué,
  ces actions n'ont de bonne chance d'être réussies que par les spécialistes.
  *Exemple : Trouver de quoi se nourrir en hiver dans un bois profond.*

  **Difficulté épique : 4 dés gagnants**.
  Complexe même pour les spécialistes, ces actions sont impossibles pour des personnes non formées.
  Seuls les meilleurs experts s'en tirent à bon compte. 
  *Exemple : Traverser un océan avec un simple voilier.*

  **Difficulté légendaire : 5 dés gagnants**.
  Impossible d'arriver à ce stade sans avoir des aptitudes quasi surnaturelles.
  *Exemple : Au cours d'une réception, voler la couronne de la Reine, qu'elle porte sur la tête,
  au vu de tous et sans se faire remarquer.*`);

export const systemDifficultiesText01 = convertMarkdownToHTML(`
  **Hélios** cherche dans une bibliothèque une information sur une plante inconnue.
  
  Si le livre peut donner l'information, le MJ demandera un lancé en **Intellect** pour une difficulté
  **Simple**. Hélios a **3 en Intellect**, possède une **compétence "Rat de bibliothèque"**
  qui lui donne **1 dé bonus** dans la réalisation de cette action.
  En dehors de ça, Hélios ne possède pas d'effet magique, ni d'équipement qui lui donnerait un autre avantage
  ou désavantage pour cette action.
  
  Il lance alors **(3+1)D6 soit 4D6** et fait... **3,4,5,2 soit 2 dés gagnants**.
  **Hélios réussit !**
  Comme son score maximum est à 5, sa réussite est une réussite simple : pas de complication.
  Hélios trouve alors que la plante inconnue est un Hibicus moscheutos.`);

export const systemDifficultiesText02 = convertMarkdownToHTML(`
  **Ophéliz** cherche à trancher les chaînes d'un prisonnier avec son épée.
  
  C'est une épée longue (donc lourde), le MJ demandera un lancé de **Force**
  et détermine la difficulté à **Complexe**.
  Ophéliz possède une **compétence "Armes à une main (épées)"** qui lui donne **1 dé bonus**
  dans la réalisation de cette action.
  Elle possède en plus un **effet magique** sur son arme (qui donne **1 dé bonus** sans condition).
  Et malheureusement pour Ophéliz, dans la prison, il fait nuit et il n'y a pas d'éclairage :
  le MJ décide alors qu'elle a **1 dé malus** pour cette action.
  
  Ophéliz a **2 en Force**, elle alors lance **(2+1+1-1)D6 soit 3D6**
  et fait... **1,4,3** soit 1 dé gagnant.
  **Ophéliz échoue...**
  Comme son score maximum est à 4, son échec est un échec à conséquence.
  Le MJ décide que dans sa tentative, Ophéliz blesse légèrement le prisonnier.`);
