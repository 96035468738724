<h3>Description de la divinité</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <button type="button" mat-icon-button (click)="previous()">
      <i class="icon-chevron-left"></i>
    </button>
    <img class="element-modal-img" [src]="divinity.image" [alt]="divinity.name" />
    <button type="button" mat-icon-button (click)="next()">
      <i class="icon-chevron-right"></i>
    </button>
  </div>
  <h2>{{ divinity.name }}</h2>
  <h3>{{ divinity.title }}</h3>
  <p class="u-textItalic">{{ divinity.comment }} - {{ divinity.commentName }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
