import { Component, OnInit } from '@angular/core';

import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this._storeService.getBackoffice();
  }
}
