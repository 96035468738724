<character-template [number]="0" label="Choix du niveau" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <div class="card">
      Choisissez le niveau qui vous intéresse pour votre personnage.
      <br />Notez qu'au début de la campagne de JDR / l'Aventure, les personnages commencent au niveau 1, mais ils
      gagneront ensuite un niveau par scénario joué.
    </div>

    <mat-form-field>
      <mat-label>Niveau</mat-label>
      <mat-select formControlName="numLevel">
        <mat-option *ngFor="let numero of numeros" [value]="numero">
          {{ numero }} {{ numero === 1 ? '(sauvegardable)' : '' }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="form.controls.numLevel.value > 1" class="card is-warning">
      Les personnages de niveau supérieur à 1 ne pourront pas être enregistrés localement.
    </div>
  </form>
</character-template>

<div class="u-flexRow u-justifyCenter u-marginTop1">
  <button type="button" mat-stroked-button color="primary" (click)="confirm()">Confirmer le niveau sélectionné</button>
</div>
