<div class="encyclo-jirakan-land-view u-marginTop1">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="La Cité de Jirakan">
      <img class="encyclopedia-img" [src]="citeJirakanImage" alt="La Cité de Jirakan" />
      <div class="card" [innerHTML]="citeJirakanResume"></div>
      <div class="card u-marginTop1" [innerHTML]="citeJirakanDetails"></div>
    </mat-tab>
    <mat-tab label="Le Commerce de Jirakan">
      <img class="encyclopedia-img" [src]="commerceJirakanImage" alt="Le Commerce de Jirakan" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="Le Désert de Sanderten">
      <img class="encyclopedia-img" [src]="desertSandertenImage" alt="Le Désert de Sanderten" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="Les Mines de Jirakan">
      <img class="encyclopedia-img" [src]="minesJirakanImage" alt="Les Mines de Jirakan" />
      <div class="card is-info">Contenu à venir...</div>
    </mat-tab>
    <mat-tab label="La Grande Révolte">
      <img class="encyclopedia-img" [src]="grandeRevolteImage" alt="La Grande Révolte" />
      <div class="card" [innerHTML]="grandeRevolteResume"></div>
    </mat-tab>
  </mat-tab-group>
</div>
