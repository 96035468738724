<h3>Description de la compétence</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <img class="element-modal-img" [src]="skill.image" [alt]="skill.name" />
  </div>
  <h2>{{ skill.name }}</h2>
  <h3>{{ skill.type.Libelle }}</h3>
  <p class="u-textItalic">
    <i class="icon-dice" title="Caractéristiques concernées"></i>&nbsp;
    <span title="Caractéristiques concernées">{{ skill.nameCaracts }}</span>
  </p>
  <p>{{ skill.description ? skill.description : 'Aucune description disponible' }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
