<character-template [number]="6" label="Choix de l'identité" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <div>
      <label for="avatar" class="character-identity-avatar u-marginTop05">
        <span *ngIf="!form.controls.avatar.value">
          Avatar du personnage<br /><br />Importer une image<br />(ratio 1:1 | JPG, PNG | max 1 Mo)
        </span>
        <img
          *ngIf="form.controls.avatar.value"
          alt="Avatar du personnage"
          [src]="getProfil(form.controls.avatar.value)"
        />
      </label>
      <input type="file" name="avatar" id="avatar" accept="image/png, image/jpeg" (change)="changeAvatar($event)" />
    </div>

    <mat-form-field>
      <mat-label>Nom du personnage</mat-label>
      <input type="text" matInput maxlength="25" formControlName="name" />
    </mat-form-field>

    <div class="card is-warning">
      Dans la campagne de JDR / l'Aventure, les personnages doivent commencer avec un âge compris entre 20 et 30 ans
      (pour le bien être scénaristique).
    </div>

    <div class="u-flexRow u-flexColumn-on-mobile">
      <mat-form-field>
        <mat-label>Âge du personnage</mat-label>
        <input type="number" matInput min="1" max="200" formControlName="age" placeholder="1-200" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Genre du personnage</mat-label>
        <mat-select formControlName="gender">
          <mat-option [value]="null"></mat-option>
          <mat-option value="Féminin">Féminin</mat-option>
          <mat-option value="Masculin">Masculin</mat-option>
          <mat-option value="Non-genré">Non-genré</mat-option>
          <mat-option value="Autre">Autre</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</character-template>
