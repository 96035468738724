import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ArlenorDivinity } from '../../../models/arlenor/ArlenorDivinity';
import { getListAngels, getListDemons } from '../../../models/data/ListDivinities';

@Component({
  selector: 'divinities-table',
  templateUrl: './divinities-table.component.html',
  styleUrls: [],
})
export class DivinitiesTableComponent implements OnInit {
  public angels: ArlenorDivinity[];
  public demons: ArlenorDivinity[];

  public displayedColumns: string[] = ['name', 'team', 'situation'];
  public dataSources: MatTableDataSource<ArlenorDivinity>[];

  constructor(public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.angels = getListAngels();
    this.demons = getListDemons();
    this.dataSources = [new MatTableDataSource<ArlenorDivinity>(this.angels), new MatTableDataSource<ArlenorDivinity>(this.demons)];
  }
}
