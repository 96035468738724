<h3>Choix de votre rôle</h3>
<mat-dialog-content class="mat-typography">
  <div class="u-marginBottom1 u-textCenter">
    Votre rôle actuel est :
    <b>{{ currentUser.libelle }}</b>
  </div>
  <form [formGroup]="form" class="u-width100 u-textCenter">
    <mat-form-field>
      <mat-label>Nouveau rôle</mat-label>
      <mat-select formControlName="code">
        <mat-option *ngFor="let user of users" [value]="user.code" [disabled]="currentUser.code === user.code">
          {{ user.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    @if (isAdmin) {
      <mat-form-field class="u-marginTop1">
        <mat-label>Clé (si besoin)</mat-label>
        <input type="password" matInput formControlName="key" />
      </mat-form-field>
    }

    <div class="card is-info u-marginTop1">
      {{ currentRole.description }}
    </div>

    <div class="u-marginTop1">
      <button
        type="button"
        mat-stroked-button
        [disabled]="form.invalid || (isAdmin && !form.controls.key.value)"
        (click)="submitForm()"
      >
        Changer de rôle
      </button>
      <div *ngIf="isInvalid" class="u-textRed u-marginTop05">Clé inconnue</div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
