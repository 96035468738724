import { Component, OnInit } from '@angular/core';

import { ArlenorRole } from '@src/models/arlenor/ArlenorRole';
import { ArlenorSpeciality } from '@src/models/arlenor/ArlenorSpeciality';
import { getListRoles } from '@src/models/data/ListRoles';
import { ArlenorSpecialities } from '@src/models/data/ListSpecialities';
import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-crystals-view',
  templateUrl: './system-crystals.view.html',
  styleUrls: [],
})
export class SystemCrystalsView implements OnInit {
  public systemCrystalsText01 = systemCrystalsText01;
  public systemCrystalsText02 = systemCrystalsText02;
  public allRoles: ArlenorRole[];
  public allSpecialities: ArlenorSpeciality[];
  public roleChoice = '';

  public ngOnInit(): void {
    this.allRoles = getListRoles();
    this.allSpecialities = ArlenorSpecialities.getListSpecialities().sort((a, b) => a.name.localeCompare(b.name));
  }

  public getSpecialities(roleCode: string) {
    return this.allSpecialities.filter(spe => spe.role.code === roleCode);
  }

  public changeRoleChoice(choice: string) {
    if (choice === this.roleChoice) this.roleChoice = '';
    else this.roleChoice = choice;
  }
}

export const systemCrystalsText01 = convertMarkdownToHTML(`
  Dans le Monde d'Arlénor, certaines personnes ont des capacités magiques,
  et utilisent un **cristal évolutif** dans leur vie quotidienne.

  En étant à leur contact, ces cristaux permettent d'utiliser certains pouvoirs en échange d'un peu d'énergie vitale.
  **Ces cristaux évoluent avec leur détenteur**, et les cristologues ont pu déterminer plusieurs branches (classes)
  de pouvoirs dans lesquelles chaque personne peut progresser.
  Le nombre de cristaux évolutif qu'un détenteur peut avoir est de l'ordre de 1,
  et le nombre de classes qu'un détenteur peut avoir accès est de l'ordre de 2.

  Les classes suivantes sont regroupées par rôles, puis par classe.`);

export const systemCrystalsText02 = convertMarkdownToHTML(`
  Chaque pouvoir est défini par plusieurs propriétés...

  Un rang...
  - **I** : le pouvoir a besoin d'une réussite "Simple" pour avoir un effet.
  - **II** : le pouvoir a besoin d'une réussite "Standard" pour avoir un effet.
  - **III** : le pouvoir a besoin d'une réussite "Complexe" pour avoir un effet.

  Un type...
  - **Offensif** : sorts qui infligent des dégâts directs aux ennemis ou qui ont un effet néfaste sur leur santé.
  - **Défensif** : sorts qui protègent ou renforcent le lanceur de sorts ou ses alliés.
  - **Soin** : sorts qui soignent le lanceur de sorts ou ses alliés, ou qui annulent les effets négatifs des sorts ennemis.
  - **Contrôle** : sorts qui modifient ou altèrent les caractéristiques physiques, mentales ou magiques du lanceur de sorts ou de ses cibles.
  - **Utilitaire** : sorts qui ont des effets non-combatifs, tels que la téléportation, la création d'objets ou de nourriture,
  ou la divination...

  Une catégorie...
  - **Sort** : pouvoir qui se lance et fait son effet.
  - **Spécial** : capacité débloquée via la magie (passif, coup spécial, réaction) pouvant utiliser une autre caractérique.
  - **Canalisation** : pouvoir qui maintient l'attention du lanceur pour faire effet, l'empêchant de réaliser d'autres actions.

  Une portée maximum...
  - **Personnelle** : soi-même.
  - **Toucher** : une ou plusieurs personnes au contact / corps-à-corps.
  - **Vision** : une ou plusieurs personnes dans le champ de vision.
  - **Zone** : une ou plusieurs personnes dans une zone "à portée de tir".
  - **Infinie** : une ou plusieurs personnes dans le monde.

  Une durée maximum...
  - **Instantanée** : durée d'un tour en combat.
  - **Scène** : durée d'une scène scénaristique / d'un combat.
  - **Journée** : durée d'une journée.
  - **Illimitée** : pas de limite de temps.

  **Note : Le temps de rechargement est égal à la durée du sort**
  (un tour d'attaque = un tour de recharge).`);
