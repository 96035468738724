import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { getListFactions } from '@src/models/data/ListFactions';

import { ArlenorFaction } from '../../../models/arlenor/ArlenorFaction';

@Component({
  selector: 'faction-modal',
  templateUrl: './faction.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class FactionModal implements OnInit {
  public currentIndex: number;
  public allFactions: ArlenorFaction[];

  public get faction(): ArlenorFaction {
    return this.allFactions[this.currentIndex];
  }

  constructor(
    public dialogRef: MatDialogRef<FactionModal>,
    @Inject(MAT_DIALOG_DATA) public data: ArlenorFaction
  ) {}

  public ngOnInit(): void {
    this.allFactions = getListFactions();
    this.allFactions.sort((a, b) => {
      if (a.isOff === b.isOff) return a.name.localeCompare(b.name);
      else if (a.isOff) return -1;
      else return 1;
    });
    this.currentIndex = this.data ? this.allFactions.findIndex(faction => faction.code === this.data.code) : 0;
  }

  public previous() {
    if (this.currentIndex === 0) this.currentIndex = this.allFactions.length - 1;
    else this.currentIndex--;
  }

  public next() {
    if (this.currentIndex === this.allFactions.length - 1) this.currentIndex = 0;
    else this.currentIndex++;
  }
}
