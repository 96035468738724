import { Component, OnInit } from '@angular/core';

import { ArlenorStuff } from '@src/models/arlenor/ArlenorStuff';
import { getListStuffs } from '@src/models/data/ListStuffs';

@Component({
  selector: 'system-stuffs-view',
  templateUrl: './system-stuffs.view.html',
  styleUrls: [],
})
export class SystemStuffsView implements OnInit {
  public allStuffs: ArlenorStuff[];

  public ngOnInit() {
    this.allStuffs = getListStuffs();
  }
}
