<character-template [number]="5" label="Choix divins" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <img
      class="character-divinity-img"
      src="assets/images_alpha/religion/divinities.png"
      alt="Illustration des symboles divins"
    />

    <div class="card is-warning">
      Si vous n'avez pas commencé la campagne de JDR / l'Aventure, alors passez cette section !
    </div>

    <div class="u-flexRow u-alignCenter">
      <mat-form-field>
        <mat-label>Divinité</mat-label>
        <mat-select formControlName="codeDivinity">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let divinity of allDivinities" [value]="divinity.code">
            {{ divinity.libNum }}{{ divinity.isInversed ? 'i' : '' }} - {{ divinity.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-icon-button color="primary" (click)="openDivinityPopup()">?</button>
    </div>

    <div class="u-flexRow u-flexColumn-on-tablet">
      <mat-form-field>
        <mat-label>Palier divin</mat-label>
        <mat-select formControlName="divinityPoints">
          <mat-option *ngFor="let point of points" [value]="point" [disabled]="!currentDivinity">
            {{ getPalier(point) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Morts subies</mat-label>
        <mat-select formControlName="deathPoints">
          <mat-option *ngFor="let point of points" [value]="point" [disabled]="!currentDivinity">
            {{ point }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</character-template>
