import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { PageSubtitles, PageTitles } from '@src/models/PagesTitles';
import { TreeNode } from '@src/models/TreeNode';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'system-view',
  templateUrl: './system.view.html',
  styleUrls: ['./system.view.scss'],
})
export class SystemView implements OnInit {
  public imagePerso = 'assets/images_filled/system/section-perso_ld.webp';
  public imageRules = 'assets/images_filled/system/section-rules_ld.webp';
  public imageJournaux = 'assets/images_filled/system/section-journaux_ld.webp';

  public title = PageTitles.system;
  public currentCode: string;
  public currentSection: TreeNode;
  public previousSection: TreeNode;
  public nextSection: TreeNode;
  public systemMenu: TreeNode[];

  private _destroyRef = inject(DestroyRef);

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  public get isPlayer(): boolean {
    return this._storeService.isPlayer;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this.systemMenu = [];
    this.systemMenu.push({
      title: PageSubtitles.systemPerso,
      children: [
        { title: PageSubtitles.systemCaracts, code: 'SystemCaractsView', subtitle: PageSubtitles.systemPerso, icon: 'icon-user', image: this.imagePerso },
        { title: PageSubtitles.systemRaces, code: 'SystemRacesView', subtitle: PageSubtitles.systemPerso, icon: 'icon-user', image: this.imagePerso },
        { title: PageSubtitles.systemSkills, code: 'SystemSkillsView', subtitle: PageSubtitles.systemPerso, icon: 'icon-user', image: this.imagePerso },
        { title: PageSubtitles.systemStuffs, code: 'SystemStuffsView', subtitle: PageSubtitles.systemPerso, icon: 'icon-user', image: this.imagePerso },
        { title: PageSubtitles.systemCrystals, code: 'SystemCrystalsView', subtitle: PageSubtitles.systemPerso, icon: 'icon-user', image: this.imagePerso },
        { title: PageSubtitles.systemStory, code: 'SystemStoryView', subtitle: PageSubtitles.systemPerso, icon: 'icon-user', image: this.imagePerso },
      ],
    });
    this.systemMenu.push({
      title: PageSubtitles.systemRules,
      children: [
        {
          title: PageSubtitles.systemDifficulties,
          code: 'SystemDifficultiesView',
          subtitle: PageSubtitles.systemRules,
          icon: 'icon-dice',
          image: this.imageRules,
        },
        { title: PageSubtitles.systemResults, code: 'SystemResultsView', subtitle: PageSubtitles.systemRules, icon: 'icon-dice', image: this.imageRules },
        { title: PageSubtitles.systemFight, code: 'SystemFightView', subtitle: PageSubtitles.systemRules, icon: 'icon-dice', image: this.imageRules },
        {
          title: PageSubtitles.systemAlterations,
          code: 'SystemAlterationsView',
          subtitle: PageSubtitles.systemRules,
          icon: 'icon-dice',
          image: this.imageRules,
        },
        { title: PageSubtitles.systemExperience, code: 'SystemExperienceView', subtitle: PageSubtitles.systemRules, icon: 'icon-dice', image: this.imageRules },
      ],
    });
    // Ajout d'autres sections selon les rôles
    if (this.isAdmin || this.isMaster || this.isPlayer) {
      this.systemMenu[1].children.push({
        title: PageSubtitles.systemDivine,
        code: 'SystemDivineView',
        subtitle: PageSubtitles.systemRules,
        icon: 'icon-dice',
        image: this.imageRules,
      });
      this.systemMenu.push({
        title: PageSubtitles.systemJournaux,
        children: [
          {
            title: PageSubtitles.systemTeamSatan,
            code: 'SystemJournauxSatanView',
            subtitle: PageSubtitles.systemJournaux,
            icon: 'icon-book',
            image: this.imageJournaux,
          },
          {
            title: PageSubtitles.systemTeamGabriel,
            code: 'SystemJournauxGabrielView',
            subtitle: PageSubtitles.systemJournaux,
            icon: 'icon-book',
            image: this.imageJournaux,
          },
          {
            title: PageSubtitles.systemTeamAsmodee,
            code: 'SystemJournauxAsmodeeView',
            subtitle: PageSubtitles.systemJournaux,
            icon: 'icon-book',
            image: this.imageJournaux,
          },
          {
            title: PageSubtitles.systemTeamMichael,
            code: 'SystemJournauxMichaelView',
            subtitle: PageSubtitles.systemJournaux,
            icon: 'icon-book',
            image: this.imageJournaux,
          },
          {
            title: PageSubtitles.systemTeamLilith,
            code: 'SystemJournauxLilithView',
            subtitle: PageSubtitles.systemJournaux,
            icon: 'icon-book',
            image: this.imageJournaux,
          },
        ],
      });
    }

    // Ensuite on vérifie la route
    this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(params => {
      this.currentCode = params.section;
      if (!this.currentCode) this.moveToSection('SystemCaractsView');
      else {
        this.currentSection = this._getSection(this.systemMenu);
        if (!this.currentSection) this.moveToSection('SystemCaractsView');
        else this._setPreviousNext();
      }
    });
  }

  public moveToSection(code: string) {
    this._router.navigate([], {
      queryParams: { section: code },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.currentCode = code;
    this.currentSection = this._getSection(this.systemMenu);
    this._setPreviousNext();
  }

  private _setPreviousNext() {
    const sections = this._getListSections(this.systemMenu, []);
    const indexCurrent = sections.findIndex(section => section.code === this.currentCode);
    const indexPrevious = indexCurrent > 0 ? indexCurrent - 1 : sections.length - 1;
    const indexNext = indexCurrent < sections.length - 1 ? indexCurrent + 1 : 0;
    this.previousSection = sections[indexPrevious];
    this.nextSection = sections[indexNext];
  }

  private _getListSections(nodes: TreeNode[], sections: TreeNode[]): TreeNode[] {
    nodes.forEach(node => {
      if (node.code) sections.push(node);
      else if (node.children?.length > 0) sections = this._getListSections(node.children, sections);
    });
    return sections;
  }

  private _getSection(nodes: TreeNode[]): TreeNode {
    let section = null;
    nodes.forEach(node => {
      if (node.code === this.currentCode) section = node;
      else if (node.children?.length > 0) {
        const tempSection = this._getSection(node.children);
        if (tempSection) section = tempSection;
      }
    });
    return section;
  }
}
