import { ArlenorZone } from '../arlenor/ArlenorZone';

import { ArlenorSectors } from './ListSectors';

export function getListZones(): ArlenorZone[] {
  const arlenorZones: ArlenorZone[] = [];
  const sectors = new ArlenorSectors();

  // ///////////////////////////////////////////////////////////////////////////////////////
  // PlainesTerfil
  // ///////////////////////////////////////////////////////////////////////////////////////

  const faradel = new ArlenorZone(
    `L'Empire Faradélien`,
    40.8,
    69.85,
    sectors.PlainesTerfil,
    'Dans notre monde, il y a ceux qui savent utiliser la magie, et ceux qui ne peuvent pas. Il y a les êtres civilisés, et il y a les bêtes...',
    'Humain faradélien, mettant son habit de garde'
  );
  faradel.image = 'assets/images_filled/lands/section-faradel_md.png';
  faradel.linkPage = '/univers/encyclopedie';
  faradel.linkParams = 'EncycloFaradelLandView';
  arlenorZones.push(faradel);

  const jirakan = new ArlenorZone(
    'Le Régime Jirakanien',
    60,
    74,
    sectors.DesertSanderten,
    `Ici c'est la règle des trois M qui domine : Miner, Miner et Miner. Il n'existe rien d'autre. On n'existe pas.`,
    'Adolescent mutant partant aux mines'
  );
  jirakan.image = 'assets/images_filled/lands/section-jirakan_md.png';
  jirakan.linkPage = '/univers/encyclopedie';
  jirakan.linkParams = 'EncycloJirakanLandView';
  arlenorZones.push(jirakan);

  const dakaros = new ArlenorZone(`Le Royaume de Dakaros`, 70, 85, sectors.MontagnesKazador, '', '');
  dakaros.image = 'assets/images_filled/lands/section-dakaros_md.png';
  dakaros.linkPage = '/univers/encyclopedie';
  dakaros.linkParams = 'EncycloDakarosLandView';
  arlenorZones.push(dakaros);

  const ether = new ArlenorZone(`Le Bosquet d'Ether`, 28, 80, sectors.ForetMiryden, '', '');
  ether.image = 'assets/images_filled/lands/section-ether_md.png';
  ether.linkPage = '/univers/encyclopedie';
  ether.linkParams = 'EncycloEtherLandView';
  arlenorZones.push(ether);

  const atlantica = new ArlenorZone(`Les Abysses d'Atlantica`, 56, 48, sectors.MerShivazen, '', '');
  atlantica.image = 'assets/images_filled/lands/section-atlantica_md.png';
  atlantica.linkPage = '/univers/encyclopedie';
  atlantica.linkParams = 'EncycloAtlanticaLandView';
  arlenorZones.push(atlantica);

  const somni = new ArlenorZone(`Les Terres Oubliées de Somni`, 8.75, 65.5, sectors.TerresOubliees, '', '');
  somni.image = 'assets/images_filled/lands/section-somni_md.png';
  somni.linkPage = '/univers/encyclopedie';
  somni.linkParams = 'EncycloSomniLandView';
  arlenorZones.push(somni);

  const lumeck = new ArlenorZone(
    `L'Ile de Lumeck`,
    29,
    24,
    sectors.MerShivazen,
    `Douloureux... Affreux... Puant... Effrayant... La mort la plus belle ne se trouve pas en ces lieux.`,
    `Cartographe, poète dans l'âme`
  );
  lumeck.image = 'assets/images_filled/world/ile-lumeck_md.png';
  lumeck.linkPage = '/univers/encyclopedie';
  lumeck.linkParams = 'EncycloNoLandView';
  arlenorZones.push(lumeck);

  const celestia = new ArlenorZone(`L'Île Célestia`, 5, 5, sectors.CielArlenor, '', '');
  celestia.image = 'assets/images_filled/world/celestia_md.png';
  celestia.linkPage = '/celestia';
  arlenorZones.push(celestia);

  return arlenorZones;
}
