import { Component } from '@angular/core';

@Component({
  selector: 'encyclo-no-land-view',
  templateUrl: './encyclo-no-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloNoLandView {
  public imerysImage = 'assets/images_filled/lands/section-no_md.png';

  public lumeckImage = 'assets/images_filled/world/ile-lumeck_md.png';
}
