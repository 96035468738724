<h3>Description du pouvoir</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <img class="element-modal-img" [src]="power.image" [alt]="power.name" />
  </div>
  <h2>{{ power.name }}</h2>
  <h3>{{ power.type.Libelle }}</h3>
  <p class="u-textItalic">
    <i class="icon-spinner" title="Catégorie du pouvoir"></i>&nbsp;
    <span title="Catégorie du pouvoir">{{ power.category.Libelle }}</span>
    &nbsp;•&nbsp;
    <i class="icon-enlarge" title="Portée du pouvoir"></i>&nbsp;
    <span title="Portée du pouvoir">{{ power.range.Libelle }}</span>
    &nbsp;•&nbsp;
    <i class="icon-hour-glass" title="Durée et rechargement du pouvoir"></i>&nbsp;
    <span title="Durée et rechargement du pouvoir">{{ power.duration.Libelle }}</span>
  </p>
  <p>{{ power.description ? power.description : 'Aucune description disponible' }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
