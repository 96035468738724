<character-template [number]="7" label="Choix des détails" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <mat-form-field>
      <mat-label>Description (physique)</mat-label>
      <input
        type="text"
        matInput
        maxlength="35"
        placeholder="Robuste, élégant, frêle, ridé, jeune..."
        formControlName="description"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Personnalité (caractère)</mat-label>
      <input
        type="text"
        matInput
        maxlength="35"
        placeholder="Timide, sérieux, charmeur, rêveur, généreux..."
        formControlName="traits"
      />
    </mat-form-field>

    <div class="u-flexRow u-alignCenter">
      <mat-form-field>
        <mat-label>Faction alliée (appartenance, intérêt particulier...)</mat-label>
        <mat-select formControlName="codeAlly">
          <mat-option
            *ngFor="let faction of allFactions"
            [value]="faction.code"
            [disabled]="faction.code === form.controls.codeEnemy.value"
          >
            {{ faction.name }}
            <span *ngIf="!faction.isOff">(secrète)</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-icon-button color="primary" (click)="openFactionPopup(true)">?</button>
    </div>

    <div class="u-flexRow u-alignCenter">
      <mat-form-field>
        <mat-label>Faction ennemie (rivalité, aversion...)</mat-label>
        <mat-select formControlName="codeEnemy">
          <mat-option
            *ngFor="let faction of allFactions"
            [value]="faction.code"
            [disabled]="faction.code === form.controls.codeAlly.value"
          >
            {{ faction.name }}
            <span *ngIf="!faction.isOff">(secrète)</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-icon-button color="primary" (click)="openFactionPopup(false)">?</button>
    </div>

    <mat-form-field>
      <mat-label>Histoire personnelle et faits divers</mat-label>
      <textarea
        matInput
        maxlength="740"
        placeholder="Son enfance, sa motivation dans la vie, l'acquisition de ses pouvoirs, des haut-faits réalisés..."
        formControlName="story"
      >
      </textarea>
    </mat-form-field>
  </form>
</character-template>
