import { Component } from '@angular/core';

import { CaractDescriptionEnum } from '@src/models/ModelEnum';
import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-caracts-view',
  templateUrl: './system-caracts.view.html',
  styleUrls: [],
})
export class SystemCaractsView {
  public caractDescriptionEnum = CaractDescriptionEnum;
  public systemCaractsText00 = systemCaractsText00;
  public systemCaractsText01 = systemCaractsText01;
  public systemCaractsText02 = systemCaractsText02;
  public caractChoice = 0;

  public changeCaractChoice(choice: number) {
    if (choice === this.caractChoice) this.caractChoice = 0;
    else this.caractChoice = choice;
  }
}

export const systemCaractsText00 = convertMarkdownToHTML(`
  Un **personnage** est défini par plusieurs choses : ses caractéristiques (et valeurs dérivées),
  ses compétences principales, sa race (parmi celles jouables), son cristal évolutif (ses pouvoirs), pour ensuite finir par quelques finitions.`);

export const systemCaractsText01 = convertMarkdownToHTML(`
  Toute personne est dotée de capacités de base qui lui sont inhérentes et qu'il peut utiliser de bien des manières.
  Ces aptitudes naturelles sont définies par cinq **caractéristiques** distinctes, chacune évaluée sur une échelle
  allant de 1 à 5.

  Un score de 1 dans une caractéristique indique une vraie faiblesse dans le domaine concerné, voire une incapacité,
  tandis qu'un 2 place le personnage dans la moyenne, ni plus ni moins.
  À partir de 3, la personne dispose d'une facilité particulière dans la caractéristique concernée,
  et un 4 indique des facultés exceptionnelles.
  Enfin, un score de 5 (seulement possible à partir du niveau 10) représente le maximum des capacités :
  seuls quelques très rares individus atteignent ce score.

  **À la création de votre personnage, chaque caractéristique est déjà à 1 et ne peut donc dépasser 4**.
  Vous pouvez ensuite répartir 9 points supplémentaires.`);

export const systemCaractsText02 = convertMarkdownToHTML(`
  La santé d'un personnage détermine sa résistance aux coups et aux événements qui pourraient le blesser.
  Elle est indiquée par ses **Points de Vie**, ou PV.

  - **Indemne** (PVs max) : Aucun malus.
  - **Blessé** (??? PVs) : Malus selon les blessures et les actions à réaliser.
  - **Au seuil de la mort** (1 PV) : Inconscient, ne peut se relever qu'avec du soin.
  - **Mort** (0 PV) : Hors-jeu (disparition du personnage selon la discrétion du MJ)

  Note : Le nombre de PV max peut varier avec la Ténacité.

  Lors de jets de combat ou de situations pouvant occasionner des blessures,
  le Maitre du Jeu interprétera le nombre de PV à enlever.
  Et lorsque les blessures s'accumulent, le personnage s'affaiblit petit à petit, ajoutant des malus à ses actions.

  Dans les cas extrêmes, certaines attaques n'ont pas besoin d'en passer par des tests quelconques.
  Un personnage armé d'une épée qui veut tuer un adversaire ligoté y parviendra directement,
  de même qu'un autre qui sera sous le point de chute d'un tir de catapulte n'a aucune chance de s'en tirer.
  Il faut faire preuve de logique.`);
