import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-divine-view',
  templateUrl: './system-divine.view.html',
  styleUrls: [],
})
export class SystemDivineView {
  public systemDivineText = systemDivineText;
}

export const systemDivineText = convertMarkdownToHTML(`
  Chaque personnage de la campagne / de l'**Aventure**, est un **réceptacle** (vide, ou contenant une divinité)
  et aura un **Palier divin** qui représentera l'intensité de la connexion avec sa divinité !
  De plus, pour permettre également la pérennité du personnage, ils n'ont pas "qu'une seule vie".

  **Sans divinité**.
  Le personnage n'a pas de divinité, ou la divinité est totalement endormie.
  Note : C'est le cas des personnages **hors Aventure**, qui n'ont pas de palier.

  **Palier divin I**.
  L'aura de la divinité est détectable par les autres divinités (ou par la Société de l'Eveil).
  Des interactions souhaitées mais rapides, peuvent potentiellement se produire...
  **Palier divin II**.
  Le personnage peut utiliser le pouvoir de la divinité, ou avoir une conversation complète avec, une fois par scénario.
  **Palier divin III: Absorption ou Cohabitation**.
  Le personnage réussit à assimiler la divinité, gagnant ainsi une puissance considérable
  ou établissant une cohabitation harmonieuse, selon son affinité.

  Le moyen pour progresser de Palier, et d'améliorer votre affinité... c'est secret !

  **1 mort**.
  Des interactions non-souhaitées, mais rapides, peuvent se produire...
  **2 morts**.
  La divinité peut prendre le contrôle du personnage temporairement, une fois par scénario.
  **3 morts : Mort définitive**.
  La divinité prend le contrôle complet du personnage, le personnage "disparaît" et devient injouable.`);
