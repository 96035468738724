import { Component, OnInit } from '@angular/core';

import { ArlenorCharacter } from '../../../../models/arlenor/ArlenorCharacter';
import { StoreService } from '../../../../services/store.service';
import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-societe-eveil-view',
  templateUrl: './encyclo-societe-eveil.view.html',
  styleUrls: ['./encyclo-secrets.view.scss'],
})
export class EncycloSocieteEveilView implements OnInit {
  public societeEveilText01 = societeEveilText01;
  public societeEveilText02 = societeEveilText02;
  public characters: ArlenorCharacter[];

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  public get isPlayer(): boolean {
    return this._storeService.isPlayer;
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    this.characters = [];

    const maestra = new ArlenorCharacter();
    maestra.name = 'Maestra';
    maestra.avatar = 'eveil/maestra';
    maestra.description = 'Dirigeante';
    maestra.story = '';
    this.characters.push(maestra);

    const deremoth = new ArlenorCharacter();
    deremoth.name = 'Deremoth';
    deremoth.avatar = 'eveil/deremoth';
    deremoth.description = 'Chef Chercheur I';
    deremoth.story = '';
    this.characters.push(deremoth);

    const xendel = new ArlenorCharacter();
    xendel.name = 'Xendel Eresles';
    xendel.avatar = 'default';
    xendel.description = 'Chef Chercheur II';
    xendel.story = '';
    this.characters.push(xendel);

    const yang = new ArlenorCharacter();
    yang.name = 'Yang';
    yang.avatar = 'eveil/yang';
    yang.description = 'Cheffe Chercheuse III';
    yang.story = '';
    this.characters.push(yang);

    const none = new ArlenorCharacter();
    none.name = 'None';
    none.avatar = 'eveil/none';
    none.description = 'Chef Opérateur I';
    none.story = '';
    this.characters.push(none);

    const fletcher = new ArlenorCharacter();
    fletcher.name = 'Fletcher';
    fletcher.avatar = 'eveil/fletcher';
    fletcher.description = 'Chef Opérateur II';
    fletcher.story = '';
    this.characters.push(fletcher);

    const leena = new ArlenorCharacter();
    leena.name = 'Leena';
    leena.avatar = 'eveil/leena';
    leena.description = 'Cheffe Opératrice III';
    leena.story = '';
    this.characters.push(leena);

    const rena = new ArlenorCharacter();
    rena.name = 'Rena';
    rena.avatar = 'eveil/rena';
    rena.description = 'Cheffe Opératrice III';
    rena.story = '';
    this.characters.push(rena);

    const agent01 = new ArlenorCharacter();
    agent01.name = 'Agent 01';
    agent01.avatar = 'eveil/agent01';
    agent01.description = 'Officier Opérateur';
    agent01.story = '';
    this.characters.push(agent01);

    const agent02 = new ArlenorCharacter();
    agent02.name = 'Agent 02';
    agent02.avatar = 'eveil/agent02';
    agent02.description = 'Officier Opérateur';
    agent02.story = '';
    this.characters.push(agent02);
  }
}

export const societeEveilText01 = convertMarkdownToHTML(`
**L'Histoire de la Société**

La Société de l'Eveil, avant, était dans une démarche de découverte et de compréhension.

*"Mes divinités nous ont façonnés, on a tellement à apprendre d'eux.
Découvrons, prudemment, mais cela ne pourra que être bénéfique pour faire grandir notre monde et notre société.
Cette force tirée de la religion pourra être que bénéfique pour notre science => On capture pour comprendre."*

Mais depuis la découverte des Etherolithes, l'opinion de ses membres dévie...

*"On a fait des progrès techniques, les êtres peuplant le monde ont évolué, alors pourquoi les divinités devraient être les seuls à avoir du pouvoir ?
Ils peuvent nous détruire : c'est une puissance malfaisante, c'est un danger.
Nous devons nous protéger car ils peuvent nous annihiler => On capture pour contrôler."*

C'est alors qu'une révolte s'est réalisé au sein de la Société, libérant la plupart des réceptacles.`);

export const societeEveilText02 = convertMarkdownToHTML(`
**L'Organisation de la Société**

Le Dirigeant / La Dirigeante : ???
Les Chercheurs : des membres qui sont impliqués dans la recherche et le développement de nouvelles technologies et de nouvelles formes de magie.
Les Opérateurs : des membres qui sont responsables de l'exécution des plans et des missions de la Société sur le terrain.
Les Agents : une faction armée de la Société chargée de la protection de ses intérêts et de la défense contre les ennemis extérieurs.`);
