import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-jirakan-land-view',
  templateUrl: './encyclo-jirakan-land.view.html',
  styleUrls: ['./../encyclopedia.view.scss'],
})
export class EncycloJirakanLandView {
  public citeJirakanResume = citeJirakanResume;
  public citeJirakanDetails = citeJirakanDetails;
  public citeJirakanImage = 'assets/images_filled/lands/section-jirakan_md.png';

  public commerceJirakanImage = 'assets/images_filled/world/jirakan_md.png';

  public desertSandertenImage = 'assets/images_filled/world/desert-sanderten_md.png';

  public minesJirakanImage = 'assets/images_filled/world/mines-jirakan_md.png';

  public grandeRevolteResume = grandeRevolteResume;
  public grandeRevolteImage = 'assets/images_filled/world/grande-revolte_md.png';
}

export const citeJirakanResume = convertMarkdownToHTML(`
Un climat désertique avec quelques oasis, des collines ou même des grottes minières.
Le territoire est dominé par la cité de Jirakan : très commerçante.
Ce sont les humains qui dominent ce territoire : les mutants ou les nains y sont traités comme des esclaves ou/et ressource minière.
Une particularité de Jirakan est la Minitation : un gouffre à plusieurs étages où vivent les esclaves et les mineurs.
Plus un esclave vit près de la surface, plus il est riche et important. Ceux qui vivent tout au fond ont une faible espérance de vie.
`);

export const citeJirakanDetails = convertMarkdownToHTML(`
**Histoire**.
Origines : Fondé sur le commerce et l'exploitation des ressources minières.
La Grande Révolte : Rébellion meurtrière des esclaves dans une des mines.
Festival du Gamalec : Célébration annuelle des foires, avec un marché noir.

**Géographie**.
Désert avec oasis, collines et grottes minières.
Cité : Jirakan, centre du commerce.
Autres sites : Le Marché de l'Oasis, la Minitation.

**Politique**.
Système politique : Oligarchie marchande dirigée par un Conseil, composé des 7 familles les plus riches.
Relations diplomatiques : Alliances économiques, mais souvent tendues en raison des pratiques esclavagistes.

**Économie**.
Industries : Commerce, extraction minière, cristologie.
Commerce : Échanges de minéraux, de cristaux, d'esclaves, et de produits exotiques des oasis.
Monnaie : Aucune, troc.

**Religion et Croyances**.
Panthéon : Vénération de divinités (ange Gabriel et démon Adramalec) liées au commerce et à la richesse.
Coutumes : Célébrations somptueuses pour les riches, aucun rite pour les esclaves.

**Connaissances**.
Éducation : Écoles de commerce, formation des esclaves pour des tâches spécifiques.
Technologie : Avancée dans le commerce, utilisation de systèmes d'irrigation pour les oasis.
Magie : Limitée, utilisée principalement pour les rituels religieux.

**Quotidien des Citoyens**.
Style de Vie : Luxueux pour les riches, difficile pour les esclaves.
Loisirs : Jeux de hasard, divertissements coûteux.

**Personnages Importants**.
Dirigeants : Conseil des 7 familles.
Faction : Le Consortium des Marchands;

**Menaces et Enjeux**.
Protagonistes : Esclaves révoltés, tribus nomades désertiques.
Problèmes internes : Conflits entre riches marchands et esclaves, guerres pour le contrôle des oasis.
`);

export const grandeRevolteResume = convertMarkdownToHTML(`
En l'An 666 du calendrier arlénien, un groupe d'esclaves et de partisans pour leur libération, ont monté une révolte contre leurs maîtres.
Et une nuit, ils sont passés à l'action en exterminant la majorité.

Cependant, l'armée de Faradel est arrivée le lendemain pour soutenir les maîtres et les marchands de Jirakan...
Pour contrer à cela, les esclaves se sont alors réfugiés dans les mines du Sud, près des montagnes de Kazador :
les connaissant comme leur poche, ils en tiraient donc un grand avantage.

Au bout de quelques jours de batailles dans les mines, certains ont commencé à avoir des hallucinations,
entendre des grognements et sentir de légères secousses...
La folie a alors atteint les deux camps et beaucoup ont fuit le combat dans les mines.
Puis le jour fatal arriva. Un dernier grondement, audible par tous, a déclenché l'effrondrement de la mine alors qu'une bataille y faisait rage.
Le peu de personnes ayant survécues, ont perdu toute magie et disaient qu'une entité les avait touchés.

Aujourd'hui, ces mines sont inaccessibles et définies comme étant maudites...
`);
