import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxParticlesModule } from '@tsparticles/angular';

import { ComponentsModule } from '@src/components/components.module';
import { MaterialsModule } from '@src/components/materials.modules';
import { CapitalizePipe } from '@src/pipes/capitilize.pipe';
import { AboutView } from '@src/views/about/about.view';
import { AcademyView } from '@src/views/celestia/academy/academy.view';
import { CelestiaView } from '@src/views/celestia/celestia.view';
import { ChronologyView } from '@src/views/celestia/chronology/chronology.view';
import { HomeView } from '@src/views/home/home.view';
import { KitUIView } from '@src/views/kit-ui/kit-ui.view';
import { LegacyView } from '@src/views/legacy/legacy.view';
import { AvatarView } from '@src/views/roleplay/avatar/avatar.view';
import { CharacterView } from '@src/views/roleplay/character/character.view';
import { CharacterCreationView } from '@src/views/roleplay/character/creation/character-creation.view';
import { CharacterFinalView } from '@src/views/roleplay/character/final/character-final.view';
import { CharacterIntroView } from '@src/views/roleplay/character/intro/character-intro.view';
import { CharacterSelectionView } from '@src/views/roleplay/character/selection/character-selection.view';
import { CreatureFinalView } from '@src/views/roleplay/creature/base/creature-final.view';
import { CreatureIntroView } from '@src/views/roleplay/creature/base/creature-intro.view';
import { CreatureSelectionView } from '@src/views/roleplay/creature/base/creature-selection.view';
import { CreatureCaractsView } from '@src/views/roleplay/creature/caracts/creature-caracts.view';
import { CreatureView } from '@src/views/roleplay/creature/creature.view';
import { CreatureCrystalsView } from '@src/views/roleplay/creature/crystals/creature-crystals.view';
import { CreatureDetailsView } from '@src/views/roleplay/creature/details/creature-details.view';
import { CreatureIdentityView } from '@src/views/roleplay/creature/identity/creature-identity.view';
import { CrystalsView } from '@src/views/roleplay/crystals/crystals.view';
import { RoleplayView } from '@src/views/roleplay/roleplay.view';
import { SystemJournauxView } from '@src/views/roleplay/system/journaux/system-journaux.view';
import { SystemCaractsView } from '@src/views/roleplay/system/perso/system-caracts.view';
import { SystemCrystalsView } from '@src/views/roleplay/system/perso/system-crystals.view';
import { SystemRacesView } from '@src/views/roleplay/system/perso/system-races.view';
import { SystemSkillsView } from '@src/views/roleplay/system/perso/system-skills.view';
import { SystemStoryView } from '@src/views/roleplay/system/perso/system-story.view';
import { SystemStuffsView } from '@src/views/roleplay/system/perso/system-stuffs.view';
import { SystemAlterationsView } from '@src/views/roleplay/system/rules/system-alterations.view';
import { SystemDifficultiesView } from '@src/views/roleplay/system/rules/system-difficulties.view';
import { SystemDivineView } from '@src/views/roleplay/system/rules/system-divine.view';
import { SystemExperienceView } from '@src/views/roleplay/system/rules/system-experience.view';
import { SystemFightView } from '@src/views/roleplay/system/rules/system-fight.view';
import { SystemResultsView } from '@src/views/roleplay/system/rules/system-results.view';
import { SystemView } from '@src/views/roleplay/system/system.view';
import { CalendarView } from '@src/views/universe/calendar/calendar.view';
import { EncycloBestiaryView } from '@src/views/universe/encyclopedia/bestiary/encyclo-bestiary.view';
import { EncyclopediaView } from '@src/views/universe/encyclopedia/encyclopedia.view';
import { EncycloFactionsView } from '@src/views/universe/encyclopedia/factions/encyclo-factions.view';
import { EncycloAtlanticaLandView } from '@src/views/universe/encyclopedia/lands/encyclo-atlantica-land.view';
import { EncycloDakarosLandView } from '@src/views/universe/encyclopedia/lands/encyclo-dakaros-land.view';
import { EncycloEtherLandView } from '@src/views/universe/encyclopedia/lands/encyclo-ether-land.view';
import { EncycloFaradelLandView } from '@src/views/universe/encyclopedia/lands/encyclo-faradel-land.view';
import { EncycloJirakanLandView } from '@src/views/universe/encyclopedia/lands/encyclo-jirakan-land.view';
import { EncycloNoLandView } from '@src/views/universe/encyclopedia/lands/encyclo-no-land.view';
import { EncycloSomniLandView } from '@src/views/universe/encyclopedia/lands/encyclo-somni-land.view';
import { EncycloCoalitionView } from '@src/views/universe/encyclopedia/secrets/encyclo-coalition.view';
import { EncycloDivineView } from '@src/views/universe/encyclopedia/secrets/encyclo-divine.view';
import { EncycloSocieteEveilView } from '@src/views/universe/encyclopedia/secrets/encyclo-societe-eveil.view';
import { MagicView } from '@src/views/universe/magic/magic.view';
import { RacesView } from '@src/views/universe/races/races.view';
import { ReligionView } from '@src/views/universe/religion/religion.view';
import { UniverseView } from '@src/views/universe/universe.view';

@NgModule({
  imports: [CommonModule, ComponentsModule, MaterialsModule, ReactiveFormsModule, NgxParticlesModule],
  declarations: [
    // Pipes
    CapitalizePipe,
    // Views
    AboutView,
    AcademyView,
    AvatarView,
    HomeView,
    CalendarView,
    CelestiaView,
    ChronologyView,
    CharacterView,
    CharacterCreationView,
    CharacterFinalView,
    CharacterIntroView,
    CharacterSelectionView,
    CreatureView,
    CreatureCaractsView,
    CreatureCrystalsView,
    CreatureDetailsView,
    CreatureFinalView,
    CreatureIdentityView,
    CreatureIntroView,
    CreatureSelectionView,
    CrystalsView,
    EncyclopediaView,
    EncycloAtlanticaLandView,
    EncycloBestiaryView,
    EncycloDakarosLandView,
    EncycloDivineView,
    EncycloCoalitionView,
    EncycloEtherLandView,
    EncycloFactionsView,
    EncycloFaradelLandView,
    EncycloJirakanLandView,
    EncycloNoLandView,
    EncycloSocieteEveilView,
    EncycloSomniLandView,
    KitUIView,
    LegacyView,
    MagicView,
    RacesView,
    ReligionView,
    RoleplayView,
    SystemView,
    SystemAlterationsView,
    SystemCaractsView,
    SystemCrystalsView,
    SystemDifficultiesView,
    SystemDivineView,
    SystemExperienceView,
    SystemFightView,
    SystemJournauxView,
    SystemRacesView,
    SystemResultsView,
    SystemSkillsView,
    SystemStoryView,
    SystemStuffsView,
    UniverseView,
  ],
})
export class ViewsModule {}
