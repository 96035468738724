<div
  class="power-img-layout"
  [class.big]="isBig"
  [class.before]="power.codeRank === powerRanksEnum.Rare.Code || power.codeRank === powerRanksEnum.TresRare.Code"
  [class.after]="power.codeRank === powerRanksEnum.TresRare.Code"
  [title]="power.type.Libelle"
>
  <img *ngIf="power.image" class="power-img" [src]="power.image" [alt]="power.type.Libelle" />
  <!--img *ngIf="power.isVerified" class="power-img-ok" src="assets/images_filled/powers/ok.png" aria-hidden="true"-->
</div>
