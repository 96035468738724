import { Component, OnInit } from '@angular/core';

import { ArlenorMagic } from '../../../models/arlenor/ArlenorMagic';
import { getListMagics } from '../../../models/data/ListMagics';
import { PageTitles } from '../../../models/PagesTitles';
import { convertMarkdownToHTML } from '../../../utils/conversions';

@Component({
  selector: 'magic-view',
  templateUrl: './magic.view.html',
  styleUrls: ['./magic.view.scss'],
})
export class MagicView implements OnInit {
  public resume = magicResume;

  public currentIndex: number;
  public allMagics: ArlenorMagic[];

  public title = PageTitles.magic;
  public imageLeft = 'assets/images_alpha/magic/magic_left.png';
  public imageRight = 'assets/images_alpha/magic/magic_right.png';

  public get previousIndex(): number {
    if (this.currentIndex === 0) return this.allMagics.length - 1;
    else return this.currentIndex - 1;
  }

  public get nextIndex(): number {
    if (this.currentIndex === this.allMagics.length - 1) return 0;
    else return this.currentIndex + 1;
  }

  public get currentMagic(): ArlenorMagic {
    return this.allMagics[this.currentIndex];
  }

  public ngOnInit() {
    this.currentIndex = 0;
    this.allMagics = getListMagics();
  }

  public previousSelection() {
    if (this.currentIndex === 0) this.currentIndex = this.allMagics.length - 1;
    else this.currentIndex = this.currentIndex - 1;
  }

  public nextSelection() {
    if (this.currentIndex === this.allMagics.length - 1) this.currentIndex = 0;
    else this.currentIndex = this.currentIndex + 1;
  }
}

export const magicResume = convertMarkdownToHTML(`
  Les premiers humains humains firent une découverte : dans certaines roches se trouvaient des cristaux.
  Et au toucher, ces cristaux produisirent des phénomènes étranges, que l'on qualifie aujourd'hui de **magie**...
  Les humains commencèrent alors à ressentir comme un lien inexplicable se former en leur contact.
  
  La plupart considère ces cristaux comme **des fragments de pouvoirs des anges et démons**.
  Cette idée a été renforcée par le fait que certains arrivent à les utiliser, et d'autres non : **comme si certains étaient des élus**.

  Le Monde d'Arlénor vit alors : une chasse aux cristaux, un développement d'outils, d'armes,
  et même une citée basée sur ces cristaux. Et aujourd'hui, la cité de Faradel est LE lieu le plus magique
  et le plus avancé technologiquement qui soit, dans le Monde d'Arlénor.`);
