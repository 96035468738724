<div class="card u-marginTop1" [innerHtml]="systemAlterationsText"></div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSourceActions">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="col-20">Altérations d'actions</th>
      <td mat-cell *matCellDef="let element" class="col-20 u-textBold">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="col-50">Description</th>
      <td mat-cell *matCellDef="let element" class="col-50">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="effect">
      <th mat-header-cell *matHeaderCellDef class="col-30">Effet</th>
      <td mat-cell *matCellDef="let element" class="col-30">{{ element.effect ? element.effect : '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSourceMentales">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="col-20">Altérations mentales</th>
      <td mat-cell *matCellDef="let element" class="col-20 u-textBold">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="col-50">Description</th>
      <td mat-cell *matCellDef="let element" class="col-50">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="effect">
      <th mat-header-cell *matHeaderCellDef class="col-30">Effet</th>
      <td mat-cell *matCellDef="let element" class="col-30">{{ element.effect ? element.effect : '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSourceElementales">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="col-20">Altérations élémentaires</th>
      <td mat-cell *matCellDef="let element" class="col-20 u-textBold">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="col-50">Description</th>
      <td mat-cell *matCellDef="let element" class="col-50">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="effect">
      <th mat-header-cell *matHeaderCellDef class="col-30">Effet</th>
      <td mat-cell *matCellDef="let element" class="col-30">{{ element.effect ? element.effect : '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSourceDiverses">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef class="col-20">Altérations diverses</th>
      <td mat-cell *matCellDef="let element" class="col-20 u-textBold">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="col-50">Description</th>
      <td mat-cell *matCellDef="let element" class="col-50">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="effect">
      <th mat-header-cell *matHeaderCellDef class="col-30">Effet</th>
      <td mat-cell *matCellDef="let element" class="col-30">{{ element.effect ? element.effect : '-' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
