import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { getListRaces } from '@src/models/data/ListRaces';
import { DifficultyEnum } from '@src/models/ModelEnum';

import { ArlenorRace } from '../../../models/arlenor/ArlenorRace';

@Component({
  selector: 'race-modal',
  templateUrl: './race.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class RaceModal implements OnInit {
  public currentIndex: number;
  public allRaces: ArlenorRace[];

  public get race(): ArlenorRace {
    return this.allRaces[this.currentIndex];
  }

  constructor(
    public dialogRef: MatDialogRef<RaceModal>,
    @Inject(MAT_DIALOG_DATA) public data: ArlenorRace
  ) {}

  public ngOnInit(): void {
    this.allRaces = getListRaces().filter(race => race.difficulty !== DifficultyEnum.Impossible.Code);
    this.currentIndex = this.data ? this.allRaces.findIndex(race => race.code === this.data.code) : 0;
  }

  public previous() {
    if (this.currentIndex === 0) this.currentIndex = this.allRaces.length - 1;
    else this.currentIndex--;
  }

  public next() {
    if (this.currentIndex === this.allRaces.length - 1) this.currentIndex = 0;
    else this.currentIndex++;
  }
}
