<mat-tab-group animationDuration="0ms" class="u-marginTop1">
  <mat-tab label="La Liste des Classes">
    <div class="card" [innerHtml]="systemCrystalsText01"></div>
    <div class="u-flexColumn u-marginTop1">
      <expand-bloc
        *ngFor="let role of allRoles"
        [label]="'Les ' + role.name + 's'"
        [isOpen]="roleChoice === role.code"
        (outToggle)="changeRoleChoice(role.code)"
      >
        <div class="grid-list grid-2">
          <div *ngFor="let spe of getSpecialities(role.code)" class="grid-element">
            <img [src]="spe.image" [alt]="spe.name" />
            <div>
              <div class="u-flexRow u-alignCenter u-gap05 u-marginBottom05">
                <h3>{{ spe.name }}</h3>
                <span [class]="spe.role.icon + ' u-text' + (spe.color | capitalize)" [title]="spe.role.name"></span>
              </div>
              <p>{{ spe.description }}</p>
              <div class="u-marginTop05 u-marginBottom05">
                <arrow-button
                  linkName="En savoir plus"
                  linkPage="/jeu-de-roles/cristaux"
                  [linkParams]="{ spe: spe.code }"
                >
                </arrow-button>
              </div>
            </div>
          </div>
        </div>
      </expand-bloc>
    </div>
  </mat-tab>
  <mat-tab label="Les Pouvoirs et leurs Propriétés">
    <div class="card" [innerHtml]="systemCrystalsText02"></div>
  </mat-tab>
</mat-tab-group>
