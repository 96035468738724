<h2 class="u-textCenter u-marginBottom2">Formulaire</h2>

<div class="sides">
  <div class="sides-left">
    <div class="sides-left-bloc">
      <character-resume [hasLevel]="hasLevel"></character-resume>
      <button type="button" mat-stroked-button [color]="isModified ? 'warn' : ''" (click)="reset()">
        <i class="icon-arrow-left"></i>
        <ng-container *ngIf="!isModified">Retour à l'introduction</ng-container>
        <ng-container *ngIf="isModified">Annuler les modifications</ng-container>
      </button>
    </div>
  </div>
  <div class="sides-right">
    <div class="u-flexColumn">
      @if (!hasLevel) {
        <character-level (outConfirm)="confirmLevel()"></character-level>
      } @else {
        <character-race (outCheck)="check('race', $event)"></character-race>
        <character-caracts (outCheck)="check('caracts', $event)"></character-caracts>
        <character-crystals [indexCrystal]="0" (outCheck)="check('crystals01', $event)"></character-crystals>
        <character-crystals
          *ngIf="character.numLevel >= 5"
          [indexCrystal]="1"
          (outCheck)="check('crystals02', $event)"
        ></character-crystals>
        <character-skills (outCheck)="check('skills', $event)"></character-skills>
        <character-divinity (outCheck)="check('divinity', $event)"></character-divinity>
        <character-identity (outCheck)="check('identity', $event)"></character-identity>
        <character-details (outCheck)="check('details', $event)"></character-details>

        <div class="u-flexRow u-justifyCenter u-marginTop1">
          <button type="button" mat-stroked-button color="primary" [disabled]="form.invalid" (click)="finish()">
            Confirmer la création
          </button>
        </div>
      }
    </div>
  </div>
</div>
