import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorDivinity } from '@src/models/arlenor/ArlenorDivinity';
import { getListDivinites } from '@src/models/data/ListDivinities';

@Component({
  selector: 'divinity-modal',
  templateUrl: './divinity.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class DivinityModal implements OnInit {
  public currentIndex: number;
  public allDivinities: ArlenorDivinity[];

  public get divinity(): ArlenorDivinity {
    return this.allDivinities[this.currentIndex];
  }

  constructor(
    public dialogRef: MatDialogRef<DivinityModal>,
    @Inject(MAT_DIALOG_DATA) public data: ArlenorDivinity
  ) {}

  public ngOnInit(): void {
    this.allDivinities = getListDivinites();
    this.currentIndex = this.data ? this.allDivinities.findIndex(divinity => divinity.code === this.data.code) : 0;
  }

  public previous() {
    if (this.currentIndex === 0) this.currentIndex = this.allDivinities.length - 1;
    else this.currentIndex--;
  }

  public next() {
    if (this.currentIndex === this.allDivinities.length - 1) this.currentIndex = 0;
    else this.currentIndex++;
  }
}
