import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorPower, PowerCategoriesEnum, PowerDurationsEnum, PowerRangesEnum, PowerRanksEnum, PowerTypesEnum } from '../../../models/arlenor/ArlenorPower';
import { ArlenorSpeciality } from '../../../models/arlenor/ArlenorSpeciality';
import { ArlenorSpecialities } from '../../../models/data/ListSpecialities';

@Component({
  selector: 'power-form-modal',
  templateUrl: './power-form.modal.html',
  styleUrls: [],
})
export class PowerFormModal implements OnInit {
  public allSpecialities: ArlenorSpeciality[];
  public allRanks = Object.values(PowerRanksEnum);
  public allTypes = Object.values(PowerTypesEnum);
  public allCategories = Object.values(PowerCategoriesEnum);
  public allRanges = Object.values(PowerRangesEnum);
  public allDurations = Object.values(PowerDurationsEnum);

  public form: FormGroup;
  public title: string;
  public power: ArlenorPower;

  constructor(
    public dialogRef: MatDialogRef<PowerFormModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      codeSpeciality: new FormControl(null, Validators.required),
      codeRank: new FormControl(null, Validators.required),
      codeType: new FormControl(null, Validators.required),
      codeCategory: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      codeRange: new FormControl(null, Validators.required),
      codeDuration: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit(): void {
    this.title = this.data.title;
    const codeSpe = this.data.codeSpe;
    this.power = this.data.power;
    this.allSpecialities = ArlenorSpecialities.getListSpecialities();
    this.allSpecialities.sort((a, b) => a.name.localeCompare(b.name));

    // Initialisiation
    this.form.controls.name.setValue(this.power.name);
    this.form.controls.codeSpeciality.setValue(codeSpe);
    this.form.controls.codeRank.setValue(this.power.codeRank);
    this.form.controls.codeType.setValue(this.power.codeType);
    this.form.controls.codeCategory.setValue(this.power.codeCategory);
    this.form.controls.description.setValue(this.power.description);
    this.form.controls.codeRange.setValue(this.power.codeRange);
    this.form.controls.codeDuration.setValue(this.power.codeDuration);
  }

  public confirm() {
    this.power.name = this.form.controls.name.value;
    this.power.codeSpeciality = this.form.controls.codeSpeciality.value;
    this.power.codeRank = this.form.controls.codeRank.value;
    this.power.codeType = this.form.controls.codeType.value;
    this.power.codeCategory = this.form.controls.codeCategory.value;
    this.power.description = this.form.controls.description.value;
    this.power.codeRange = this.form.controls.codeRange.value;
    this.power.codeDuration = this.form.controls.codeDuration.value;

    this.dialogRef.close(this.power);
  }
}
