import { Component } from '@angular/core';

@Component({
  selector: 'system-fight-view',
  templateUrl: './system-fight.view.html',
  styleUrls: [],
})
export class SystemFightView {
  public fightChoice = 0;

  public changeFightChoice(choice: number) {
    if (choice === this.fightChoice) this.fightChoice = 0;
    else this.fightChoice = choice;
  }
}
