import { Component, DestroyRef, EventEmitter, inject, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-skills',
  templateUrl: './character-skills.component.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterSkillsComponent implements OnInit, OnChanges {
  @Output() public outCheck = new EventEmitter<boolean>();

  public form: FormGroup;
  public character: ArlenorCharacter;
  public idsSkills: number[];

  private _destroyRef = inject(DestroyRef);

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  constructor(private _storeService: StoreService) {
    this.form = new FormGroup({
      isNbSkillsValid: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    this.character = this._storeService.character;
    this.idsSkills = this.character.idsSkills;

    this._checkOldSkills();

    this.outCheck.emit(!this.form.invalid);
    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._storeService.modifyCharacter();
      this.outCheck.emit(!this.form.invalid);
      this._save();
    });
  }

  public ngOnChanges() {
    this._checkOldSkills();
  }

  public addSkill(skill: ArlenorSkill) {
    this.idsSkills.push(skill.id);
    this._storeService.modifyCharacter();
    this._checkOldSkills();
  }

  public removeSkill(skill: ArlenorSkill) {
    this.idsSkills = this.idsSkills.filter((idSkill: number) => idSkill !== skill.id);
    this._storeService.modifyCharacter();
    this._checkOldSkills();
  }

  private _checkOldSkills() {
    if (!this.allSkills || !this.idsSkills) return;
    const validIdsSkills = this.allSkills.map(skill => skill.id);
    this.idsSkills = this.idsSkills.filter(idSkill => validIdsSkills.includes(idSkill));
    this._checkNbSkills();
  }

  private _checkNbSkills() {
    if (!this.allSkills || !this.idsSkills) return;
    const nbSkills = this.idsSkills.length;
    this.form.controls.isNbSkillsValid.setValue(nbSkills === this.character.level.maxSkills ? true : null);
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.idsSkills = this.idsSkills;
    this._storeService.changeCharacterSkills(newCharacter);
  }
}
