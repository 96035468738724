<div class="universe-view bg-universe">
  <div class="layout-view">
    <h1 class="u-marginBottom2">{{ pages.worldmap }}</h1>

    <div class="sides">
      <div class="sides-left">
        <div class="sides-left-bloc">
          <div class="card u-textCenter u-width100">
            <img class="universe-img" [src]="currentZone.image" [alt]="currentZone.name" />
            <hr class="u-marginTop1 u-marginBottom1" />
            <h2 class="u-marginBottom05">{{ currentZone.name }}</h2>
            <h3 class="u-marginBottom1">{{ currentZone.sector.name }}</h3>
            <p class="u-marginBottom1">
              Climat&nbsp;:&nbsp;{{ currentZone.sector.climate }}<br />
              Niveau de danger&nbsp;:&nbsp;{{ currentZone.sector.danger }}
            </p>
            @if (currentZone.linkPage && currentZone.linkParams) {
              <arrow-button
                linkName="En savoir plus"
                [linkPage]="currentZone.linkPage"
                [linkParams]="{ section: currentZone.linkParams }"
              ></arrow-button>
            } @else if (currentZone.linkPage) {
              <arrow-button linkName="En savoir plus" [linkPage]="currentZone.linkPage"></arrow-button>
            } @else {
              <button type="button" mat-stroked-button disabled>Zone actuellement indisponible</button>
            }
            @if (currentZone.comment) {
              <hr class="u-marginTop1 u-marginBottom1" />
              <p class="u-marginTop1" comments>
                "{{ currentZone.comment }}"
                <br />
                - {{ currentZone.commentName }}
              </p>
            }
          </div>
        </div>
      </div>
      <div class="sides-right">
        <div class="universe-map-section">
          <i
            *ngFor="let zone of allZones; let index = index"
            class="universe-map-target icon-location u-opacity05 u-pointer"
            [class.u-opacity1]="zone.name === currentZone.name"
            [style]="{ top: zone.posTop + '%', left: zone.posLeft + '%' }"
            (mouseover)="selectZone(index)"
            (click)="selectZone(index)"
          ></i>
          <img
            [class.u-displayNone]="showCities"
            class="universe-map card"
            src="assets/images_filled/map/map-climates_hd.png"
            alt="Carte des Climats"
          />
          <img
            [class.u-displayNone]="!showCities"
            class="universe-map card"
            src="assets/images_filled/map/map-cities_hd.png"
            alt="Carte des Cités"
          />
        </div>

        <div class="u-flexRow u-justifyCenter u-marginTop1">
          <toggle-button
            [text]="'Voir les cités et les niveaux de danger'"
            [value]="showCities"
            (outChange)="toggleMap($event)"
          ></toggle-button>
        </div>
      </div>
    </div>
  </div>
</div>
