<div class="card u-marginTop1" [innerHtml]="systemExperienceText"></div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSource01">
    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef>Niveau</th>
      <td mat-cell *matCellDef="let element" class="u-textBold">{{ element.level }}</td>
    </ng-container>

    <ng-container matColumnDef="pvs">
      <th mat-header-cell *matHeaderCellDef>PVs de base</th>
      <td mat-cell *matCellDef="let element">{{ element.pvs ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="caracts">
      <th mat-header-cell *matHeaderCellDef>Caractéristiques</th>
      <td mat-cell *matCellDef="let element">{{ element.caracts ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="skills">
      <th mat-header-cell *matHeaderCellDef>Compétences</th>
      <td mat-cell *matCellDef="let element">{{ element.skills ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="powers01">
      <th mat-header-cell *matHeaderCellDef>Pouvoirs<br />(1ère classe)</th>
      <td mat-cell *matCellDef="let element">{{ element.powers01 ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="powers02">
      <th mat-header-cell *matHeaderCellDef>Pouvoirs<br />(2ème classe)</th>
      <td mat-cell *matCellDef="let element">{{ element.powers02 ?? '-----' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div class="mdc-table-container u-marginTop1">
  <table mat-table [dataSource]="dataSource02">
    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef>Niveau</th>
      <td mat-cell *matCellDef="let element" class="u-textBold">{{ element.level }}</td>
    </ng-container>

    <ng-container matColumnDef="pvs">
      <th mat-header-cell *matHeaderCellDef>PVs de base</th>
      <td mat-cell *matCellDef="let element">{{ element.pvs ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="caracts">
      <th mat-header-cell *matHeaderCellDef>Caractéristiques</th>
      <td mat-cell *matCellDef="let element">{{ element.caracts ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="skills">
      <th mat-header-cell *matHeaderCellDef>Compétences</th>
      <td mat-cell *matCellDef="let element">{{ element.skills ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="powers01">
      <th mat-header-cell *matHeaderCellDef>Pouvoirs<br />(1ère classe)</th>
      <td mat-cell *matCellDef="let element">{{ element.powers01 ?? '-----' }}</td>
    </ng-container>

    <ng-container matColumnDef="powers02">
      <th mat-header-cell *matHeaderCellDef>Pouvoirs<br />(2ème classe)</th>
      <td mat-cell *matCellDef="let element">{{ element.powers02 ?? '-----' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
