import { Component } from '@angular/core';

import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-story-view',
  templateUrl: './system-story.view.html',
  styleUrls: [],
})
export class SystemStoryView {
  public systemStoryText = systemStoryText;
  public systemStoryText01 = systemStoryText01;
  public systemStoryText02 = systemStoryText02;
  public systemStoryText03 = systemStoryText03;
  public finitionChoice = 0;

  public changeFinitionChoice(choice: number) {
    if (choice === this.finitionChoice) this.finitionChoice = 0;
    else this.finitionChoice = choice;
  }
}

export const systemStoryText = convertMarkdownToHTML(`
  Le jeu de rôle est un moyen de raconter des histoires, comme les romans ou le cinéma,
  et les histoires sont toujours meilleures lorsqu'elles ont des héros crédibles et profonds.
  Pour l'instant, votre personnage est un tas de chiffres gribouillés au crayon sur un formulaire.

  Pour lui insuffler un peu de vie, la première chose à faire est de remplir son "état civil".

  **Quel est son âge et son genre ?** Dans la campagne principale du Monde d'Arlénor, l'âge est forcément entre 20 et
  30 ans.

  **Et... Comment s'appelle votre personnage ?** Le choix d'un nom est toujours un moment délicat.
  Évitez les noms ridicules ou connotés, car au bout de quelques parties, cela ne fera plus rire personne.
  Une fois baptisé, il reste à lui donner un peu de relief. Si le coeur vous en dit, prenez quelques minutes pour
  réfléchir aux points suivants...`);

export const systemStoryText01 = convertMarkdownToHTML(`
  Il existe une foule de détails qu'aucun jet de dés ne définira à votre place.
  
  Quelle est la couleur des yeux de votre personnage ? Celle de ses cheveux ?
  Est-il grand ? A-t-il une longue barbe ? Est-il élégant ?
  Quelle est la première impression qu'il laisse ?

  Voici quelques adjectifs pour vous aider :
  Sévère, Joli, Beau, Sensuel, Distingué, Sale, Jeune,
  Gros, Las, Poilu, Bronzé, Ridé, Musclé, Pâle, Insipide.

  Et il n'y a rien de mieux qu'une image, un avatar pour compléter son profil !`);

export const systemStoryText02 = convertMarkdownToHTML(`
  Pensez à votre personnage comme à une vraie personne.
  A-t-il des goûts particuliers ? Des choses dont il a horreur ? Une passion pour les rousses ?
  L'habitude de commander un poulet rôti et de la tarte au citron dans toutes les auberges où il déjeune ?
  Essayez de trouver un ou deux détails qui le caractériseront, mais limitez-vous à cela.
  Sinon votre personnage risque d'être une collection de tics.

  Voici quelques exemples pour vous aider :
  Généreux, Fan des oiseaux, Rêveur, Parieur, Audacieux, Réservé,
  Séducteur, Loyal, Populaire, Intellectuel, Persévérant, Agressif.

  Au niveau des idéologies et de ses croyances, est-ce que votre personnage tolère les mutants ? Ou plussoie leur
  extermination ?
  Est-ce qu'il prie Arlénor ? Adule-t-il une divinité en particulier ? Croit-il plutôt en la science ?
  Se fie-t-il au Destin ? Et qu'est-ce qui lui tient à coeur ? Une proche, parent ou ami, un professeur... une personnne
  récemment décédée ?
  Ou est-ce plutôt un lieu particulier qui lui sert de refuge, de sanctuaire ?`);

export const systemStoryText03 = convertMarkdownToHTML(`
  Maintenant que vous avez une assez bonne image de lui dans le présent,
  remontez un peu dans son passé. Où a-t-il grandi ? A-t-il des amis ? De la famille ? Quelles sont ses relations avec
  eux ?
  
  Dans la campagne principale du Monde d'Arlénor, les personnages joueurs, seront obligatoirement
  des enfants adoptés par une famille, et ne connaissent pas leurs véritables parents, ni même ce qu'il s'est passé
  avant leur adoption ?

  De plus, avec le temps et le jeu, votre personnage évoluera et effectuera des actions épiques (ou l'inverse).
  Ce sera le bon moment pour les noter ! Votre personnage sera connu "pour ça" !`);
