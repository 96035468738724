<div class="avatar-view bg-roleplay">
  <div class="layout-view">
    <h1>{{ title }}</h1>

    <div class="card u-width100 is-warning u-marginTop1">
      Le créateur d'avatar est une solution en cours de développement, certaines fonctionnalités peuvent être absentes
      ou n'ont pas encore été testées.
    </div>

    <div class="u-width100 u-marginTop1">
      <meiker [assetsItems]="items" [assetsName]="name" [height]="height" [width]="width"></meiker>
    </div>
  </div>
</div>
