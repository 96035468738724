<div class="encyclo-adventure-view u-marginTop1">
  @if (!isAdmin && !isMaster && !isPlayer) {
    <div class="card is-info">Données réservées aux Maîtres du Jeu et/ou Joueurs.</div>
  } @else if (team === 'Satan') {
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="0) Les Personnages">
        <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamSatan }"></ng-container>
      </mat-tab>
      <mat-tab label="1) Le Temple de Kiranof">
        <div class="card u-marginTop1" [innerHtml]="journalSatan01"></div>
      </mat-tab>
    </mat-tab-group>
  } @else if (team === 'Gabriel') {
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="0) Les Personnages">
        <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamGabriel }"></ng-container>
      </mat-tab>
      <mat-tab label="1) Le Temple de Kiranof">
        <div class="card u-marginTop1" [innerHtml]="journalGabriel01"></div>
      </mat-tab>
      <mat-tab label="2) La Forêt d'Imerys">
        <div class="card u-marginTop1" [innerHtml]="journalGabriel02"></div>
      </mat-tab>
      <mat-tab label="3) Les Mines de Jirakan">
        <div class="card u-marginTop1" [innerHtml]="journalGabriel03"></div>
      </mat-tab>
      <mat-tab label="4) La Lucifaniel">
        <div class="card u-marginTop1" [innerHtml]="journalGabriel04"></div>
      </mat-tab>
    </mat-tab-group>
  } @else if (team === 'Asmodee') {
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="0) Les Personnages">
        <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamAsmodee }"></ng-container>
      </mat-tab>
      <mat-tab label="1) Le Temple de Kiranof">
        <div class="card u-marginTop1" [innerHtml]="journalAsmodee01"></div>
      </mat-tab>
      <mat-tab label="2) La Forêt d'Imerys">
        <div class="card u-marginTop1" [innerHtml]="journalAsmodee02"></div>
      </mat-tab>
      <mat-tab label="3) Les Mines de Jirakan">
        <div class="card u-marginTop1" [innerHtml]="journalAsmodee03"></div>
      </mat-tab>
    </mat-tab-group>
  } @else if (team === 'Michael') {
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="0) Les Personnages">
        <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamMichael }"></ng-container>
      </mat-tab>
      <mat-tab label="1) Le Temple de Kiranof">
        <div class="card u-marginTop1" [innerHtml]="journalMichael01"></div>
      </mat-tab>
    </mat-tab-group>
  } @else if (team === 'Lilith') {
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="0) Les Personnages">
        <ng-container *ngTemplateOutlet="charactersGrid; context: { team: teamLilith }"></ng-container>
      </mat-tab>
      <mat-tab label="1) Le Temple de Kiranof">
        <div class="card u-marginTop1" [innerHtml]="journalLilith01"></div>
      </mat-tab>
    </mat-tab-group>
  }
</div>

<ng-template #charactersGrid let-team="team">
  <div *ngIf="!getCharacters(team).length" class="card is-info u-marginTop1">Aucun personnage.</div>

  <div class="grid-list grid-2 u-marginTop1">
    <div *ngFor="let character of getCharacters(team)" class="character-item grid-element">
      <img *ngIf="character.profil" class="character-image" [src]="character.profil" [alt]="character.name" />
      <div>
        <h3>{{ character.name }}</h3>
        <p class="u-textBold u-marginBottom05">{{ getDetails(character) }}</p>
        <p class="u-marginBottom05" comments>
          {{ character.description }}
          <br />{{ character.traits }}
        </p>
        <p class="u-marginBottom0">
          {{ character.story ? character.story : 'Aucune description' }}
        </p>
      </div>
    </div>
  </div>
</ng-template>
