<div class="card">
  @for (type of types; track type.Code; let isFirst = $first) {
    <hr *ngIf="!isFirst" class="u-marginTop2 u-marginBottom1" />
    <div class="u-flexRow u-justifyBetween u-marginBottom1">
      <h3>{{ type.Libelle }}</h3>
    </div>
    <div class="u-flexRow u-flexWrap">
      <button
        *ngFor="let skill of typesSkills[type.Code]"
        mat-stroked-button
        class="skills-selection-button"
        [disabled]="checkDisabled(skill)"
        [color]="checkSkill(skill) ? 'primary' : ''"
        (click)="changeSkill(skill)"
      >
        <div class="skills-selection-button-hover">
          <h2 class="u-marginTop0 u-marginBottom0">{{ skill.name }}</h2>
          <b>{{ skill.type.Libelle }}</b>
          <br />
          <br />
          {{ skill.description ? skill.description : 'Aucune description disponible' }}
        </div>
        <img
          class="skills-selection-button-img"
          [src]="skill.image"
          [alt]="skill.type.Libelle"
          [title]="skill.type.Libelle"
        />
        {{ skill.name }}
        <mat-checkbox
          [disabled]="checkDisabled(skill)"
          [color]="checkSkill(skill) ? 'primary' : ''"
          [checked]="checkSkill(skill)"
        ></mat-checkbox>
      </button>
    </div>
  }
</div>
