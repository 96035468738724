<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef class="col-20"></th>
    <td mat-cell *matCellDef="let element" class="col-20 u-pointer" (click)="seeMore(element)">
      <div class="power-img-layout" [title]="element.type.Libelle">
        <img *ngIf="element.image" class="power-img" [src]="element.image" [alt]="element.type.Libelle" />
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef class="col-40">Type d'équipement</th>
    <td mat-cell *matCellDef="let element" class="col-40 u-pointer" (click)="seeMore(element)">
      {{ element.type.Libelle }}
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="col-40">Nom de l'équipement</th>
    <td mat-cell *matCellDef="let element" class="col-40 u-pointer" (click)="seeMore(element)">
      {{ element.name }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div *ngIf="!dataSource" class="card is-info u-textCenter">Aucun équipement.</div>
