import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

import { ArlenorBestiary } from '../../../models/arlenor/ArlenorBestiary';
import { ArlenorCreature } from '../../../models/arlenor/ArlenorCreature';
import { PageSubtitles, PageTitles } from '../../../models/PagesTitles';
import { TreeNode } from '../../../models/TreeNode';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'encyclopedia-view',
  templateUrl: './encyclopedia.view.html',
  styleUrls: ['./encyclopedia.view.scss'],
})
export class EncyclopediaView implements OnInit {
  public imageBestiary = 'assets/images_filled/encyclo/section-bestiary_hd.png';
  public imageDivine = 'assets/images_filled/encyclo/section-divine_ld.webp';
  public imageFactionsOff = 'assets/images_filled/encyclo/section-officielles_ld.webp';
  public imageFactionsSec = 'assets/images_filled/encyclo/section-secretes_ld.webp';

  public imageFaradel = 'assets/images_filled/lands/section-faradel_md.png';
  public imageJirakan = 'assets/images_filled/lands/section-jirakan_md.png';
  public imageDakaros = 'assets/images_filled/lands/section-dakaros_md.png';
  public imageEther = 'assets/images_filled/lands/section-ether_md.png';
  public imageAtlantica = 'assets/images_filled/lands/section-atlantica_md.png';
  public imageSomni = 'assets/images_filled/lands/section-somni_md.png';
  public imageNo = 'assets/images_filled/lands/section-no_md.png';

  public title = PageTitles.encyclopedia;
  public currentCode: string;
  public currentSection: TreeNode;
  public previousSection: TreeNode;
  public nextSection: TreeNode;
  public encycloMenu: TreeNode[];
  public allBestiaries: ArlenorBestiary[];

  private _destroyRef = inject(DestroyRef);

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get isMaster(): boolean {
    return this._storeService.isMaster;
  }

  public get isPlayer(): boolean {
    return this._storeService.isPlayer;
  }

  public get creatures(): ArlenorCreature[] {
    const allCreatures = this._storeService.$allCreatures.value || [];
    const localCreatures = this._storeService.localCreatures || [];
    return allCreatures.concat(localCreatures);
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this.encycloMenu = [];
    this.encycloMenu.push({
      title: PageSubtitles.encycloFactions,
      children: [
        {
          title: PageSubtitles.encycloFactionsOff,
          code: 'EncycloFactionsOffView',
          subtitle: PageSubtitles.encycloFactions,
          icon: 'icon-price-tags',
          image: this.imageFactionsOff,
          tags: ['faction', 'guilde', 'officiel'],
        },
        {
          title: PageSubtitles.encycloFactionsSec,
          code: 'EncycloFactionsSecView',
          subtitle: PageSubtitles.encycloFactions,
          icon: 'icon-price-tags',
          image: this.imageFactionsSec,
          tags: ['faction', 'guilde', 'secret'],
        },
      ],
    });
    this.encycloMenu.push({
      title: PageSubtitles.encycloLands,
      children: [
        {
          title: PageSubtitles.encycloFaradelLand,
          code: 'EncycloFaradelLandView',
          subtitle: PageSubtitles.encycloLands,
          icon: 'icon-pushpin',
          image: this.imageFaradel,
        },
        {
          title: PageSubtitles.encycloJirakanLand,
          code: 'EncycloJirakanLandView',
          subtitle: PageSubtitles.encycloLands,
          icon: 'icon-pushpin',
          image: this.imageJirakan,
        },
        {
          title: PageSubtitles.encycloDakarosLand,
          code: 'EncycloDakarosLandView',
          subtitle: PageSubtitles.encycloLands,
          icon: 'icon-pushpin',
          image: this.imageDakaros,
        },
        {
          title: PageSubtitles.encycloEtherLand,
          code: 'EncycloEtherLandView',
          subtitle: PageSubtitles.encycloLands,
          icon: 'icon-pushpin',
          image: this.imageEther,
        },
        {
          title: PageSubtitles.encycloAtlanticaLand,
          code: 'EncycloAtlanticaLandView',
          subtitle: PageSubtitles.encycloLands,
          icon: 'icon-pushpin',
          image: this.imageAtlantica,
        },
        {
          title: PageSubtitles.encycloSomniLand,
          code: 'EncycloSomniLandView',
          subtitle: PageSubtitles.encycloLands,
          icon: 'icon-pushpin',
          image: this.imageSomni,
        },
        { title: PageSubtitles.encycloNoLand, code: 'EncycloNoLandView', subtitle: PageSubtitles.encycloLands, icon: 'icon-pushpin', image: this.imageNo },
      ],
    });
    // Ajout du bestiaire
    /*const childrenBestiaries: TreeNode[] = [];
    this.allBestiaries = getListBestiaries();
    this.allBestiaries.forEach((bestiary, index) => {
      childrenBestiaries.push({
        title: bestiary.name,
        code: 'EncycloBestiary' + index + 'View',
        subtitle: PageSubtitles.encycloBestiary,
        icon: 'icon-leaf',
        image: this.imageBestiary,
      });
    });
    this.encycloMenu.push({
      title: PageSubtitles.encycloBestiary,
      children: childrenBestiaries,
    });
    this._storeService.loadAllCreatures();
    this._storeService.loadLocalCreatures();*/
    // Ajout d'autres sections selon les rôles
    if (this.isAdmin || this.isMaster || this.isPlayer) {
      this.encycloMenu.push({
        title: PageSubtitles.encycloSecrets,
        children: [
          {
            title: PageSubtitles.encycloDivine,
            code: 'EncycloDivineView',
            subtitle: PageSubtitles.encycloSecrets,
            icon: 'icon-accessibility',
            image: this.imageDivine,
          },
          {
            title: PageSubtitles.encycloSocieteEveil,
            code: 'EncycloSocieteEveilView',
            subtitle: PageSubtitles.encycloSecrets,
            icon: 'icon-accessibility',
            image: this.imageDivine,
          },
          {
            title: PageSubtitles.encycloCoalition,
            code: 'EncycloCoalitionView',
            subtitle: PageSubtitles.encycloSecrets,
            icon: 'icon-accessibility',
            image: this.imageDivine,
          },
        ],
      });
    }

    // Ensuite on vérifie la route
    this._activatedRoute.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(params => {
      this.currentCode = params.section;
      if (!this.currentCode) this.moveToSection('EncycloFactionsOffView');
      else {
        this.currentSection = this._getSection(this.encycloMenu);
        if (!this.currentSection) this.moveToSection('EncycloFactionsOffView');
        else this._setPreviousNext();
      }
    });
  }

  public moveToSection(code: string) {
    this._router.navigate([], {
      queryParams: { section: code },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.currentCode = code;
    this.currentSection = this._getSection(this.encycloMenu);
    this._setPreviousNext();
  }

  private _setPreviousNext() {
    const sections = this._getListSections(this.encycloMenu, []);
    const indexCurrent = sections.findIndex(section => section.code === this.currentCode);
    const indexPrevious = indexCurrent > 0 ? indexCurrent - 1 : sections.length - 1;
    const indexNext = indexCurrent < sections.length - 1 ? indexCurrent + 1 : 0;
    this.previousSection = sections[indexPrevious];
    this.nextSection = sections[indexNext];
  }

  private _getListSections(nodes: TreeNode[], sections: TreeNode[]): TreeNode[] {
    nodes.forEach(node => {
      if (node.code) sections.push(node);
      else if (node.children?.length > 0) sections = this._getListSections(node.children, sections);
    });
    return sections;
  }

  private _getSection(nodes: TreeNode[]): TreeNode {
    let section = null;
    nodes.forEach(node => {
      if (node.code === this.currentCode) section = node;
      else if (node.children?.length > 0) {
        const tempSection = this._getSection(node.children);
        if (tempSection) section = tempSection;
      }
    });
    return section;
  }
}
