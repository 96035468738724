<h3>Description de la caractéristique</h3>
<mat-dialog-content class="mat-typography element-modal">
  <ng-container *ngIf="caract === caractDescriptionEnum.Force.Code">
    <h2>La Force</h2>
    <p [innerHtml]="caractDescriptionEnum.Force.Libelle"></p>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Habilete.Code">
    <h2>L'Habileté</h2>
    <p [innerHtml]="caractDescriptionEnum.Habilete.Libelle"></p>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Intellect.Code">
    <h2>L'Intellect</h2>
    <p [innerHtml]="caractDescriptionEnum.Intellect.Libelle"></p>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Tenacite.Code">
    <h2>La Ténacité</h2>
    <p [innerHtml]="caractDescriptionEnum.Tenacite.Libelle"></p>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Charisme.Code">
    <h2>Le Charisme</h2>
    <p [innerHtml]="caractDescriptionEnum.Charisme.Libelle"></p>
  </ng-container>

  <ng-container *ngIf="caract === caractDescriptionEnum.Magie.Code">
    <h2>La Magie</h2>
    <p [innerHtml]="caractDescriptionEnum.Magie.Libelle"></p>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
