import { ArlenorArchetype } from './ArlenorArchetype';
import { ArlenorRole } from './ArlenorRole';

export class ArlenorSpeciality {
  public name: string;
  public color: string;
  public role: ArlenorRole;
  public urlImage: string;
  public description: string;
  public explanations: boolean;
  public appearance: string;
  public archetype01: ArlenorArchetype | null;
  public archetype02: ArlenorArchetype | null;

  public get code(): string {
    let code = this.name;
    code = code.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    code = code.replace(/\s/g, '');
    return code.toUpperCase();
  }

  public get image(): string {
    return 'assets/images_filled/specialities/' + this.urlImage;
  }

  constructor(name: string, color: string, role: ArlenorRole) {
    this.name = name;
    this.color = color;
    this.role = role;
    this.urlImage = '';
    this.description = '';
    this.appearance = '';
    this.archetype01 = null;
    this.archetype02 = null;
  }

  public getImageForArchetype(isBlack = false): string {
    const [name, extension] = this.urlImage.split('.');
    const newFileName = isBlack ? `${name}-black.${extension}` : `${name}.${extension}`;
    return 'assets/images_alpha/archetypes/' + newFileName;
  }
}
