import { Component, DestroyRef, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { StoreService } from '@src/services/store.service';

@Component({
  selector: 'character-level',
  templateUrl: './character-level.component.html',
  styles: [':host {	display: contents; }'],
})
export class CharacterLevelComponent implements OnInit {
  @Output() public outConfirm = new EventEmitter();

  public form: FormGroup;
  public numeros = Array(20)
    .fill(1)
    .map((x, i) => x + i);

  private _destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {
    this.form = new FormGroup({
      numLevel: new FormControl(null, Validators.required),
    });
  }

  public ngOnInit() {
    // Init
    this.form.controls.numLevel.setValue(1);

    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this._save();
    });
  }

  public confirm() {
    this.outConfirm.emit();
  }

  private _save() {
    const newCharacter = new ArlenorCharacter();
    newCharacter.numLevel = this.form.controls.numLevel.value;
    this._storeService.changeCharacterLevel(newCharacter);
  }
}
