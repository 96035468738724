<character-template [number]="1" label="Choix de la race" [isInvalid]="!form.valid">
  <form [formGroup]="form" class="u-flexColumn">
    <div class="u-flexRow u-alignCenter">
      <mat-form-field>
        <mat-label>Race</mat-label>
        <mat-select formControlName="codeRace">
          <mat-select-trigger>{{ selectedRaceName }}</mat-select-trigger>
          <mat-option *ngFor="let race of allRaces" [value]="race.code">
            <span [class]="'u-marginRight05 ' + getDifficultyColor(race)">{{ getDifficultyLibelle(race) }}</span>
            {{ race.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-icon-button color="primary" (click)="openRacePopup()">?</button>
    </div>

    <div class="grid-list grid-2 grid-sized">
      <div *ngFor="let skill of getSkills(currentRace.code)" class="grid-element">
        <img *ngIf="skill.image" [src]="skill.image" [alt]="skill.name" />
        <div>
          <h3 class="u-marginBottom05">{{ skill.name }}</h3>
          <p [innerHtml]="skill.description"></p>
        </div>
      </div>
    </div>
  </form>
</character-template>
