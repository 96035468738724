import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorStuff, StuffTypesEnum } from '../../../models/arlenor/ArlenorStuff';

@Component({
  selector: 'stuff-modal',
  templateUrl: './stuff.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class StuffModal implements OnInit {
  public stuff: ArlenorStuff;
  public stuffTypesEnum = StuffTypesEnum;

  constructor(
    public dialogRef: MatDialogRef<StuffModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.stuff = this.data;
  }
}
