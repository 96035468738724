<h3>Description de la faction</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <button type="button" mat-icon-button (click)="previous()">
      <i class="icon-chevron-left"></i>
    </button>
    <img class="element-modal-img" [src]="faction.image" [alt]="faction.name" />
    <button type="button" mat-icon-button (click)="next()">
      <i class="icon-chevron-right"></i>
    </button>
  </div>
  <h2>{{ faction.name }}</h2>
  <p [innerHtml]="faction.description"></p>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
