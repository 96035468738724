<h3>Description de la classe</h3>
<mat-dialog-content class="mat-typography element-modal">
  <div class="u-flexRow u-alignCenter u-justifyCenter">
    <button type="button" mat-icon-button (click)="previous()">
      <i class="icon-chevron-left"></i>
    </button>
    <img class="element-modal-img" [src]="speciality.image" [alt]="speciality.name" />
    <button type="button" mat-icon-button (click)="next()">
      <i class="icon-chevron-right"></i>
    </button>
  </div>
  <h2>{{ speciality.name }}</h2>
  <p>{{ speciality.description }}</p>
  <div *ngIf="speciality.archetype01">
    <h4>Archétype : "{{ speciality.archetype01.name }}"</h4>
    <p>{{ speciality.archetype01.description }}</p>
  </div>
  <div *ngIf="speciality.archetype02">
    <h4>Archétype : "{{ speciality.archetype02.name }}"</h4>
    <p>{{ speciality.archetype02.description }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button type="button" mat-stroked-button color="primary" mat-dialog-close>Fermer</button>
</mat-dialog-actions>
