/* eslint-disable @typescript-eslint/no-explicit-any */
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

import { ArlenorPower } from '../models/arlenor/ArlenorPower';
import { ArlenorSkill, SkillTypesEnum } from '../models/arlenor/ArlenorSkill';
import { ArlenorStuff, StuffTypesEnum } from '../models/arlenor/ArlenorStuff';
import { ArlenorSpecialities } from '../models/data/ListSpecialities';

import { getEquipment, getFolderItem, getItem, getPower, getSkill } from './foundry_conversions';

export const exportSkills = (skills: ArlenorSkill[]) => {
  const folders: any[] = [];
  const zip = new JSZip();

  Object.values(SkillTypesEnum).forEach(type => {
    const newFolder = getFolderItem(type.Libelle);
    zip.file('folders_' + setNameFile(newFolder.name) + '.json', setContentFile(newFolder));
    folders.push(newFolder);
  });

  skills.forEach(skill => {
    const folder = folders.find(fld => fld.name === skill.type.Libelle);
    if (!folder) return;
    const newSkill = getSkill(skill, folder._id);
    zip.file('skill_' + setNameFile(newSkill.name) + '.json', setContentFile(newSkill));
  });

  zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'skills.zip'));
};

export const exportEquipments = (stuffs: ArlenorStuff[]) => {
  const folders: any[] = [];
  const zip = new JSZip();

  [StuffTypesEnum.WeaponShort.Libelle, StuffTypesEnum.WeaponLong.Libelle, StuffTypesEnum.Armor.Libelle, StuffTypesEnum.Accessory.Libelle].forEach(type => {
    const newFolder = getFolderItem(type);
    zip.file('folders_' + setNameFile(newFolder.name) + '.json', setContentFile(newFolder));
    folders.push(newFolder);
  });

  stuffs.forEach(stuff => {
    const folder = folders.find(fld => fld.name === stuff.type.Libelle);
    if (!folder) return;
    const newPower = getEquipment(stuff, folder._id);
    zip.file('equipment_' + setNameFile(newPower.name) + '.json', setContentFile(newPower));
  });

  zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'equipments.zip'));
};

export const exportItems = (stuffs: ArlenorStuff[]) => {
  const folders: any[] = [];
  const zip = new JSZip();

  [StuffTypesEnum.Potion.Libelle, StuffTypesEnum.Other.Libelle].forEach(type => {
    const newFolder = getFolderItem(type);
    zip.file('folders_' + setNameFile(newFolder.name) + '.json', setContentFile(newFolder));
    folders.push(newFolder);
  });

  stuffs.forEach(stuff => {
    const folder = folders.find(fld => fld.name === stuff.type.Libelle);
    if (!folder) return;
    const newItem = getItem(stuff, folder._id);
    zip.file('item_' + setNameFile(newItem.name) + '.json', setContentFile(newItem));
  });

  zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'items.zip'));
};

export const exportPowers = (powers: ArlenorPower[]) => {
  const folders: any[] = [];
  const zip = new JSZip();

  const allSpecialities = ArlenorSpecialities.getListSpecialities();
  allSpecialities.sort((a, b) => a.name.localeCompare(b.name));
  allSpecialities.forEach(spe => {
    const newFolder = getFolderItem(spe.name);
    zip.file('folders_' + setNameFile(newFolder.name) + '.json', setContentFile(newFolder));
    folders.push(newFolder);
  });

  powers.forEach(power => {
    const folder = folders.find(fld => fld.name === power.speciality.name);
    if (!folder) return;
    const newPower = getPower(power, folder._id);
    zip.file('power_' + setNameFile(newPower.name) + '.json', setContentFile(newPower));
  });

  zip.generateAsync({ type: 'blob' }).then(content => saveAs(content, 'powers.zip'));
};

const setNameFile = (name: string) => {
  return name.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};

const setContentFile = (obj: any) => {
  return JSON.stringify(obj, null, 2);
};
