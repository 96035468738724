<div class="about-view bg-global">
  <div class="layout-full-view">
    <div class="about-container">
      <div class="about-left">
        <img src="assets/images_alpha/about.png" alt="Avatar" />
      </div>
      <div class="about-right">
        <h1>À propos</h1>
        <div separator></div>
        <h2>Présentation</h2>
        <p>
          &emsp;Je m'appelle Nicholas (aka Spefire), j'ai 29 ans et je suis un ingénieur en informatique ayant une
          passion pour l'illustration et tout ce qui est graphique (ironique hein ?). Je m'entraine sérieusement depuis
          2015 en jongleant avec les fanarts, les jeux vidéo et le jeu de rôles...
        </p>
        <h3>Contact / Questions</h3>
        <p>
          Vous pouvez me contacter sur <a href="mailto:spefire@gmail.com">{{ 'spefire@gmail.com' }}</a>
        </p>
        <h2>Le Projet</h2>
        <p>
          &emsp;Le Monde d'Arlénor est né avec ma découverte du jeu de rôles en 2014. J'avais envie de contrôler le
          monde dans lequel vivrait mes joueurs et créer mes propres scénarios très rapidement. En janvier 2015, le
          Monde d'Arlénor est né. Mes premiers joueurs ont vécu une première campagne de 8 scénarios. Seulement, je
          souhaitais partager cet univers au delà du jeu de rôles... d'où ce site !
        </p>
        <p>
          &emsp;Célestia est une sorte d'extension du Monde d'Arlénor : une île céleste encore inexplorée par les
          joueurs. Je l'ai créé pour y faire vivre mes OCs (mes propres personnages) et partager leurs histoires. Un
          jour, mes joueurs les rencontreront...
        </p>
        <h2>Remerciements</h2>
        <p>
          &emsp;Merci à mes joueurs Laetitia, Jérôme, Jonathan et Sylvain, Marylloup, Tayh et Célestine qui m'ont
          accompagné lors de la création d'Arlénor. Merci à mes MJs d'autres univers qui m'ont énormément apporté en
          terme de conseils et de soutien. Et puis merci à ce qui m'ont soutenu durant ce projet et à toi de
          t'intéresser au Monde d'Arlénor !
        </p>
      </div>
    </div>
  </div>
</div>
