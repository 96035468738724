<div class="card u-marginTop1" [innerHtml]="systemDifficultiesText0A"></div>

<div class="card u-marginTop1" [innerHtml]="systemDifficultiesText0B"></div>

<div class="card u-marginTop1" [innerHtml]="systemDifficultiesText0C"></div>

<div class="u-flexColumn u-marginTop1">
  <expand-bloc label="Exemple n°1" [isOpen]="exampleChoice === 1" (outToggle)="changeExampleChoice(1)">
    <div [innerHtml]="systemDifficultiesText01"></div>
  </expand-bloc>

  <expand-bloc label="Exemple n°2" [isOpen]="exampleChoice === 2" (outToggle)="changeExampleChoice(2)">
    <div [innerHtml]="systemDifficultiesText02"></div>
  </expand-bloc>
</div>
