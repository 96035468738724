<h2 class="u-textCenter u-marginBottom2">Récapitulatif</h2>

@if (isLoading) {
  <anim-finish (outFinish)="end()"></anim-finish>
} @else {
  <div class="character-final sides">
    <div class="sides-left">
      <div class="sides-left-bloc">
        <character-resume [hasLevel]="true"></character-resume>
        <button type="button" mat-stroked-button color="primary" (click)="back()">
          <i class="icon-arrow-left"></i>
          Retour au formulaire
        </button>
        <button type="button" mat-stroked-button (click)="reset()">
          <i class="icon-arrow-left"></i>
          Retour à l'introduction
        </button>
      </div>
    </div>
    <div class="sides-right">
      <div class="u-flexColumn">
        <div class="card u-textCenter">
          <div class="character-final-back"></div>
          <img class="character-final-img" [src]="character.profil" [alt]="character.name" />
          <h2 class="u-marginTop2 u-marginBottom05">Bienvenue, {{ character.name }}</h2>
          <h3>Dans le Monde d'Arlénor</h3>
          <p class="u-marginTop1">
            Votre personnage est désormais fini ! <br />Il ne reste plus qu'à lui donner vie dans cet univers...
          </p>
        </div>

        <div class="card">
          <p class="u-textCenter">Vous pouvez désormais récupérer vos fiches personnage remplies.</p>
          <div class="u-flexRow u-flexWrap u-justifyCenter u-marginTop1">
            <button type="button" mat-stroked-button (click)="download(false)">
              Télécharger la fiche<br />(en noir et blanc)
            </button>
            <button type="button" mat-stroked-button color="primary" (click)="download(true)">
              Télécharger la fiche<br />(en couleurs)
            </button>
          </div>
        </div>

        <div class="card">
          <p class="u-textCenter">
            Vous pouvez enregistrer votre personnage <b>{{ character.name }}</b> localement sur votre navigateur !
            <br />
            Alors vous le retrouverez dans la liste avec les autres personnages.
          </p>
          <div *ngIf="character.numLevel === 1" class="u-flexRow u-flexWrap u-justifyCenter u-marginTop1">
            <button type="button" mat-stroked-button [disabled]="isSaved" (click)="openSavePopup()">
              <span *ngIf="!isSaved">Enregistrer le personnage</span>
              <span *ngIf="isSaved">Enregistrement effectué !</span>
            </button>
            <button
              type="button"
              mat-stroked-button
              *ngIf="isAdmin"
              [disabled]="isSavedBDD"
              (click)="openSavePopupBDD()"
            >
              <span *ngIf="!isSavedBDD">Enregistrer le personnage en BDD</span>
              <span *ngIf="isSavedBDD">Enregistrement en BDD effectué !</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
}
