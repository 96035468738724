<div class="races-view bg-universe">
  <head-layout [headTitle]="title" [imageLeft]="imageLeft" [imageRight]="imageRight">
    <div class="card" [innerHTML]="resume"></div>
  </head-layout>

  <div separator></div>

  <div class="layout-bloc-view">
    <div class="races-imgs">
      <button type="button" mat-icon-button (click)="previousSelection()">
        <i class="icon-chevron-left"></i>
      </button>
      <img (click)="previousSelection()" class="races-img" [src]="previousImage" alt="Race précédente" />
      <img class="races-img main" [src]="currentImage" alt="Race actuelle" />
      <img (click)="nextSelection()" class="races-img" [src]="nextImage" alt="Race suivante" />
      <button type="button" mat-icon-button (click)="nextSelection()">
        <i class="icon-chevron-right"></i>
      </button>
    </div>
    <div *ngIf="currentRace" class="races-description card">
      <h2 class="u-marginBottom05">{{ currentRace.name }}</h2>
      <p class="u-textItalic u-marginBottom1">
        {{ currentRace.infoAge }}
        <br />
        Apparence : {{ currentRace.infoAppareance }}
      </p>
      <p class="u-marginBottom1" [innerHtml]="currentRace.description"></p>
      <div class="section-moreinfos">
        <p comments>
          <span class="u-textBold">{{ currentRace.ratioWorld }}%</span>
          du monde sont des {{ currentRace.name.toLowerCase() }}s
        </p>
        <p comments>
          <span>Localisations</span>
          <br />
          <span *ngFor="let location of currentRace.locations"> - {{ location }}<br /> </span>
        </p>
        <p comments>
          <span class="u-textBold">{{ currentRace.ratioMagic }}%</span>
          des {{ currentRace.name.toLowerCase() }}s peuvent utiliser la magie
        </p>
      </div>
    </div>
    <div class="grid-list grid-2 u-marginTop1 u-width100">
      <div *ngFor="let skill of getSkillsByRace(currentRace.code)" class="grid-element">
        <img *ngIf="skill.image" [src]="skill.image" [alt]="skill.name" />
        <div>
          <h3 class="u-marginBottom05">{{ skill.name }}</h3>
          <p [innerHtml]="skill.description"></p>
        </div>
      </div>
    </div>
  </div>
</div>
