import { Component, EventEmitter, Output } from '@angular/core';

import { convertMarkdownToHTML } from '@src/utils/conversions';
import downloads_characters from '@src/utils/downloads_characters';

@Component({
  selector: 'character-intro-view',
  templateUrl: './character-intro.view.html',
  styleUrls: ['./character-intro.view.scss'],
})
export class CharacterIntroView {
  @Output() public outCreate = new EventEmitter();
  @Output() public outSelect = new EventEmitter();

  public characterCreateText = characterCreateText;
  public characterSelectText = characterSelectText;

  public startCreate() {
    this.outCreate.emit();
  }

  public startSelect() {
    this.outSelect.emit();
  }

  public async download(isColored: boolean) {
    downloads_characters.downloadPDFVide(isColored);
  }
}

export const characterCreateText = convertMarkdownToHTML(`
  Un personnage est défini par plusieurs choses : on commence par **ses caractéristiques**,
  **sa race**, **ses compétences principales**, **ses pouvoirs**... pour ensuite finir par **son identité** et quelques finitions.`);

export const characterSelectText = convertMarkdownToHTML(`
  Des **personnages pré-tirés** vous seront présentés : vous pourrez les garder tels quels ou les modifier. Cependant
  c'est **VOUS** qui le ferez vivre via **ses actions**... on choisit ce que l'on est par nos actes !`);
