import { Component, OnInit } from '@angular/core';

import { ArlenorDivinity } from '../../../models/arlenor/ArlenorDivinity';
import { getListAngels, getListDemons } from '../../../models/data/ListDivinities';
import { PageTitles } from '../../../models/PagesTitles';
import { convertMarkdownToHTML } from '../../../utils/conversions';

@Component({
  selector: 'religion-view',
  templateUrl: './religion.view.html',
  styleUrls: ['./religion.view.scss'],
})
export class ReligionView implements OnInit {
  public resume = arlenorResume;

  public allAngels: ArlenorDivinity[];
  public allDemons: ArlenorDivinity[];
  public currentDivinity: ArlenorDivinity;

  public title = PageTitles.religion;
  public imageLeft = 'assets/images_alpha/religion/angel.png';
  public imageRight = 'assets/images_alpha/religion/demon.png';

  public ngOnInit() {
    this.allAngels = getListAngels();
    this.allDemons = getListDemons();
    this.currentDivinity = this.allAngels[0];
  }

  public changeSelection(divinity: ArlenorDivinity) {
    this.currentDivinity = divinity;
  }
}

export const arlenorResume = convertMarkdownToHTML(`
  Avant, il n'existait Rien. Seul le **Rien** était présent.
  Il se refermait sur lui-même, en boule, en boucle...
  Un jour, pour alors le combler, le **Tout** exista.
  
  Le Tout et le Rien s'entremêlèrent ensuite pour former une nouvelle entité
  se suffisant à iel-même : **Arlénor, la Divinité de l'Existence**.
  Arlénor voyant le vaste univers du Tout et du Rien, se décida à le remplir de mondes.
  C'est alors qu'iel créa son tout premier monde, nommé aujourd'hui : Le Monde d'Arlénor.
  
  Lors de la **Création**, Arlénor donna naissance à dix Anges et dix Démons
  afin de l'aider dans sa quête : ils avaient pour but de concevoir certains éléments du Monde tout en veillant sur son
  **Équilibre**. Chaque entité devint ainsi maître dans leur domaine respectif.
  
  Une fois la Création terminée (dont la naissance des humains et d'autres créatures...),
  Arlénor rappela ses anges et démons auprès d'iel, en vue de réaliser d'autres mondes.`);
