import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, DestroyRef, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { debounceTime } from 'rxjs';

import { TreeNode } from '../../models/TreeNode';

@Component({
  selector: 'tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.scss'],
})
export class TreeMenuComponent implements OnInit, OnChanges {
  @Input() public hasSearch: boolean;
  @Input() public currentCode: string;
  @Input() public menu: TreeNode[];
  @Output() public outChangeCode = new EventEmitter<string>();

  public form: FormGroup;
  public currentMenu: TreeNode[] = [];
  public treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<TreeNode>();

  private _destroyRef = inject(DestroyRef);

  constructor() {
    this.form = new FormGroup({
      search: new FormControl(null),
    });
  }

  public ngOnInit() {
    this.currentMenu = this.menu;
    this._refresh();

    if (this.hasSearch) {
      this.form.controls.search.valueChanges.pipe(debounceTime(500), takeUntilDestroyed(this._destroyRef)).subscribe(value => {
        if (value) this.currentMenu = this._searchNode(this.menu);
        else this.currentMenu = this.menu;
        this._refresh();
      });
    }
  }

  public ngOnChanges() {
    this._refresh();
  }

  public hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  public selectNode(node: TreeNode) {
    this.outChangeCode.emit(node.code);
  }

  public reset() {
    this.form.controls.search.setValue(null);
  }

  private _refresh() {
    this.dataSource.data = this.currentMenu;
    this._expandNode(this.currentMenu);
  }

  private _searchNode(nodes: TreeNode[]): TreeNode[] {
    let theNode: TreeNode[] = [];
    nodes.forEach(node => {
      if (this._checkNode(node)) theNode.push(node);
      else if (node.children?.length > 0) {
        const tempNode = this._searchNode(node.children);
        theNode = theNode.concat(tempNode);
      }
    });
    return theNode;
  }

  private _expandNode(nodes: TreeNode[]): TreeNode {
    let theNode = null;
    nodes.forEach(node => {
      if (node.code === this.currentCode) {
        theNode = node;
        this.treeControl.expand(node);
      } else if (node.children?.length > 0) {
        const tempNode = this._expandNode(node.children);
        if (tempNode) {
          theNode = tempNode;
          this.treeControl.expand(node);
        }
      }
    });
    return theNode;
  }

  private _checkNode(node: TreeNode) {
    // Convertir la chaîne de recherche en minuscules pour une recherche insensible à la casse
    const lowercaseSearch = this.form.controls.search.value.toLowerCase();

    // Vérifier si le titre contient la chaîne de recherche
    if (node.title && node.title.toLowerCase().includes(lowercaseSearch)) return true;

    // Vérifier si sous-titre contient la chaîne de recherche
    if (node.subtitle && node.subtitle.toLowerCase().includes(lowercaseSearch)) return true;

    // Vérifier si l'un des tags contient la chaîne de recherche
    if (node.tags && node.tags.some(tag => tag.toLowerCase().includes(lowercaseSearch))) return true;

    // Aucune correspondance trouvée
    return false;
  }
}
