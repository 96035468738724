import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ArlenorBestiary } from '../../../../models/arlenor/ArlenorBestiary';
import { ArlenorCreature } from '../../../../models/arlenor/ArlenorCreature';
import { getListBestiaries } from '../../../../models/data/ListBestiaries';
import { convertMarkdownToHTML } from '../../../../utils/conversions';

@Component({
  selector: 'encyclo-bestiary-view',
  templateUrl: './encyclo-bestiary.view.html',
  styleUrls: ['./encyclo-bestiary.view.scss'],
})
export class EncycloBestiaryView implements OnInit, OnChanges {
  @Input() public indexBestiary: number;
  @Input() public allCreatures: ArlenorCreature[];

  public etoilesPrimordialesText = etoilesPrimordialesText;
  public bestiary: ArlenorBestiary;
  public creatures: ArlenorCreature[];

  public ngOnInit() {
    this._refresh();
  }

  public ngOnChanges() {
    this._refresh();
  }

  private _refresh() {
    const allBestiary = getListBestiaries();
    this.bestiary = allBestiary[this.indexBestiary];
    this.creatures = this.allCreatures.filter(creature => creature.codeBestiary === this.bestiary.code);
  }
}

export const etoilesPrimordialesText = convertMarkdownToHTML(`
**Le cas des étoiles primordiales**

Les étoiles primordiales sont les premiers esprits ayant vécu dans le Monde d'Arlénor, transformés en étoiles à leur mort.

Les esprits ne sont pas tous puissants, leur étoile brille d'un éclat différent selon leur "grade"...
- Étoile fine (Simple).
- Étoile de bronze (Standard).
- Étoile d'argent (Complexe).
- Étoile d'or (Épique).
- Étoile diamant (Légendaire).

Les esprits ont été regroupés en 5 constellations : en fonction de leur personnalité et de leurs aptitudes...
- Les Constellations de la Guerre (Offensive).
- Les Constellations de la Garde (Défensive).
- Les Constellations de la Restauration (Soin).
- Les Constellations de l'Influence (Contrôle).
- Les Constellations du Service (Utilitaire).`);
