<div class="roleplay-view">
  <div class="roleplay-section bg-roleplay bg-block glyphe-hover">
    <div class="title-container">
      <h1 class="u-marginBottom2">{{ pages.adventure }}</h1>
      <h2 class="u-marginBottom05">1. La Carte du Monde,<br />et ses habitants</h2>
      <p class="u-marginBottom1">
        Avant de créer son personnage, ou même de lire les règles du jeu de rôles, découvrez les différents territoires,
        la religion dominante, la magie omni-présente, les peuples y vivant et les conflits y règnant...
      </p>
      <arrow-button linkName="Voir le Monde" linkPage="/univers"></arrow-button>
    </div>
    <div class="glyphe-container">
      <glyphe icon="map"></glyphe>
    </div>
  </div>
  <div separator></div>
  <div class="roleplay-section bg-roleplay-reverse bg-block bg-black inversed-section glyphe-hover">
    <div class="glyphe-container">
      <glyphe class="little" icon="book"></glyphe>
    </div>
    <div class="title-container">
      <h2 class="u-marginBottom05">2. Les règles du jeu</h2>
      <p class="u-marginBottom1">
        Trouvez ici tous les éléments permettant de comprendre la création de personnage en profondeur ainsi que le
        système de jeu et des propositions de pouvoirs.
      </p>
      <div class="u-flexColumn u-alignEnd u-alignCenter-on-mobile">
        <arrow-button linkName="Comprendre le système" linkPage="/jeu-de-roles/systeme"> </arrow-button>
        <arrow-button linkName="Voir les cristaux évolutifs" linkPage="/jeu-de-roles/cristaux"> </arrow-button>
      </div>
    </div>
  </div>
  <div separator></div>
  <div class="roleplay-section bg-roleplay bg-block glyphe-hover">
    <div class="title-container">
      <h2 class="u-marginBottom05">3. La création de personnage</h2>
      <p class="u-marginBottom1">
        Créez votre personnage, des caractéristiques aux compétences, en passant par leurs pouvoirs... Accédez dès
        maintenant à la naissance de votre personnage !
      </p>
      <div class="u-flexColumn u-alignStart u-alignCenter-on-mobile">
        <arrow-button linkName="Passer à la création !" linkPage="/jeu-de-roles/personnage"> </arrow-button>
        <button type="button" mat-stroked-button color="primary" (click)="download(false)">
          Télécharger la fiche vierge (imprimable)
        </button>
        <button type="button" mat-stroked-button color="primary" (click)="download(true)">
          Télécharger la fiche vierge (colorée)
        </button>
      </div>
    </div>
    <div class="glyphe-container">
      <glyphe icon="perso"></glyphe>
    </div>
  </div>
  <div separator></div>
  <div class="roleplay-section bg-roleplay-reverse bg-block bg-black inversed-section">
    <div class="glyphe-container">
      <div class="glyphe-view">
        <img class="glyphe-logo" src="assets/images_alpha/fvtt.png" alt="Logo Foundry VTT" />
      </div>
    </div>
    <div class="title-container">
      <h2 class="u-marginBottom05">Foundry Virtual Tabletop</h2>
      <p class="u-marginBottom1">Trouvez ici le système de jeu du Monde d'Arlénor, pour y jouer sur Foundry VTT.</p>
      <div class="u-flexColumn u-alignEnd u-alignCenter-on-mobile">
        <arrow-button linkName="Aller sur Foundry VTT" linkUrl="https://foundryvtt.com/packages/arlenor">
        </arrow-button>
        <button *ngIf="isAdmin" type="button" mat-stroked-button color="primary" (click)="downloadFoundryDB()">
          Télécharger les données de FoundryVTT
        </button>
      </div>
    </div>
  </div>
</div>
