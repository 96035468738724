import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';

import { NgParticlesService } from '@tsparticles/angular';
import { IOptions, RecursivePartial, tsParticles } from '@tsparticles/engine';
import { loadSlim } from '@tsparticles/slim';

import { debounceTime, fromEvent } from 'rxjs';
import { PageTitles } from 'src/app/models/PagesTitles';

@Component({
  selector: 'home-view',
  templateUrl: './home.view.html',
  styleUrls: ['./home.view.scss'],
})
export class HomeView implements OnInit {
  public pages = PageTitles;
  public selection = 0;
  public particlesOptions = particlesOptions;
  private _timeSub: number = null;
  private _destroyRef = inject(DestroyRef);

  constructor(
    private _title: Title,
    private _ngParticlesService: NgParticlesService
  ) {
    this._title.setTitle(PageTitles.home);
  }

  public ngOnInit() {
    this._launchTimeout();

    fromEvent<WheelEvent>(window, 'wheel')
      .pipe(takeUntilDestroyed(this._destroyRef))
      .pipe(debounceTime(200))
      .subscribe(event => {
        if (event.deltaY > 0) this.changeSelection(1);
        else this.changeSelection(-1);
      });

    tsParticles.load({ id: 'tsparticles' });
    this._ngParticlesService.init(async engine => {
      await loadSlim(engine);
    });
  }

  public changeSelection(increment = 1): void {
    this.selection = this.selection + increment;
    if (this.selection === -1) this.selection = 2;
    else if (this.selection === 3) this.selection = 0;
    this._launchTimeout();
  }

  public setSelection(newSelection: number): void {
    this.selection = newSelection;
    this._launchTimeout();
  }

  private _launchTimeout(): void {
    if (this._timeSub) window.clearTimeout(this._timeSub);
    this._timeSub = window.setTimeout(() => {
      this.changeSelection();
    }, 10000);
  }
}

const particlesOptions: RecursivePartial<IOptions> = {
  particles: {
    number: {
      value: 50,
      density: { enable: true },
    },
    color: {
      value: '#f1e179',
    },
    shape: {
      type: 'circle',
    },
    opacity: {
      value: 0.5,
      animation: { enable: false },
    },
    size: {
      value: 2,
      animation: { enable: false },
    },
    line_linked: { enable: false },
    move: {
      enable: true,
      speed: 10,
      direction: 'top-right',
      random: true,
      straight: false,
      outModes: 'out',
      attract: { enable: false },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onHover: {
        enable: false,
        mode: 'bubble',
      },
      onClick: {
        enable: false,
        mode: 'repulse',
      },
      resize: { enable: false },
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 0.5,
        },
      },
      bubble: {
        distance: 400,
        size: 4,
        duration: 0.3,
        opacity: 1,
        speed: 3,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};
