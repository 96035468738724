import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
  @Input() public text: string;
  @Input() public value: boolean;
  @Output() public outChange = new EventEmitter<boolean>();

  public submit(event: Event) {
    const target = <HTMLInputElement>event.target;
    this.outChange.emit(target.checked);
  }
}
