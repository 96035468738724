import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ArlenorPower, PowerRanksEnum } from '../../../models/arlenor/ArlenorPower';

@Component({
  selector: 'power-modal',
  templateUrl: './power.modal.html',
  styleUrls: ['../element.modal.scss'],
})
export class PowerModal implements OnInit {
  public power: ArlenorPower;
  public powerRanksEnum = PowerRanksEnum;

  constructor(
    public dialogRef: MatDialogRef<PowerModal>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public ngOnInit(): void {
    this.power = this.data;
  }
}
