import { ArlenorFaction } from '../arlenor/ArlenorFaction';

export function getListFactions(): ArlenorFaction[] {
  const factions: ArlenorFaction[] = [];

  factions.push(
    new ArlenorFaction(
      true,
      'warriors_ld.webp',
      'La Guilde de la Vaillance',
      `La Guilde de la Vaillance est réputée pour son courage inébranlable sur le champ de bataille.
    Ses membres sont des héros intrépides, prêts à défendre l'honneur et la justice à tout prix.
    Ils épaulent l'armée faradélienne et interviennent en soutien dans la sécurité des villages.`,
      ['Courage', 'Honneur', 'Détermination'],
      'Faradel, Quartier des armées'
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'mages_ld.webp',
      `L'Alliance Arcanique`,
      `L'Alliance Arcanique rassemble les mages les plus puissants et les plus érudits.
    Ses membres sont des chercheurs de la connaissance magique, utilisant leurs pouvoirs pour comprendre les secrets du monde,
    le protéger contre les éléments déchaînés, et proposer des lois sur la magie.`,
      ['Sagesse', 'Connaissance', 'Puissance'],
      'Faradel, Tour du Savoir'
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'druids_ld.webp',
      'Le Cercle Floral du Sud',
      `Le Cercle Floral du Sud est étroitement lié à la nature et à l'équilibre écologique.
    Ses membres sont des gardiens de la flore, utilisant la magie naturelle pour protéger le monde.
    L'un de leur rêve est de repeindre le désert de Jirakan par de la verdure.`,
      ['Harmonie', 'Respect de la Nature', 'Equilibre'],
      'Plaines de Terfil, au Sud'
    )
  );
  factions.push(
    new ArlenorFaction(
      false,
      'assassins_ld.webp',
      'Le Syndicat des Ombres',
      `Le Syndicat des Ombres est une guilde secrète composée d'assassins, de voleurs et d'espions.
    Ses membres opèrent dans l'ombre, accomplissant des contrats, des missions clandestines
    pour maintenir l'équilibre des pouvoirs, ou le renverser.`,
      ['Discrétion', 'Adaptabilité', 'Subtilité'],
      'Inconnue'
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'priests_ld.webp',
      'La Chorale Céleste',
      `La Chorale Céleste est une guilde de mages dévoués aux divinités et à l'inspiration divine.
    Ses membres utilisent la musique et la spiritualité pour apporter l'espoir et la guérison à la population,
    ou soutenir d'autres aventuriers dans leurs quêtes.`,
      ['Foi', 'Harmonie', 'Inspiration'],
      `Faradel, Cathédrale d'Arlénor`
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'legends_ld.webp',
      `L'École des Légendes`,
      `L'École des Légendes est institution prestigieuse dédiée à former la prochaine génération de héros et d'aventuriers.
    Sous la tutelle de mentors expérimentés, les aspirants héros de l'École des Légendes se préparent à écrire
    leur propre destinée et à laisser leur marque sur l'histoire.`,
      ['Entraide', 'Courage', 'Détermination'],
      `Plaines de Terfil, au Sud`
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'circus_ld.webp',
      `La Troupe du Cirque`,
      `La Troupe du Cirque est une faction plutôt mobile, regroupant les personnes peu ordinaires, des enchanteurs, voire
    même des mutants (exploités ou non)... afin de divertir le peuple. Pour certains des membres, leur but est d'être populaire,
    et pour d'autres c'est le fait d'apporter de la joie dans le monde.`,
      ['Popularité', 'Extraordinaire', 'Diversité'],
      'Faradel, Bordure extérieure de la ville'
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'engineers_ld.webp',
      'La Guilde Mécanique',
      `La Guilde Mécanique est composée d'ingénieurs et d'inventeurs talentueux.
    Ses membres maîtrisent la technologie et la magie mécanique pour créer des machines puissantes, des armes avancées ou des aides au quotidien.`,
      ['Innovation', 'Précision', 'Progression'],
      'Faradel, Quartier populaire'
    )
  );
  factions.push(
    new ArlenorFaction(
      false,
      'merchants_ld.webp',
      'Le Consortium des Marchands',
      `Le Consortium des Marchands est une guilde de négociants et de marchands prospères.
    Ses membres contrôlent les routes commerciales, gèrent parfois le transfert d'esclaves
    et utilisent leur influence économique pour façonner le monde.`,
      ['Commerce', 'Richesse', 'Opportunité'],
      'Jirakan, Haut-quartiers'
    )
  );
  factions.push(
    new ArlenorFaction(
      false,
      'zoo_ld.webp',
      `Le Zoo Interdit`,
      `Le Zoo Interdit est un groupe de collectionneurs. Ils cherchent à collectionner toutes les créatures, les animaux et végétaux
    du monde, voire même des mutants pour un intérêt financier. L'emplacement de ce zoo reste indéfini / change au cours du temps,
    et sa visite est alors coûteuse.`,
      ['Collection', 'Rareté', 'Diversité'],
      'Inconnue'
    )
  );
  factions.push(
    new ArlenorFaction(
      false,
      'chaos_ld.webp',
      `Les Disciples du Chaos`,
      `Les Disciples du Chaos s'oppose à l'Équilibre institué par la religion d'Arlénor.
    Ils cherchent à semer le désordre et la discorde dans la société, en orchestrant des actes de sabotage,
    en propageant la peur et en vénérant des forces destructrices afin de renverser des structures sociales existantes.`,
      ['Désordre', 'Anarchie', 'Terrorisme'],
      'Partout dans le monde'
    )
  );
  factions.push(
    new ArlenorFaction(
      false,
      'darkmages_ld.webp',
      `L'Ordre Mystique`,
      `L'Ordre Mystique est une guilde de mystiques et d'érudits des arts occultes comme la nécromancie.
    Ses membres cherchent la vérité et le pouvoir dans les mystères de la magie et de l'au-delà,
    utilisant leurs connaissances pour explorer l'inconnu et l'interdit.`,
      ['Sagesse', 'Exploration', 'Mystère'],
      'Inconnue'
    )
  );
  factions.push(
    new ArlenorFaction(
      true,
      'tamers_ld.webp',
      'Les Gardiens de la Faune',
      `Les Gardiens de la Faune sont spécialisés dans le recueil, le soin et le dressage des créatures sauvages.
    Ses membres sont des explorateurs et des dompteurs exceptionnels, garantissant une vie saine à leurs animaux.
    Leur mission est aussi de comptabiliser les différentes créatures du monde.`,
      ['Harmonie', 'Soin', 'Liberté'],
      `Plaines de Terfil, à l'Est`
    )
  );
  factions.push(
    new ArlenorFaction(
      false,
      'revolt_ld.webp',
      `L'Insurrection Égalitaire`,
      `L'Insurrection Égalitaire est un groupe composée de Mutants principalement. Il ne s'agit pas d'une guilde officielle,
    mais ses partisants ont pour mission de se rebeller contre la domination des elfes et des humains,
    cherchant à rétablir un équilibre entre les peuples.`,
      ['Liberté', 'Colère', 'Solidarité'],
      'Faradel, Sous-sols de la ville'
    )
  );

  return factions;
}
