<div class="card u-marginTop1" [innerHtml]="systemStoryText"></div>
<div class="u-flexColumn u-marginTop1">
  <expand-bloc
    label="Avatar et description physique"
    [isOpen]="finitionChoice === 1"
    (outToggle)="changeFinitionChoice(1)"
  >
    <div [innerHtml]="systemStoryText01"></div>
  </expand-bloc>

  <expand-bloc
    label="Trait de caractère, idéologies, croyances..."
    [isOpen]="finitionChoice === 2"
    (outToggle)="changeFinitionChoice(2)"
  >
    <div [innerHtml]="systemStoryText02"></div>
  </expand-bloc>

  <expand-bloc label="Histoire personnelle" [isOpen]="finitionChoice === 3" (outToggle)="changeFinitionChoice(3)">
    <div [innerHtml]="systemStoryText03"></div>
  </expand-bloc>
</div>
