<character-template
  [number]="3"
  [label]="indexCrystal === 0 ? 'Choix de la classe principale' : 'Choix de la classe secondaire'"
  [isInvalid]="!form.valid"
>
  <form [formGroup]="form" class="u-flexColumn">
    <div class="u-flexRow u-alignCenter">
      <mat-form-field>
        <mat-label>Classe</mat-label>
        <mat-select formControlName="codeSpeciality">
          <mat-select-trigger>{{ selectedSpeName }}</mat-select-trigger>
          <mat-option *ngFor="let spe of allSpecialities" [value]="spe.code" [disabled]="!checkSpe(spe)">
            <span [class]="'status-' + spe.color">
              <span class="u-marginRight05" [class]="spe.role.icon"></span>
              <span>{{ spe.role.name }}</span>
            </span>
            {{ spe.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button" mat-icon-button color="primary" (click)="openSpePopup()">?</button>
    </div>

    <div *ngIf="!selectedSpeciality" class="card is-warning">
      Choisissez une classe avant de sélectionner des pouvoirs.
    </div>

    <div *ngIf="selectedSpeciality && !form.controls.isNbPowersValid.value" class="card is-warning u-flexColumn u-gap0">
      <span *ngIf="maxRankI && nbRankI < maxRankI">
        Pouvoirs de rang I restants à sélectionner : {{ maxRankI - nbRankI }}
      </span>
      <span *ngIf="maxRankII && nbRankII < maxRankII">
        Pouvoirs de rang II restants à sélectionner : {{ maxRankII - nbRankII }}
      </span>
      <span *ngIf="maxRankIII && nbRankIII < maxRankIII">
        Pouvoirs de rang III restants à sélectionner : {{ maxRankIII - nbRankIII }}
      </span>
      <span *ngIf="maxRankX && nbRankX < maxRankX">
        Pouvoirs de rang X restants à sélectionner : {{ maxRankX - nbRankX }}
      </span>
    </div>

    <div *ngIf="selectedSpeciality && form.controls.isNbPowersValid.value" class="card">
      Tous les pouvoirs ont été sélectionnés.
    </div>

    <powers-selection
      *ngIf="selectedSpeciality"
      [indexCrystal]="indexCrystal"
      [level]="character.level"
      [idsPowers]="idsPowers"
      [powers]="filteredPowers"
      (outAdd)="addPower($event)"
      (outRemove)="removePower($event)"
    >
    </powers-selection>
  </form>
</character-template>
