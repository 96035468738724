<div class="card u-marginTop1" [innerHtml]="systemCaractsText00"></div>

<div class="card u-marginTop1" [innerHtml]="systemCaractsText01"></div>

<div class="u-flexColumn u-marginTop1">
  <expand-bloc label="La Force" [isOpen]="caractChoice === 1" (outToggle)="changeCaractChoice(1)">
    <div [innerHtml]="caractDescriptionEnum.Force.Libelle"></div>
  </expand-bloc>

  <expand-bloc label="L'Habileté" [isOpen]="caractChoice === 2" (outToggle)="changeCaractChoice(2)">
    <div [innerHtml]="caractDescriptionEnum.Habilete.Libelle"></div>
  </expand-bloc>

  <expand-bloc label="L'Intellect" [isOpen]="caractChoice === 3" (outToggle)="changeCaractChoice(3)">
    <div [innerHtml]="caractDescriptionEnum.Intellect.Libelle"></div>
  </expand-bloc>

  <expand-bloc label="La Ténacité" [isOpen]="caractChoice === 4" (outToggle)="changeCaractChoice(4)">
    <div [innerHtml]="caractDescriptionEnum.Tenacite.Libelle"></div>
  </expand-bloc>

  <expand-bloc label="Le Charisme" [isOpen]="caractChoice === 5" (outToggle)="changeCaractChoice(5)">
    <div [innerHtml]="caractDescriptionEnum.Charisme.Libelle"></div>
  </expand-bloc>

  <expand-bloc label="La Magie" [isOpen]="caractChoice === 6" (outToggle)="changeCaractChoice(6)">
    <div [innerHtml]="caractDescriptionEnum.Magie.Libelle"></div>
  </expand-bloc>
</div>

<div class="card u-marginTop1" [innerHtml]="systemCaractsText02"></div>
