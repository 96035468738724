import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmationModal } from '@src/components/modals/confirmation/confirmation.modal';
import { InformationModal } from '@src/components/modals/information/information.modal';
import { ArlenorCharacter } from '@src/models/arlenor/ArlenorCharacter';
import { ArlenorPower } from '@src/models/arlenor/ArlenorPower';
import { ArlenorSkill } from '@src/models/arlenor/ArlenorSkill';
import { StoreService } from '@src/services/store.service';
import downloads_characters from '@src/utils/downloads_characters';
import random from '@src/utils/random';
import supabase_api from '@src/utils/supabase_api';

@Component({
  selector: 'character-final-view',
  templateUrl: './character-final.view.html',
  styleUrls: ['./character-final.view.scss'],
})
export class CharacterFinalView implements OnInit {
  @Output() public outBack = new EventEmitter();
  @Output() public outReset = new EventEmitter();

  public character: ArlenorCharacter;
  public isLoading: boolean;
  public isSaved: boolean;
  public isSavedBDD: boolean;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  public get allSkills(): ArlenorSkill[] {
    return this._storeService.$allSkills.value || [];
  }

  public get allPowers(): ArlenorPower[] {
    return this._storeService.$allPowers.value || [];
  }

  constructor(
    public dialog: MatDialog,
    private _storeService: StoreService
  ) {}

  public ngOnInit() {
    this.character = this._storeService.character;
    this.isLoading = true;
    this.isSaved = false;
    this.isSavedBDD = false;
  }

  public end() {
    this.isLoading = false;
  }

  public back() {
    if (!this.isSaved) {
      const dialogRef = this.dialog.open(ConfirmationModal, {
        data: {
          title: `Changement de page`,
          content: `Vous allez quitter la page : les modifications ne seront pas enregistrées.`,
        },
      });

      dialogRef.afterClosed().subscribe((value: boolean) => {
        if (value) this.outBack.emit();
      });
    } else this.outBack.emit();
  }

  public reset() {
    this.outReset.emit();
  }

  public async download(isColored: boolean) {
    downloads_characters.downloadPDF(isColored, this.character, this.allSkills, this.allPowers);
  }

  public openSavePopup() {
    const character = this.character;
    character.initTime();
    character.isBDD = false;
    this._storeService.saveLocalCharacter(character);
    this.isSaved = true;

    const dialogRef = this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde du personnage`,
        content:
          `Votre personnage <b>` +
          character.name +
          ` - Niveau ` +
          character.numLevel +
          `</b> est désormais sauvegardé sur votre navigateur.
        <br><br>
        Note : En effaçant l'historique de votre navigateur, vos personnages sauvegardés seront effacés.`,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  public async openSavePopupBDD() {
    const character = this.character;
    character.initTime();
    character.isBDD = true;
    character.guid = random.generateID(20);
    await supabase_api.postCharacter(character);
    this.isSavedBDD = true;

    const dialogRef = this.dialog.open(InformationModal, {
      data: {
        title: `Sauvegarde du personnage`,
        content:
          `Votre personnage
        <b>` +
          character.name +
          ` - Niveau ` +
          character.numLevel +
          `</b> est désormais sauvegardé en base de données.`,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }
}
