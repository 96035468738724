<div class="meiker">
  <div class="meiker-left">
    <div class="meiker-options-container card">
      <mat-tab-group class="meiker-categories is-header" animationDuration="0ms">
        <mat-tab *ngFor="let category of categories" label="{{ category.libelle }}">
          <div *ngFor="let option of category.options; let isFirst = first">
            <h3 [class.meiker-option-separator]="!isFirst">
              {{ option.libelle }}
            </h3>
            <div *ngIf="option.names?.length" class="meiker-names">
              <div
                *ngFor="let name of option.names"
                class="meiker-name"
                [class.is-selected]="option.name === name"
                (click)="changeName(option, name)"
              >
                <img [src]="getIconUrl(name)" aria-hidden="true" />
              </div>
            </div>
            <div *ngIf="option.colors?.length" class="meiker-colors">
              <div
                *ngFor="let color of option.colors"
                class="meiker-color"
                [class.is-selected]="option.color === color"
                [style.background-color]="color"
                (click)="changeColor(option, color)"
              ></div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="meiker-right">
    <div class="meiker-result">
      <div class="meiker-result-container card">
        <div class="meiker-canvas" [style.height]="height + 'px'" [style.width]="width + 'px'" id="capture">
          <canvas
            *ngFor="let item of items; let index = index"
            class="meiker-canva"
            [height]="height"
            [width]="width"
            id="canvas-{{ assetsName }}-{{ index }}"
          >
          </canvas>
        </div>
      </div>
      <div class="meiker-result-actions card">
        <button type="button" mat-stroked-button color="warn" (click)="reset()">Réinitialiser</button>
        <button type="button" mat-stroked-button color="primary" (click)="export()">Télécharger</button>
      </div>
    </div>
  </div>
</div>
