<div class="encyclo-factions-view">
  <div *ngIf="isOff" class="grid-list grid-2">
    <div *ngFor="let faction of factionsOfficielles" class="faction-item grid-element">
      <img *ngIf="faction.image" class="faction-image" [src]="faction.image" [alt]="faction.name" />
      <div>
        <h3 class="u-marginBottom05">{{ faction.name }}</h3>
        <p>{{ faction.description }}</p>
        <p class="u-marginTop05" comments>
          {{ getValues(faction.values) }}
          <br />
          Position de la base : {{ faction.location }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!isOff" class="grid-list grid-2">
    <div *ngFor="let faction of factionsNonOfficielles" class="faction-item grid-element">
      <img *ngIf="faction.image" class="faction-image" [src]="faction.image" [alt]="faction.name" />
      <div>
        <h3 class="u-marginBottom05">{{ faction.name }}</h3>
        <p>{{ faction.description }}</p>
        <p class="u-marginTop05" comments>
          {{ getValues(faction.values) }}
          <br />
          Position de la base : {{ faction.location }}
        </p>
      </div>
    </div>
  </div>
</div>
