import { Component, OnInit } from '@angular/core';

import { PageTitles } from '@src/models/PagesTitles';

import { StoreService } from '../../services/store.service';
import downloads_characters from '../../utils/downloads_characters';
import { exportEquipments, exportItems, exportPowers, exportSkills } from '../../utils/foundry_exports';

@Component({
  selector: 'roleplay-view',
  templateUrl: './roleplay.view.html',
  styleUrls: ['./roleplay.view.scss'],
})
export class RoleplayView implements OnInit {
  public pages = PageTitles;

  public get isAdmin(): boolean {
    return this._storeService.isAdmin;
  }

  constructor(private _storeService: StoreService) {}

  public ngOnInit() {
    if (this.isAdmin) {
      this._storeService.loadAllSkills();
      this._storeService.loadAllStuffs();
      this._storeService.loadAllPowers();
    }
  }

  public async download(isColored: boolean) {
    downloads_characters.downloadPDFVide(isColored);
  }

  public async downloadFoundryDB() {
    exportSkills(this._storeService.$allSkills.value);
    exportEquipments(this._storeService.$allStuffs.value);
    exportItems(this._storeService.$allStuffs.value);
    exportPowers(this._storeService.$allPowers.value);
  }
}
