export const convertMarkdownToHTML = (markdown: string): string => {
  let result = markdown
    .replaceAll(/\*\*([\s\S]*?)\*\*/g, '<b>$1</b>')
    .replaceAll(/\*([\s\S]*?)\*/g, '<i>$1</i>')
    .replaceAll(/(\s*:\s*)/g, '&nbsp;:&nbsp;')
    .replaceAll(/(?:\r\n|\r|\n)\s*(?:\r\n|\r|\n)/g, '<br/><br/>')
    .replaceAll(/(\.\s*)(?:\r\n|\r|\n)/g, '.<br/>')
    .replaceAll(/(!\s*)(?:\r\n|\r|\n)/g, '!<br/>')
    .replaceAll(/(\?\s*)(?:\r\n|\r|\n)/g, '?<br/>')
    .replaceAll('<br/> ', '<br/>')
    .replaceAll(' <br/>', '<br/>')
    .replaceAll(/# (.*?)(?:\r\n|\r|\n)/g, '<h1>$1</h1>')
    .replaceAll(/## (.*?)(?:\r\n|\r|\n)/g, '<h2>$1</h2>');

  let security = 0;
  while ((security < 100 && (result.startsWith('<br/>') || result.startsWith(' ') || result.endsWith('<br/>'))) || result.endsWith(' ')) {
    if (result.startsWith('<br/>')) result = result.replace('<br/>', '');
    if (result.startsWith(' ')) result = result.replace(' ', '');
    if (result.endsWith('<br/>')) {
      const lastIndex = result.lastIndexOf('<br/>');
      result = result.substring(0, lastIndex) + result.substring(lastIndex + result.length);
    }
    if (result.endsWith(' ')) {
      const lastIndex = result.lastIndexOf(' ');
      result = result.substring(0, lastIndex) + result.substring(lastIndex + result.length);
    }
    security++;
  }

  return result;
};
