import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from '@src/app-routing.module';
import { AppComponent } from '@src/app.component';
import { ComponentsModule } from '@src/components/components.module';
import { StoreService } from '@src/services/store.service';
import { ViewsModule } from '@src/views/views.module';

@NgModule({
  imports: [AppRoutingModule, BrowserModule, BrowserAnimationsModule, ComponentsModule, ViewsModule],
  declarations: [AppComponent],
  providers: [StoreService],
  bootstrap: [AppComponent],
})
export class AppModule {}
