import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { convertMarkdownToHTML } from '@src/utils/conversions';

@Component({
  selector: 'system-experience-view',
  templateUrl: './system-experience.view.html',
  styleUrls: [],
})
export class SystemExperienceView {
  public systemExperienceText = systemExperienceText;

  public displayedColumns: string[] = ['level', 'pvs', 'caracts', 'skills', 'powers01', 'powers02'];

  public data01: ExperienceElement[] = [
    {
      level: 1,
      pvs: '5 PVs',
      caracts: '15 Caracts.',
      skills: '3 Comps.',
      powers01: '[2, 1, 0]',
      powers02: '[0, 0, 0]',
    },
    {
      level: 2,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 3,
      pvs: null,
      caracts: null,
      skills: '+1',
      powers01: null,
      powers02: null,
    },
    {
      level: 4,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 5,
      pvs: null,
      caracts: '+1',
      skills: null,
      powers01: null,
      powers02: '[+2, 0, 0]',
    },
    {
      level: 6,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 7,
      pvs: null,
      caracts: null,
      skills: null,
      powers01: '[0, 0, +1]',
      powers02: null,
    },
    {
      level: 8,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 9,
      pvs: null,
      caracts: null,
      skills: '+1',
      powers01: null,
      powers02: null,
    },
    {
      level: 10,
      pvs: '+1',
      caracts: '+1',
      skills: '+1',
      powers01: '[+1, 0, 0]',
      powers02: '[0, +1, 0]',
    },
    {
      level: 'Total',
      pvs: '10 PVs',
      caracts: '17 Caracts.',
      skills: '6 Comps.',
      powers01: '[3, 1, 1]',
      powers02: '[2, 1, 0]',
    },
  ];
  public dataSource01 = new MatTableDataSource<ExperienceElement>(this.data01);

  public data02: ExperienceElement[] = [
    {
      level: 11,
      pvs: null,
      caracts: null,
      skills: null,
      powers01: null,
      powers02: '[+1, 0, 0]',
    },
    {
      level: 12,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 13,
      pvs: null,
      caracts: null,
      skills: '+1',
      powers01: '[0, +1, 0]',
      powers02: null,
    },
    {
      level: 14,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 15,
      pvs: null,
      caracts: '+1',
      skills: null,
      powers01: null,
      powers02: '[0, 0, +1]',
    },
    {
      level: 16,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 17,
      pvs: null,
      caracts: null,
      skills: null,
      powers01: null,
      powers02: '[0, +1, 0]',
    },
    {
      level: 18,
      pvs: '+1',
      caracts: null,
      skills: null,
      powers01: null,
      powers02: null,
    },
    {
      level: 19,
      pvs: null,
      caracts: null,
      skills: '+1',
      powers01: null,
      powers02: null,
    },
    {
      level: 20,
      pvs: '+1',
      caracts: '+2',
      skills: null,
      powers01: '[0, 0, +1]',
      powers02: null,
    },
    {
      level: 'Total',
      pvs: '15 PVs',
      caracts: '20 Caracts.',
      skills: '8 Comps.',
      powers01: '[3, 2, 2]',
      powers02: '[3, 2, 1]',
    },
  ];
  public dataSource02 = new MatTableDataSource<ExperienceElement>(this.data02);
}

export interface ExperienceElement {
  level: number | string;
  pvs: string;
  caracts: string;
  skills: string;
  powers01: string;
  powers02: string;
}

export const systemExperienceText = convertMarkdownToHTML(`
  L'expérience d'un personnage du Monde d'Arlénor,
  ne se définit pas au nombre d'ennemis tués, ni au nombre de kilomètres parcourus...
  
  **Un personnage gagne de l'expérience à chaque aventure vécue :** en général, il gagne 1 niveau par scénario terminé.`);
