<div class="encyclo-bestiary-view u-marginTop1">
  <div class="card">
    {{ bestiary.description }}
    <br /><i>{{ bestiary.examples }}</i>
  </div>

  <div
    *ngIf="bestiary.code === 'ETOILESPRIMORDIALES'"
    class="card u-marginTop1"
    [innerHtml]="etoilesPrimordialesText"
  ></div>

  <div *ngIf="!creatures.length" class="card is-info u-marginTop1">Aucune créature.</div>
  <div class="grid-list grid-2 u-marginTop1">
    <div *ngFor="let creature of creatures" class="creature-item grid-element">
      <img *ngIf="creature.profil" class="creature-image" [src]="creature.profil" [alt]="creature.name" />
      <div>
        <h3 class="u-marginBottom1">{{ creature.name }}</h3>
        <p>
          <span [class]="creature.difficultyColor">{{ creature.difficultyLibelle }}</span>
        </p>
        <p>{{ creature.story ? creature.story : 'Aucune description' }}</p>
      </div>
    </div>
  </div>
</div>
