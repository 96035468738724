import { Component, Input, OnInit } from '@angular/core';

import html2canvas from 'html2canvas';
import { AssetsItem, ETypeCategory, ETypeColors } from 'src/assets/meiker/avatar-files';

import { MeikerCategory, MeikerItem } from '../../models/MeikerItem';

@Component({
  selector: 'meiker',
  templateUrl: './meiker.component.html',
  styleUrls: ['./meiker.component.scss'],
})
export class MeikerComponent implements OnInit {
  @Input() public assetsItems: AssetsItem[];
  @Input() public assetsName: string;
  @Input() public height: number;
  @Input() public width: number;

  public hairColors = ['#ceb583', '#b4976c', '#b27b53', '#907462', '#655347', '#2f2b29', '#747270', '#d2d2d2'];
  public skinColors = ['#ecd3c9', '#ba9686', '#785a4d'];
  public otherColors = [
    '#ff6666',
    '#ff9966',
    '#ffcc66',
    '#ccff66',
    '#66ff66',
    '#66ff99',
    '#66ffcc',
    '#66ccff',
    '#6699ff',
    '#9966ff',
    '#cc66ff',
    '#ff66ff',
    '#333333',
    '#666666',
    '#aaaaaa',
    '#eeeeee',
  ];

  public items: MeikerItem[];
  public categories: MeikerCategory[];

  public ngOnInit() {
    this.reset();
  }

  public render() {
    this.items.forEach((item, index) => {
      // Obtenez le canvas et son contexte de dessin
      const canvas = document.getElementById('canvas-' + this.assetsName + '-' + index) as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.onload = function () {
        // Dessinez l'image
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        if (item.color) {
          // Appliquez le mode de fusion "multiply" entre l'image et un rectangle rempli de la couleur spécifiée
          ctx.globalCompositeOperation = 'multiply';
          ctx.fillStyle = item.color;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
        }

        // Dessinez l'image d'effet de masquage
        ctx.globalCompositeOperation = 'destination-in';
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        // Réinitialisez le mode de fusion pour la prochaine image
        ctx.globalCompositeOperation = 'source-over';
      };
      image.src = this.getUrl(item.name);
    });
  }

  public reset() {
    this.items = [];
    this.categories = [];
    setTimeout(() => {
      this._createItems();
      this._createCategories();
      setTimeout(() => {
        this.render();
      }, 0);
    }, 0);
  }

  public export() {
    const element: HTMLElement = document.querySelector('#capture');
    html2canvas(element).then(function (canvas) {
      const imageElement: HTMLImageElement = document.querySelector('#rendu');
      if (imageElement) imageElement.src = canvas.toDataURL();
      simulateDownloadImageClick(canvas.toDataURL(), 'avatar.png');
    });
  }

  public changeName(item: MeikerItem, name: string) {
    item.name = name;
    this.render();
  }

  public changeColor(item: MeikerItem, color: string) {
    // Pour les peaux, on applique la même couleur
    if (item.typeColors === ETypeColors.Skin) {
      this.items.forEach(i => {
        if (i.typeColors === ETypeColors.Skin) i.color = color;
      });
    } else item.color = color;
    this.render();
  }

  public getUrl(name: string) {
    return 'assets/meiker/' + this.assetsName + '-files/' + name + '.png';
  }

  public getIconUrl(name: string) {
    const parts = name.split('/');
    if (parts.length > 1) name = parts[0] + '/icon_' + parts[1];
    else name = 'icon_' + name;
    return 'assets/meiker/' + this.assetsName + '-files/' + name + '.png';
  }

  private _createItems() {
    this.assetsItems.forEach(assetsItem => {
      const names = [];
      if (assetsItem.removable) names.push(assetsItem.name + '_00');
      if (assetsItem.nb === 1) names.push(assetsItem.name);
      else {
        for (let index = 0; index < assetsItem.nb; index++) {
          const libIndex = index + 1 > 9 ? '' + (index + 1) : '0' + (index + 1);
          names.push(assetsItem.name + '_' + libIndex);
        }
      }

      let colors: string[] = [];
      if (assetsItem.typeColors === ETypeColors.Hair) colors = this.hairColors;
      if (assetsItem.typeColors === ETypeColors.Skin) colors = this.skinColors;
      if (assetsItem.typeColors === ETypeColors.Other) colors = this.otherColors;

      this.items.push({
        category: assetsItem.category,
        libelle: assetsItem.libelle,
        name: names[0],
        names: names.length > 1 ? names : null,
        typeColors: assetsItem.typeColors,
        color: colors.length > 0 ? colors[0] : null,
        colors: colors.length > 1 ? colors : null,
      });
    });
  }

  private _createCategories() {
    Object.values(ETypeCategory).forEach(assetsCategory => {
      const category: MeikerCategory = {
        libelle: assetsCategory,
        options: this.items
          .filter(item => item.category === assetsCategory && (item.names?.length || item.colors?.length))
          .sort((a, b) => a.libelle.localeCompare(b.libelle)),
      };
      this.categories.push(category);
    });
  }
}

function simulateDownloadImageClick(uri: string, filename: string) {
  const link = document.createElement('a');
  if (typeof link.download !== 'string') {
    window.open(uri);
  } else {
    link.href = uri;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
