import { ArlenorSector } from './ArlenorSector';

export class ArlenorZone {
  public name: string;
  public posTop: number;
  public posLeft: number;
  public image: string;
  public sector: ArlenorSector;
  public comment: string;
  public commentName: string;
  public linkPage: string | null;
  public linkParams: string | null;

  constructor(name: string, posTop: number, posLeft: number, sector: ArlenorSector, comment: string, commentName: string) {
    this.name = name;
    this.image = '';
    this.posTop = posTop;
    this.posLeft = posLeft;
    this.sector = sector;
    this.comment = comment;
    this.commentName = commentName;
  }
}
